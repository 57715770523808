<div class="form-horizontal">
  <div class="form-group">
    <label for="typeDD" class="pw-label control-label col-sm-4"> Assay Name </label>
    <div class="col-sm-6" id="typeDD">
      <sob-assay-selector
        [fluidAnalyses]="fluidAnalyses"
        [currentAnalysis]="currentAnalysis"
        (updateCurrentAssay)="updateCurrentAnalysis($event)"></sob-assay-selector>
    </div>
  </div>
</div>
<hr />
<ul class="nav nav-tabs" role="tablist" style="margin-right: 15px; margin-top: 20px">
  <li role="presentation" class="active">
    <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">General</a>
  </li>

  <li *ngIf="currentAnalysis" role="presentation">
    <a href="#fluidCharacterization" aria-controls="fluidCharacterization" role="tab" data-toggle="tab">Fluid</a>
  </li>
  <li role="presentation">
    <a href="#economics" aria-controls="economics" role="tab" data-toggle="tab">Economics</a>
  </li>
  <li role="presentation">
    <a href="#constraints" aria-controls="constraints" role="tab" data-toggle="tab">Constraints</a>
  </li>

  <li role="presentation">
    <a href="#diluentSourceComments" aria-controls="diluentSourceComments" role="tab" data-toggle="tab">
      <i class="fa fa-comments fa-lg" aria-hidden="true"> </i>
      Comments
    </a>
  </li>
</ul>

<div class="tab-content pw-margin tab-content-padding">
  <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <div class="form-group">
          <label for="isOptimizable" class="control-label col-sm-4"> Optimize </label>
          <div class="col-sm-4">
            <label class="control-label">
              <input
                type="checkbox"
                id="isOptimizable"
                [(ngModel)]="unitOperation.isOptimizable"
                (change)="isOptimizableChanged($event.target.checked)" />
            </label>
          </div>
        </div>

        <div class="form-group">
          <label [attr.for]="'volumetricFlowrate'" class="pw-label control-label col-sm-4"> Volumetric Flowrate </label>
          <sim-var-input
            [inputId]="'volumetricFlowrate'"
            [simVar]="unitOperation.volumetricFlowrate"
            [disabled]="unitOperation.isOptimizable"></sim-var-input>
        </div>

        <hr />
        <h4 class="subtitle">GHG</h4>

        <div class="form-group">
          <label [attr.for]="'ghgIntensity'" class="pw-label control-label col-sm-4"> GHG Intensity </label>
          <sim-var-input [inputId]="'ghgIntensity'" [simVar]="unitOperation.ghgIntensity"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'ghgEmissions'" class="pw-label control-label col-sm-4"> GHG Emissions </label>
          <sim-var-input
            [inputId]="'ghgEmissions'"
            [readOnly]="true"
            [simVar]="unitOperation.ghgEmissions"></sim-var-input>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="currentAnalysis" role="tabpanel" class="tab-pane fade in" id="fluidCharacterization">
    <sob-suncor-fluid-analysis-properties [currentAnalysis]="currentAnalysis"></sob-suncor-fluid-analysis-properties>
  </div>
  <div role="tabpanel" class="tab-pane fade in" id="economics">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <h4 class="subtitle">OPEX</h4>

        <div class="form-group">
          <label [attr.for]="'price'" class="pw-label control-label col-sm-4"> Premium/Discount to WTI </label>
          <sim-var-input
            [inputId]="'premiumDiscountToWTI'"
            [simVar]="unitOperation.premiumDiscountToWTI"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'opexCarbonDioxide'" class="pw-label control-label col-sm-4"> CO2 </label>
          <sim-var-input
            [inputId]="'opexCarbonDioxide'"
            [simVar]="unitOperation.opexCarbonDioxide"
            [readOnly]="true"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'price'" class="pw-label control-label col-sm-4"> Diluent Price </label>
          <sim-var-input [inputId]="'price'" [simVar]="unitOperation.price" [readOnly]="true"></sim-var-input>
        </div>

        <div class="form-group">
          <label [attr.for]="'opexTotal'" class="pw-label control-label col-sm-4"> Total </label>
          <sim-var-input [inputId]="'opexTotal'" [simVar]="unitOperation.opexTotal" [readOnly]="true"></sim-var-input>
        </div>
      </div>
    </div>
  </div>
  <div role="tabpanel" class="tab-pane fade in" id="constraints">
    <div class="tab-content no-padding no-border">
      <p style="text-align: right; position: relative">
        <span style="position: absolute; right: -25px; top: -20px; font-weight: bold">Active</span>
      </p>
      <div class="form-horizontal">
        <div class="form-group">
          <label [attr.for]="'capacity'" class="pw-label control-label col-sm-4"> Maximum Flow Rate </label>
          <sim-var-input [inputId]="'capacity'" [isConstraint]="true" [simVar]="unitOperation.capacity"></sim-var-input>
        </div>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="diluentSourceComments">
    <div class="tab-content no-padding no-border">
      <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
    </div>
  </div>
</div>
