import { ChangeDetectorRef, Component, inject, Input, OnChanges, ViewChild } from '@angular/core';
import { SuncorFluidAnalysis } from '../../../_models/_fluid/suncor-fluid-analysis';
import { generateUuid } from '../../../_utils/utils';
import { SimulationVariable } from '../../../_models';
import { MultipleSimVarInputDirective } from '../../shared/multiple-sim-var-input/multiple-sim-var-input.directive';

@Component({
  selector: 'sob-suncor-fluid-analysis-properties',
  templateUrl: './suncor-fluid-analysis-properties.component.html',
  styleUrls: ['./suncor-fluid-analysis-properties.component.css'],
})
export class SuncorFluidAnalysisPropertiesComponent implements OnChanges {
  @Input() currentAnalysis?: SuncorFluidAnalysis;
  @ViewChild(MultipleSimVarInputDirective) multipleSimVarInput?: MultipleSimVarInputDirective;

  protected readonly componentId = generateUuid();

  private ref = inject(ChangeDetectorRef);

  freeGasCompositionTotalVariable?: SimulationVariable;

  ngOnChanges() {
    this.freeGasCompositionTotalVariable = this.currentAnalysis.freeGasComposition.getTotalAsSimulationVariable(
      this.currentAnalysis.composition[0].unit
    );

    this.ref.detectChanges();

    if (this.multipleSimVarInput) {
      this.multipleSimVarInput.calculateTotal();
    }
  }

  showFreeGasCompositionClicked() {
    $('#gasCompositionUoTab').tab('show');
  }

  showCompositionTab() {
    $('#compositionUoTab').tab('show');
  }
}
