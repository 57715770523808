import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { ColorPickerModule } from 'ngx-color-picker';

import { WjGridSheetModule } from '@grapecity/wijmo.angular2.grid.sheet';
import { WjNavModule } from '@grapecity/wijmo.angular2.nav';
import { WjInputModule } from '@grapecity/wijmo.angular2.input';
import { CoreService } from '../_services/core.service';
import { ApiService } from '../_services/api.service';

import { FlowsheetComponent } from './flowsheet.component';
import { FlowsheetNavbarComponent } from './flowsheet-navbar/flowsheet-navbar.component';
import { FlowsheetSideMenuComponent } from './flowsheet-side-menu/flowsheet-side-menu.component';
import { FlowsheetDiagramComponent } from './flowsheet-diagram/flowsheet-diagram.component';

import { KeysPipe } from '../shared/keys.pipe';
import { ThousandSeparatorPipe } from '../shared/thousand-separator.pipe';
import { UnitOperationFilterPipePipe } from '../shared/unit-operation-filter-pipe.pipe';
import { TitleCaseCompositionNamePipe } from '../shared/title-case-composition-name.pipe';
import { AlphanumericSortPipe } from '../shared/alphanumeric-sort.pipe';
import { FlowsheetDiagramPaletteComponent } from './flowsheet-diagram-palette/flowsheet-diagram-palette.component';
import { FlowsheetDiagramService } from '../_services/flowsheet-diagram/flowsheet-diagram.service';
import { FlowsheetDiagramTemplateProvider } from '../_services/flowsheet-diagram/flowsheet-diagram-template-provider.service';
import { FlowsheetService } from '../_services/flowsheet.service';
import { PropertyWindowComponent } from './property-window/property-window.component';

import { ValueInputComponent } from './shared/value-input/value-input.component';
import { UnitSelectComponent } from './shared/unit-select/unit-select.component';
import { SimVarInputComponent } from './shared/sim-var-input/sim-var-input.component';
import { MultipleSimVarInputDirective } from './shared/multiple-sim-var-input/multiple-sim-var-input.directive';
import { SimulationVariableArrayComponent } from './shared/simulation-variable-array/simulation-variable-array.component';
import { DiluentSourceComponent } from './property-window/diluent-source/diluent-source.component';
import { MineSourceComponent } from './property-window/mine-source/mine-source.component';
import { SagdSourceComponent } from './property-window/sagd-source/sagd-source.component';
import { RefineryComponent } from './property-window/refinery/refinery.component';
import { ThirdPartyRefineryComponent } from './property-window/third-party-refinery/third-party-refinery.component';
import { UpgraderComponent } from './property-window/upgrader/upgrader.component';
import { SplitterComponent } from './property-window/splitter/splitter.component';

import { MixerComponent } from './property-window/mixer/mixer.component';
import { CaseSettingsComponent } from './case-settings/case-settings.component';
import { MaterialStreamComponent } from './property-window/material-stream/material-stream.component';
import { CollapsePanelIconDirective } from './shared/collapse-panel-icon/collapse-panel-icon.directive';
import { CutComponent } from './shared/cut/cut.component';
import { SaveCaseFileComponent } from './save-case-file/save-case-file.component';
import { OpenCaseFileComponent } from './open-case-file/open-case-file.component';
import { BottomBarComponent } from './bottom-bar/bottom-bar.component';
import { LogManagerComponent } from './log-manager/log-manager.component';
import { LogManagerService } from './log-manager/log-manager.service';
import { KpiManagerComponent } from './case-study/kpi-manager/kpi-manager.component';
import { ExcelReportService } from '../_services/excel-report/excel-report.service';
import { ReportService } from '../_services/report.service';
import { SummaryReportComponent } from './summary-report/summary-report.component';
import { ObjectiveFunctionSelectorComponent } from './objective-function-selector/objective-function-selector.component';
import { ProgressIndicatorComponent } from './progress-indicator/progress-indicator.component';
import { ProgressIndicatorService } from './progress-indicator/progress-indicator.service';
import { ThirdPartySourceComponent } from './property-window/third-party-source/third-party-source.component';
import { ExcelReportComponent } from './excel-report/excel-report.component';
import { OptimizationSettingsTableComponent } from './optimization-settings-table/optimization-settings-table.component';
import { CaseStatusIndicatorComponent } from './case-status-indicator/case-status-indicator.component';
import { FlowsheetCommentsComponent } from './flowsheet-comments/flowsheet-comments.component';
import { OffshoreSourceComponent } from './property-window/offshore-source/offshore-source.component';
import { AboutWindowComponent } from './about-window/about-window.component';
import { SendCaseModalComponent } from './send-case-modal/send-case-modal.component';
import { SendCaseModalService } from './send-case-modal/send-case-modal.service';
import { DownloadManualService } from '../_services/download-manual.service';
import { CompatibilityService } from '../_services/compatibility-service';
import { CodeEditorModule } from './code-editor/code-editor.module';
import { InsightsComponent } from './summary-report/insights/insights.component';
import { SuncorRenewableBlockComponent } from './property-window/suncor-renewable-block/suncor-renewable-block.component';
import { SuncorRenewableComponent } from './property-window/suncor-renewable-block/suncor-renewable/suncor-renewable.component';
import { ExcelUploadComponent } from './excel-upload/excel-upload.component';
import { CaseListComponent } from './case-list/case-list.component';
import { GlobalKpiComponent } from './global-kpi/global-kpi.component';
import { GlobalKpiService } from '../_services/global-kpi.service';
import { CaseClonerService } from '../_services/case-cloner/case-cloner.service';
import { ExcelUploadService } from '../_services/excel-upload/excel-upload.service';
import { ProjectDeserializerService } from '../_services/project-deserializer/project-deserializer.service';
import { CaseDeserializerService } from '../_services/case-deserializer/case-deserializer.service';
import { FlowsheetSpreadsheetComponent } from './flowsheet-spreadsheet/flowsheet-spreadsheet.component';
import { FlexSheetSpreadsheetService } from '../_services/flowsheet-spreadsheet/flex-sheet-spreadsheet.service';
import { FlowsheetViewSelectorComponent } from './flowsheet-view-selector/flowsheet-view-selector.component';
import { SankeyDiagramComponent } from './sankey-diagram/sankey-diagram.component';
import { DiagramControlsComponent } from './diagram-controls/diagram-controls.component';
import { UncertaintyAnalysisComponent } from './case-study/uncertainty-analysis/uncertainty-analysis.component';
import { UncertainParameterRowComponent } from './case-study/uncertain-parameter-row/uncertain-parameter-row.component';
import { UncertaintyAnalysisService } from '../_services/case-study/uncertainty-analysis.service';
import { UncertaintyAnalysisApiService } from '../_services/case-study/uncertainty-analysis-api.service';
import { ParametricStudyComponent } from './case-study/parametric-study/parametric-study.component';
import { ParameterInputComponent } from './case-study/parameter-input/parameter-input.component';
import { ParametricStudyWindowComponent } from './case-study/parametric-study-window/parametric-study-window.component';
import { ParametricStudyResultsComponent } from './case-study/parametric-study-results/parametric-study-results.component';
import { SubFlowsheetInfoComponent } from './sub-flowsheet-info/sub-flowsheet-info.component';
import { DRUComponent } from './property-window/dru/dru.component';
import { VACComponent } from './property-window/vac/vac.component';
import { HydrotreaterComponent } from './property-window/hydrotreater/hydrotreater.component';
import { DCUComponent } from './property-window/dcu/dcu.component';
import { CutPropertiesComponent } from './shared/cut-properties/cut-properties.component';
import { CommodityTankComponent } from './property-window/commodity-tank/commodity-tank.component';
import { ProductTankComponent } from './property-window/product-tank/product-tank.component';
import { AssayManagerComponent } from './assay-manager/assay-manager.component';
import { AssaySelectorComponent } from './assay-manager/assay-selector/assay-selector.component';
import { RangeDistributionRatioVariableComponent } from './shared/range-distriburion-ratio-variable/range-distribution-ratio-variable.component';
import { HydrocrackerComponent } from './property-window/hydrocracker/hydrocracker.component';
import { ConstraintViolationReportComponent } from './constraint-violation-report/constraint-violation-report.component';
import { SankeyDiagramControlsComponent } from './sankey-diagram-controls/sankey-diagram-controls.component';
import { SearchModalComponent } from './search-modal/search-modal.component';
import { SteamUseUtilityComponent } from './shared/steam-use-utility/steam-use-utility.component';
import { SolveResultsTypeSelectorComponent } from './solve-results-type-selector/solve-results-type-selector.component';
import { NumberToUnitConverter } from '../_services/number-to-unit-converter.service';
import { GoJsPaletteUtils } from '../_services/flowsheet-palette/gojs-palette-utils';
import { UpgraderPaletteHandler } from '../_services/flowsheet-palette/upgrader-palette-handler';
import { FirstLevelPaletteModelProvider } from '../_services/flowsheet-palette/first-level-palette-model-provider';
import { UpgraderSubFlowsheetHandler } from '../_services/sub-flowsheet/upgrader-sub-flowsheet-handler.service';
import { ExtractionComponent } from './property-window/extraction/extraction.component';
import { MultiPeriodSettingsComponent } from './multi-period/multi-period-settings/multi-period-settings.component';
import { NumberValidationMessagesComponent } from './shared/number-validation-messages/number-validation-messages.component';
import { MultiPeriodInventorySettingsComponent } from './multi-period/multi-period-inventory-settings/multi-period-inventory-settings.component';
import { MultiPeriodParameterSelectorComponent } from './multi-period/multi-period-parameter-selector/multi-period-parameter-selector.component';
import { MultiPeriodParameterSpreadsheetComponent } from './multi-period/multi-period-parameter-spreadsheet/multi-period-parameter-spreadsheet.component';
import { MultiPeriodResultsComponent } from './multi-period/multi-period-results/multi-period-results.component';
import { ExtractionPaletteHandler } from '../_services/flowsheet-palette/extraction-palette-handler.service';
import { ExtractionSubFlowsheetHandler } from '../_services/sub-flowsheet/extraction-sub-flowsheet-handler.service';
import { ProjectFileLoaderService } from '../_services/project-deserializer/project-file-loader.service';
import { GoJsDiagramUtils } from '../_services/flowsheet-diagram/go-js-diagram-utils';
import { UtilitiesFlowsheetHandler } from '../_services/sub-flowsheet/utilities-flowsheet-handler';
import { UtilitiesPaletteHandler } from '../_services/flowsheet-palette/utilities-palette-handler';
import { UtilitiesFlowsheetTemplateProvider } from '../_services/flowsheet-diagram/utilities-flowsheet-template-provider';
import { WaterTurbineComponent } from './property-window/water-turbine/water-turbine.component';
import { WaterHeatExchangerComponent } from './property-window/water-heat-exchanger/water-heat-exchanger.component';
import { WaterHeaterComponent } from './property-window/water-heater/water-heater.component';
import { WaterCoolerComponent } from './property-window/water-cooler/water-cooler.component';
import { WaterTwoPhaseSeparatorComponent } from './property-window/water-two-phase-separator/water-two-phase-separator.component';
import { WaterPumpComponent } from './property-window/water-pump/water-pump.component';
import { WaterUtilityUnitOperationComponent } from './property-window/water-utility-unit-operation/water-utility-unit-operation.component';
import { WaterValveComponent } from './property-window/water-valve/water-valve.component';
import { WaterMixerComponent } from './property-window/water-mixer/water-mixer.component';
import { WaterSplitterComponent } from './property-window/water-splitter/water-splitter.component';
import { WaterSinkComponent } from './property-window/water-sink/water-sink.component';
import { WaterMaterialStreamComponent } from './property-window/water-material-stream/water-material-stream.component';
import { KpiManagerModalComponent } from './case-study/kpi-manager-modal/kpi-manager-modal.component';
import { FluidCokerComponent } from './property-window/fluid-coker/fluid-coker.component';
import { HydrogenPlantComponent } from './property-window/hydrogen-plant/hydrogen-plant.component';
import { HgoHydrotreaterComponent } from './property-window/hgo-hydrotreater/hgo-hydrotreater.component';
import { ReactiveSimVarInputDirective } from '../shared/reactive-sim-var-input.directive';
import { ReactiveUnitSelectComponent } from '../shared/reactive-unit-select/reactive-unit-select.component';
import { ReactiveVariableValueInputComponent } from '../shared/reactive-variable-value-input/reactive-variable-value-input.component';
import { WaterSourceImportComponent } from './property-window/water-source-import/water-source-import.component';
import { WaterSourceInformationStreamFormComponent } from './property-window/water-source-information-stream-form/water-source-information-stream-form.component';
import { WaterPipeComponent } from './property-window/water-pipe/water-pipe.component';
import { WaterSinkImportComponent } from './property-window/water-sink-import/water-sink-import.component';
import { WaterBoilerComponent } from './property-window/water-boiler/water-boiler.component';
import { WaterHeaderComponent } from './property-window/water-header/water-header.component';
import { ReactiveConstraintCheckBoxComponent } from '../shared/reactive-constraint-check-box/reactive-constraint-check-box.component';
import { SulfurPlantComponent } from './property-window/sulfur-plant/sulfur-plant.component';
import { ReactiveSteamUseUtilityComponent } from './shared/reactive-sob-steam-use-utility/reactive-steam-use-utility.component';
import { ReactiveCodeEditorModule } from './reactive-code-editor/reactive-code-editor.module';
import { DiagramCommentButtonComponent } from './diagram-comment-button/diagram-comment-button.component';
import { GasContributorComponent } from './shared/gas-contributor/gas-contributor.component';
import { UtilitiesSummaryReportComponent } from './summary-report/utilities-summary-report/utilities-summary-report.component';
import { UtilitiesSummaryReportChartComponent } from './summary-report/utilities-summary-report-chart/utilities-summary-report-chart.component';
import { FuelGasUtilityUnitOperationComponent } from './property-window/fuel-gas-utility/fuel-gas-utility-unit-operation.component';
import { FuelGasPaletteHandler } from '../_services/flowsheet-palette/fuel-gas-palette-handler';
import { FuelGasSubFlowsheetHandler } from '../_services/sub-flowsheet/fuel-gas-sub-flowsheet-handler';
import { ReactiveGasContributorComponent } from './shared/reactive-gas-contributor/reactive-gas-contributor.component';
import { WaterCogenerationComponent } from './property-window/water-cogeneration/water-cogeneration.component';
import { FuelGasFlowsheetTemplateProvider } from '../_services/flowsheet-diagram/fuel-gas-flowsheet-template-provider';
import { FuelGasFlareComponent } from './property-window/fuel-gas-flare/fuel-gas-flare.component';
import { FuelGasSinkComponent } from './property-window/fuel-gas-sink/fuel-gas-sink.component';
import { FuelGasSinkImportComponent } from './property-window/fuel-gas-sink-import/fuel-gas-sink-import.component';
import { EnergyStreamFormComponent } from './property-window/energy-stream-form/energy-stream-form.component';
import { FuelGasSourceImportComponent } from './property-window/fuel-gas-source-import/fuel-gas-source-import.component';
import { FuelGasSourceInformationStreamFormComponent } from './property-window/fuel-gas-source-information-stream-form/fuel-gas-source-information-stream-form.component';
import { FuelGasMixerComponent } from './property-window/fuel-gas-mixer/fuel-gas-mixer.component';
import { FuelGasSourceComponent } from './property-window/fuel-gas-source/fuel-gas-source.component';
import { FuelGasSplitterComponent } from './property-window/fuel-gas-splitter/fuel-gas-splitter.component';
import { FuelGasMaterialStreamComponent } from './property-window/fuel-gas-material-stream/fuel-gas-material-stream.component';
import { FuelGasAmineContactorComponent } from './property-window/fuel-gas-amine-contactor/fuel-gas-amine-contactor.component';
import { ReactiveMultipleVariableInputDirective } from '../shared/reactive-multiple-variable-input.directive';
import { FuelGasSummaryReportComponent } from './summary-report/fuel-gas-summary-report/fuel-gas-summary-report.component';
import { FuelGasSummaryReportChartComponent } from './summary-report/fuel-gas-summary-report-chart/fuel-gas-summary-report-chart.component';
import { GhgSummaryReportComponent } from './summary-report/ghg-summary-report/ghg-summary-report.component';
import { FlowsheetTreeService } from '../_services/sub-flowsheet/flowsheet-tree/flowsheet-tree.service';
import { UpgraderReportComponent } from './upgrader-report/upgrader-report.component';
import { GhgUpgraderReportComponent } from './upgrader-report/ghg-upgrader-report/ghg-upgrader-report.component';
import { ConstraintRankingComponent } from './upgrader-report/constraint-ranking/constraint-ranking.component';
import { FlowsheetTreeSelectorComponent } from './flowsheet-tree-selector/flowsheet-tree-selector.component';
import { ServerUtcDateService } from '../_services/helpers/server-utc-date.service';
import { CommentsService } from '../_services/helpers/comments.service';
import { UnitOperationCommentsComponent } from './unit-operation-comments/unit-operation-comments.component';
import { CaseCommentsComponent } from './case-comments/case-comments.component';
import { WaterSourceComponent } from './property-window/water-source/water-source.component';
import { PipeComponent } from './property-window/pipe/pipe.component';
import { ReactiveCutPropertiesComponent } from './shared/reactive-cut-properties/reactive-cut-properties.component';
import { ReactiveCutComponent } from './shared/reactive-cut/reactive-cut.component';
import { BulkPropertiesComponent } from './shared/bulk-properties/bulk-properties.component';
import { CompositionComponent } from './shared/composition/composition.component';
import { MultiperiodExcelReportComponent } from './multiperiod-excel-report/multiperiod-excel-report.component';
import { CaseStudyExcelReportComponent } from './case-study-excel-report/case-study-excel-report.component';
import { LgoHydrotreaterComponent } from './property-window/lgo-hydrotreater/lgo-hydrotreater.component';
import { ReactiveRangeDistributionRatioVariablesComponent } from './shared/reactive-range-distribution-ratio-variables/reactive-range-distribution-ratio-variables.component';
import { AfterDeserializationCompatibilityService } from '../_services/after-deserialization-compatibility-service';
import { BitumenConversionComponent } from './property-window/bitumen-conversion/bitumen-conversion.component';
import { DiagramItemColorPickerComponent } from './diagram-item-color-picker/diagram-item-color-picker.component';
import { DiagramItemColorPickerService } from './diagram-item-color-picker/diagram-item-color-picker.service';
import { OtherDiagramObjectsTemplateProvider } from '../_services/flowsheet-diagram/other-diagram-objects-template-provider';
import { AssayListComponent } from './assay-manager/assay-list/assay-list.component';
import { GasExportComponent } from './property-window/gas-export/gas-export.component';
import { LightEndsRecoveryUnitComponent } from './property-window/light-ends-recovery-unit/light-ends-recovery-unit.component';
import { FreeGasCompositionFormComponent } from './assay-manager/free-gas-composition-form/free-gas-composition-form.component';
import { NumberToUnitPipe } from '../shared/pipes/number-to-unit-pipe';
import { MsBulkPropertiesComponent } from './property-window/material-stream/ms-bulk-properties/ms-bulk-properties.component';
import { MsCompositionComponent } from './property-window/material-stream/ms-composition/ms-composition.component';
import { FlexiformerComponent } from './property-window/flexiformer/flexiformer.component';
import { SuncorFluidAnalysisPropertiesComponent } from './property-window/suncor-fluid-analysis-properties/suncor-fluid-analysis-properties.component';
import { ReactiveSuncorFluidAnalysisPropertiesComponent } from './property-window/reactive-suncor-fluid-analysis-properties/reactive-suncor-fluid-analysis-properties.component';

@NgModule({
  imports: [
    CommonModule,
    ColorPickerModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    CodeEditorModule,
    ReactiveCodeEditorModule,
    WjGridSheetModule,
    WjNavModule,
    WjInputModule,
    NumberToUnitPipe,
  ],
  exports: [FlowsheetComponent],
  declarations: [
    KeysPipe,
    ThousandSeparatorPipe,
    UnitOperationFilterPipePipe,
    TitleCaseCompositionNamePipe,
    AlphanumericSortPipe,
    CollapsePanelIconDirective,
    FlowsheetComponent,
    FlowsheetSideMenuComponent,
    FlowsheetNavbarComponent,
    FlowsheetDiagramComponent,
    FlowsheetDiagramPaletteComponent,
    PropertyWindowComponent,
    MultipleSimVarInputDirective,
    SimulationVariableArrayComponent,
    SimVarInputComponent,
    ValueInputComponent,
    UnitSelectComponent,
    DiluentSourceComponent,
    MineSourceComponent,
    SagdSourceComponent,
    RefineryComponent,
    ThirdPartyRefineryComponent,
    UpgraderComponent,
    SplitterComponent,
    MixerComponent,
    CaseSettingsComponent,
    MaterialStreamComponent,
    CutComponent,
    SaveCaseFileComponent,
    OpenCaseFileComponent,
    BottomBarComponent,
    LogManagerComponent,
    KpiManagerComponent,
    SummaryReportComponent,
    ObjectiveFunctionSelectorComponent,
    ProgressIndicatorComponent,
    ThirdPartySourceComponent,
    ExcelReportComponent,
    OptimizationSettingsTableComponent,
    CaseStatusIndicatorComponent,
    FlowsheetCommentsComponent,
    OffshoreSourceComponent,
    AboutWindowComponent,
    SendCaseModalComponent,
    InsightsComponent,
    SuncorRenewableBlockComponent,
    SuncorRenewableComponent,
    ExcelUploadComponent,
    CaseListComponent,
    GlobalKpiComponent,
    FlowsheetViewSelectorComponent,
    SankeyDiagramComponent,
    FlowsheetSpreadsheetComponent,
    DiagramControlsComponent,
    FlowsheetSpreadsheetComponent,
    UncertaintyAnalysisComponent,
    UncertainParameterRowComponent,
    ParametricStudyComponent,
    ParameterInputComponent,
    ParametricStudyWindowComponent,
    ParametricStudyResultsComponent,
    SubFlowsheetInfoComponent,
    DRUComponent,
    VACComponent,
    HydrotreaterComponent,
    DCUComponent,
    CutPropertiesComponent,
    AssayManagerComponent,
    CommodityTankComponent,
    ProductTankComponent,
    AssaySelectorComponent,
    RangeDistributionRatioVariableComponent,
    HydrocrackerComponent,
    ConstraintViolationReportComponent,
    SankeyDiagramControlsComponent,
    SearchModalComponent,
    SteamUseUtilityComponent,
    SolveResultsTypeSelectorComponent,
    ExtractionComponent,
    WaterTurbineComponent,
    WaterHeatExchangerComponent,
    WaterHeaterComponent,
    WaterCoolerComponent,
    WaterTwoPhaseSeparatorComponent,
    WaterPumpComponent,
    WaterUtilityUnitOperationComponent,
    WaterValveComponent,
    WaterMixerComponent,
    WaterSplitterComponent,
    WaterSinkComponent,
    WaterMaterialStreamComponent,
    MultiPeriodSettingsComponent,
    NumberValidationMessagesComponent,
    MultiPeriodInventorySettingsComponent,
    MultiPeriodParameterSelectorComponent,
    MultiPeriodParameterSpreadsheetComponent,
    KpiManagerModalComponent,
    FluidCokerComponent,
    HydrogenPlantComponent,
    HgoHydrotreaterComponent,
    MultiPeriodResultsComponent,
    LgoHydrotreaterComponent,
    ReactiveSimVarInputDirective,
    ReactiveUnitSelectComponent,
    ReactiveVariableValueInputComponent,
    ReactiveConstraintCheckBoxComponent,
    WaterSourceImportComponent,
    WaterSourceInformationStreamFormComponent,
    WaterPipeComponent,
    WaterSinkImportComponent,
    WaterBoilerComponent,
    SulfurPlantComponent,
    WaterHeaderComponent,
    ReactiveSteamUseUtilityComponent,
    DiagramCommentButtonComponent,
    GasContributorComponent,
    UtilitiesSummaryReportComponent,
    UtilitiesSummaryReportChartComponent,
    FuelGasUtilityUnitOperationComponent,
    ReactiveGasContributorComponent,
    WaterCogenerationComponent,
    FuelGasFlareComponent,
    FuelGasSinkComponent,
    FuelGasSinkImportComponent,
    EnergyStreamFormComponent,
    FuelGasSourceImportComponent,
    FuelGasSourceInformationStreamFormComponent,
    FuelGasMixerComponent,
    FuelGasSourceComponent,
    FuelGasSplitterComponent,
    FuelGasMaterialStreamComponent,
    FuelGasAmineContactorComponent,
    ReactiveMultipleVariableInputDirective,
    FuelGasSummaryReportComponent,
    FuelGasSummaryReportChartComponent,
    GhgSummaryReportComponent,
    GhgUpgraderReportComponent,
    UpgraderReportComponent,
    ConstraintRankingComponent,
    FlowsheetTreeSelectorComponent,
    UnitOperationCommentsComponent,
    CaseCommentsComponent,
    WaterSourceComponent,
    PipeComponent,
    RefineryComponent,
    ReactiveCutPropertiesComponent,
    ReactiveCutComponent,
    BulkPropertiesComponent,
    CompositionComponent,
    MultiperiodExcelReportComponent,
    CaseStudyExcelReportComponent,
    ReactiveRangeDistributionRatioVariablesComponent,
    BitumenConversionComponent,
    DiagramItemColorPickerComponent,
    AssayListComponent,
    GasExportComponent,
    LightEndsRecoveryUnitComponent,
    FreeGasCompositionFormComponent,
    MsBulkPropertiesComponent,
    MsCompositionComponent,
    FlexiformerComponent,
    SuncorFluidAnalysisPropertiesComponent,
    ReactiveSuncorFluidAnalysisPropertiesComponent,
  ],

  providers: [
    NumberToUnitConverter,
    ApiService,
    CompatibilityService,
    AfterDeserializationCompatibilityService,
    CoreService,
    DownloadManualService,
    FlowsheetDiagramService,
    FlowsheetDiagramTemplateProvider,
    GoJsDiagramUtils,
    UtilitiesFlowsheetTemplateProvider,
    FuelGasFlowsheetTemplateProvider,
    FlowsheetService,
    GoJsPaletteUtils,
    UpgraderPaletteHandler,
    ExtractionPaletteHandler,
    UtilitiesPaletteHandler,
    FuelGasPaletteHandler,
    FirstLevelPaletteModelProvider,
    UpgraderSubFlowsheetHandler,
    ExtractionSubFlowsheetHandler,
    UtilitiesFlowsheetHandler,
    FuelGasSubFlowsheetHandler,
    LogManagerService,
    ProgressIndicatorService,
    ReportService,
    SendCaseModalService,
    ExcelReportService,
    ExcelUploadService,
    ProjectFileLoaderService,
    ProjectDeserializerService,
    CaseDeserializerService,
    CaseClonerService,
    GlobalKpiService,
    FlexSheetSpreadsheetService,
    UncertaintyAnalysisService,
    UncertaintyAnalysisApiService,
    FlowsheetTreeService,
    ServerUtcDateService,
    CommentsService,
    OtherDiagramObjectsTemplateProvider,
    DiagramItemColorPickerService,
  ],
})
export class FlowsheetModule {}
