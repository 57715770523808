import { Component, Input, OnInit } from '@angular/core';
import { Extraction } from '../../../_models/_unit-operations/extraction';
import { CoreService } from '../../../_services/core.service';
import { SuncorFluidAnalysis } from '../../../_models/_fluid/suncor-fluid-analysis';
import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { FlowsheetDiagramService } from '../../../_services/flowsheet-diagram/flowsheet-diagram.service';
import { FlowsheetService } from '../../../_services/flowsheet.service';
import { AssayType } from '../../../_config/assay-type.enum';

@Component({
  selector: 'sob-extraction',
  templateUrl: './extraction.component.html',
  styleUrls: ['./extraction.component.css'],
})
export class ExtractionComponent implements OnInit {
  @Input() unitOperation: Extraction;
  currentAnalysis: SuncorFluidAnalysis;

  constructor(
    private coreService: CoreService,
    private flowsheetDiagramService: FlowsheetDiagramService,
    private flowsheetService: FlowsheetService
  ) {}

  ngOnInit() {
    this.updateCurrentAnalysis();
  }

  get fluidAnalyses() {
    return this.coreService.currentCase.assayManager.getAssaysByType(AssayType.CRUDE_OIL_BITUMEN);
  }

  updateCurrentAnalysis(id?) {
    if (id) {
      this.unitOperation.fluidAnalysisId = id;
    }
    if (this.unitOperation.fluidAnalysisId) {
      this.currentAnalysis = this.coreService.currentCase.assayManager.findFluidAnalysisById(
        this.unitOperation.fluidAnalysisId
      );
    }
  }

  // region level 2 handling
  clearOpex(): void {
    if (!this.unitOperation.useSubFlowsheet) {
      this.unitOperation.opexTotal.value = undefined;
      this.unitOperation.opexVar.value = undefined;
      this.unitOperation.opexGasDiesel.value = undefined;
      this.unitOperation.opexPower.value = undefined;
      this.unitOperation.opexCarbonDioxide.value = undefined;
      this.unitOperation.opexFixed.value = 0.0;
    }
  }

  changeNodeTemplate(level2Enabled: boolean) {
    if (level2Enabled) {
      this.flowsheetDiagramService.changeTemplate(this.unitOperation.id, unitOperationsConfig.flexibleExtraction.key);
    } else {
      this.flowsheetDiagramService.changeTemplate(this.unitOperation.id, unitOperationsConfig.extraction.key);
    }
    this.flowsheetDiagramService.deleteInletStreams(this.unitOperation.id);
    this.flowsheetDiagramService.deleteOutletStreams(this.unitOperation.id);
  }

  openSubFlowsheet(): void {
    if ($('#closePropertyWindow').attr('disabled')) {
      return;
    }
    this.flowsheetService.prepareUnitOperationForSave();
    this.flowsheetService.closePropertyWindow();

    this.flowsheetService.loadUnitOperationFlowsheet(this.unitOperation.id);
  }
  // endregion
}
