import { Component, inject, Input } from '@angular/core';
import { DCU } from '../../../_models/_unit-operations/dcu';
import { FlowsheetService } from '../../../_services/flowsheet.service';
import { DcuCodeProvider } from '../../../_services/custom-code-providers/dcu-code-provider';
import { SeparateGasStreamUserUnitBlockUpdater } from '../../../_services/flowsheet-diagram/separate-gas-stream-user-unit-block-updater';
import { FlowsheetDiagramService } from '../../../_services/flowsheet-diagram/flowsheet-diagram.service';
import { CoreService } from '../../../_services/core.service';

@Component({
  selector: 'sob-dcu',
  templateUrl: './dcu.component.html',
  styleUrls: ['./dcu.component.css'],
})
export class DCUComponent {
  @Input() unitOperation: DCU;
  defaultCodeProvider: DcuCodeProvider;

  private readonly flowsheetDiagramService = inject(FlowsheetDiagramService);
  private readonly coreService = inject(CoreService);

  constructor(private flowsheetService: FlowsheetService) {
    this.defaultCodeProvider = new DcuCodeProvider();
  }

  saveCustomModel(res) {
    if (res.instanceId === 'dcuCodeEditor') {
      this.unitOperation.modelScript = res.code;
      this.flowsheetService.saveCustomCodeChanges();
    }
  }

  separateGasStreamChanged() {
    SeparateGasStreamUserUnitBlockUpdater.create().updateGoJsBlock(
      this.unitOperation.id,
      this.unitOperation.hasSeparateGasStream,
      this.coreService,
      this.flowsheetDiagramService.gojsDiagram
    );
  }
}
