import * as go from 'gojs';
import { CoreService } from '../core.service';
import { UnitOperation } from '../../_models';
import { isSeparateGasStreamUser } from '../../_models/_interfaces/separateGasStreamUser';
import { Hydrocracker } from '../../_models/_unit-operations/hydrocracker';

export class SeparateGasStreamUserUnitBlockUpdater {
  static create(): SeparateGasStreamUserUnitBlockUpdater {
    return new SeparateGasStreamUserUnitBlockUpdater();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  updateGoJsBlock(id: string, hasSeparateGasStream: boolean, coreService: CoreService, diagram: go.Diagram) {
    const { currentCase } = coreService;
    const unitOperation = currentCase.getUnitOperation(id);

    if (!isSeparateGasStreamUser(unitOperation)) {
      throw new Error(`The unit operation ${unitOperation?.name} is not a Separate Gas Stream User`);
    }

    const uoNode = diagram.findNodesByExample({ id }).first();

    if (!uoNode) {
      throw new Error(`The diagram node for ${unitOperation.name} was not found`);
    }

    diagram.startTransaction('updatePorts');
    if (!uoNode.data.gasPort) {
      diagram.model.setDataProperty(uoNode.data, 'gasPort', []);
    }

    if (hasSeparateGasStream) {
      if (unitOperation instanceof Hydrocracker) {
        this.setNewPortsAlignmentValue(uoNode, new go.Spot(0.7, 0));
      }
      this.addGasOutletPort(uoNode, uoNode.data.gasPort);
    } else {
      this.handleDeletedPorts(unitOperation, uoNode.data.gasPort, coreService, uoNode);
    }

    diagram.commitTransaction('updatePorts');
  }

  private handleDeletedPorts(
    unitOperation: UnitOperation,
    portsArray: any[],
    coreService: CoreService,
    uoNode: go.Node
  ) {
    const { currentCase } = coreService;
    const { model } = uoNode.diagram;
    const gasPortIdx = portsArray.findIndex(port => port.portId === 'gas');
    model.removeArrayItem(portsArray, gasPortIdx);

    const streamToDelete = currentCase.filterSuncorMaterialStreams(s => {
      return s.fromPort === 'gas' && s.inletUnitOperationId === unitOperation.id;
    })[0];

    if (streamToDelete) {
      coreService.removeStream(streamToDelete);
    }

    if (unitOperation instanceof Hydrocracker) {
      this.setNewPortsAlignmentValue(uoNode, new go.Spot(0.5, 0));
    }
  }

  private addGasOutletPort(uoNode: go.Node, nodePortsArray: any[]) {
    const { model } = uoNode.diagram;
    const newPortData = this.createPortData();
    const portGraphObject = uoNode.findPort(newPortData.portId);

    // the port exists if the result of findPort != owner node
    if (portGraphObject !== uoNode) {
      const { data } = portGraphObject as go.Panel;
      model.setDataProperty(data, 'tooltip', newPortData.tooltip);
    } else {
      model.insertArrayItem(nodePortsArray, -1, newPortData);
    }
  }

  private createPortData() {
    return {
      portId: 'gas',
      tooltip: 'Gas',
    };
  }

  private setNewPortsAlignmentValue(uoNode: go.Node, diluentValue: go.Spot) {
    const { model } = uoNode.diagram;

    const diluentPort = uoNode.findPort('diluent');
    if (diluentPort !== uoNode) {
      model.setDataProperty(uoNode.data, 'diluentAlignment', diluentValue);
    }
  }
}
