import { Component, inject, Input } from '@angular/core';
import { FluidCoker } from '../../../_models/_unit-operations/fluid-coker';
import { DefaultCodeProvider } from '../../../_services/custom-code-providers/default-code-provider';
import { FlowsheetService } from '../../../_services/flowsheet.service';
import { SeparateGasStreamUserUnitBlockUpdater } from '../../../_services/flowsheet-diagram/separate-gas-stream-user-unit-block-updater';
import { FlowsheetDiagramService } from '../../../_services/flowsheet-diagram/flowsheet-diagram.service';
import { CoreService } from '../../../_services/core.service';

@Component({
  selector: 'sob-fluid-coker',
  templateUrl: './fluid-coker.component.html',
  styleUrls: ['./fluid-coker.component.css'],
})
export class FluidCokerComponent {
  @Input() unitOperation: FluidCoker;
  defaultCodeProvider: DefaultCodeProvider;
  readonly codeEditorInstanceId = 'fluidCokerCodeEditor';

  private readonly flowsheetDiagramService = inject(FlowsheetDiagramService);
  private readonly coreService = inject(CoreService);

  constructor(private flowsheetService: FlowsheetService) {
    this.defaultCodeProvider = new DefaultCodeProvider('');
  }

  useDataDrivenYieldModelChanged(target: EventTarget) {
    if ((target as HTMLInputElement).checked) {
      this.unitOperation.naphthaYield.value = undefined;
      this.unitOperation.gasOilYield.value = undefined;
    }
  }

  saveCustomModel(res) {
    if (res.instanceId === this.codeEditorInstanceId) {
      this.unitOperation.modelScript = res.code;
      this.flowsheetService.saveCustomCodeChanges();
    }
  }

  separateGasStreamChanged() {
    SeparateGasStreamUserUnitBlockUpdater.create().updateGoJsBlock(
      this.unitOperation.id,
      this.unitOperation.hasSeparateGasStream,
      this.coreService,
      this.flowsheetDiagramService.gojsDiagram
    );
  }
}
