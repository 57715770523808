import { SimulationVariable } from '../simulation-variable';
import { FluidAnalysis } from './fluid-analysis';
import { Case } from '../case';
import { SuncorHypotheticalCompound } from './suncor-hypothetical-compound';
import { BaseObject } from '../base-object';
import { Cut } from './cut';
import { AssayManagerFluidAnalysisResourceData } from './assay-manager-resource-data';
import { ReadyStatus } from '../../_config/ready-status.enum';
import { FreeGasComposition } from './free-gas-composition';

export class SuncorFluidAnalysis extends FluidAnalysis {
  category = 'fluid-analysis';
  ownerBaseObject: BaseObject;

  // So-called Bulk properties
  massDensity: SimulationVariable;
  ccr: SimulationVariable;
  sulfur: SimulationVariable;
  nitrogen: SimulationVariable;

  // So called Cuts
  lightEndsCut: Cut;
  lightNaphthaCut: Cut;
  heavyNaphthaCut: Cut;
  distillateCut: Cut;
  LGOCut: Cut;
  HGOCut: Cut;
  VGOCut: Cut;
  HVGOCut: Cut;
  residCut: Cut;

  // wtf is this though
  protected fakeFluidPackage: Array<SuncorHypotheticalCompound>;

  freeGasComposition: FreeGasComposition;

  /**
   *
   */
  constructor(fluidAnalysis: any, ownerCase: Case, ownerBaseObject?: BaseObject) {
    super(fluidAnalysis, ownerCase);
    if (fluidAnalysis.composition) {
      if (fluidAnalysis.composition.length < 9) {
        fluidAnalysis.composition = [];
        fluidAnalysis.fakeFluidPackage = [];
      }
    }
    this.ownerBaseObject = ownerBaseObject;
    this.assayName = fluidAnalysis.assayName || '';
    this.assayType = fluidAnalysis.assayType || '';
    this.composition = []; // fluidAnalysis.composition || [];
    this.fakeFluidPackage = fluidAnalysis.fakeFluidPackage || [];

    this.initSimVars(fluidAnalysis);

    if (!this.fakeFluidPackage.length) {
      this.initFluidPackage();
    }

    this.initComposition(fluidAnalysis.composition || []);
    this.initFreeGasComposition(fluidAnalysis.freeGasComposition);

    this.setQuantities();

    this.upperBound = fluidAnalysis.upperBound;
    this.lowerBound = fluidAnalysis.lowerBound;
    this.readyStatus = fluidAnalysis.readyStatus || ReadyStatus.UNDEFINED;
  }

  override addSimVarsToPool() {
    super.addSimVarsToPool();
    for (const c of this.composition) {
      this.ownerCase.addToPools(c);
    }
    this.lightEndsCut.addSimVarsToPool();
    this.lightNaphthaCut.addSimVarsToPool();
    this.heavyNaphthaCut.addSimVarsToPool();
    this.distillateCut.addSimVarsToPool();
    this.LGOCut.addSimVarsToPool();
    this.HGOCut.addSimVarsToPool();
    this.VGOCut.addSimVarsToPool();
    this.HVGOCut.addSimVarsToPool();
    this.residCut.addSimVarsToPool();
  }

  override overwriteValues(another: BaseObject) {
    super.overwriteValues(another);

    const fs = another as SuncorFluidAnalysis;

    for (let i = 0; i < this.composition.length; i++) {
      this.composition[i].overwriteValues(fs.composition[i]);
    }
    this.lightEndsCut.overwriteValues(fs.lightEndsCut);
    this.lightNaphthaCut.overwriteValues(fs.lightNaphthaCut);
    this.heavyNaphthaCut.overwriteValues(fs.heavyNaphthaCut);
    this.distillateCut.overwriteValues(fs.distillateCut);
    this.LGOCut.overwriteValues(fs.LGOCut);
    this.HGOCut.overwriteValues(fs.HGOCut);
    this.VGOCut.overwriteValues(fs.VGOCut);
    this.HVGOCut.overwriteValues(fs.HVGOCut);
    this.residCut.overwriteValues(fs.residCut);
  }

  override convertToInternalUnit(): void {
    super.convertToInternalUnit();
    this.lightEndsCut.convertToInternalUnit();
    this.lightNaphthaCut.convertToInternalUnit();
    this.heavyNaphthaCut.convertToInternalUnit();
    this.distillateCut.convertToInternalUnit();
    this.LGOCut.convertToInternalUnit();
    this.HGOCut.convertToInternalUnit();
    this.VGOCut.convertToInternalUnit();
    this.HVGOCut.convertToInternalUnit();
    this.residCut.convertToInternalUnit();
  }

  protected override ignoreForOverWrite(propertyName: string): boolean {
    return (
      super.ignoreForOverWrite(propertyName) || this[propertyName] instanceof Array || this[propertyName] instanceof Cut
    );
  }

  override getName() {
    return this.ownerBaseObject.getName();
  }

  setSimulationVariableNames(): void {
    this.massDensity.setName('Mass Density');
    this.ccr.setName('CCR');
  }

  override setSimulationVariablesOwner(): void {
    super.setSimulationVariablesOwner();
    for (let i = 0; i < this.composition.length; i++) {
      this.composition[i].setSimulationVariablesOwner();
    }
  }

  initSimVars(fluidAnalysis: any): void {
    this.massDensity = new SimulationVariable(fluidAnalysis.massDensity, this.ownerCase, this);
    this.ccr = new SimulationVariable(fluidAnalysis.ccr, this.ownerCase, this);
    this.sulfur = new SimulationVariable(fluidAnalysis.sulfur, this.ownerCase, this);
    this.nitrogen = new SimulationVariable(fluidAnalysis.nitrogen, this.ownerCase, this);
    // Apparently
    this.lightEndsCut = new Cut(fluidAnalysis.lightEndsCut || {}, this.ownerCase, this);
    this.lightNaphthaCut = new Cut(fluidAnalysis.lightNaphthaCut || {}, this.ownerCase, this);
    this.heavyNaphthaCut = new Cut(fluidAnalysis.heavyNaphthaCut || {}, this.ownerCase, this);
    this.distillateCut = new Cut(fluidAnalysis.distillateCut || {}, this.ownerCase, this);
    this.LGOCut = new Cut(fluidAnalysis.LGOCut || {}, this.ownerCase, this);
    this.HGOCut = new Cut(fluidAnalysis.HGOCut || {}, this.ownerCase, this);
    this.VGOCut = new Cut(fluidAnalysis.VGOCut || {}, this.ownerCase, this);
    this.HVGOCut = new Cut(fluidAnalysis.HVGOCut || {}, this.ownerCase, this);
    this.residCut = new Cut(fluidAnalysis.residCut || {}, this.ownerCase, this);
  }

  setOwnerBaseObject(ownerBaseObject: BaseObject): void {
    this.ownerBaseObject = ownerBaseObject;
  }

  // this should be an override
  initFluidPackage(): void {
    // this.fluidPackage = new SuncorFluidPackage({});

    // this is so useless
    let hypo: SuncorHypotheticalCompound;

    hypo = new SuncorHypotheticalCompound({}, this.ownerCase);
    hypo.setName('Light ends');
    this.fakeFluidPackage.push(hypo);

    hypo = new SuncorHypotheticalCompound({}, this.ownerCase);
    hypo.setName('Light naphtha');
    this.fakeFluidPackage.push(hypo);

    hypo = new SuncorHypotheticalCompound({}, this.ownerCase);
    hypo.setName('Heavy naphtha');
    this.fakeFluidPackage.push(hypo);

    hypo = new SuncorHypotheticalCompound({}, this.ownerCase);
    hypo.setName('Distillate');
    this.fakeFluidPackage.push(hypo);

    hypo = new SuncorHypotheticalCompound({}, this.ownerCase);
    hypo.setName('LGO');
    this.fakeFluidPackage.push(hypo);

    hypo = new SuncorHypotheticalCompound({}, this.ownerCase);
    hypo.setName('HGO');
    this.fakeFluidPackage.push(hypo);

    hypo = new SuncorHypotheticalCompound({}, this.ownerCase);
    hypo.setName('VGO');
    this.fakeFluidPackage.push(hypo);

    hypo = new SuncorHypotheticalCompound({}, this.ownerCase);
    hypo.setName('HVGO');
    this.fakeFluidPackage.push(hypo);

    hypo = new SuncorHypotheticalCompound({}, this.ownerCase);
    hypo.setName('Resid');
    this.fakeFluidPackage.push(hypo);
  }

  initComposition(composition): void {
    // YE OLDE LOOP
    for (let idx = 0; idx < 9; idx++) {
      // let's use the input composition values for initialization
      if (composition[idx] instanceof SimulationVariable || typeof composition[idx] === 'undefined') {
        this.composition.push(new SimulationVariable(composition[idx], this.ownerCase, this));
        this.composition[idx].setName(this.fakeFluidPackage[idx].name);
      }
    }
  }

  getCompositionElement(name: string): SimulationVariable {
    return this.composition.find(sv => sv.name === name);
  }

  setCompositionNames() {
    const names = ['Light ends', 'Light naphtha', 'Heavy naphtha', 'Distillate', 'LGO', 'HGO', 'VGO', 'HVGO', 'Resid'];
    for (let idx = 0; idx < 9; idx++) {
      this.composition[idx].setName(names[idx]);
    }
  }

  setQuantities() {
    this.massDensity.setQuantity('LiquidDensity');
    this.ccr.setQuantity('MassFraction');
    this.sulfur.setQuantity('MassFraction');
    this.nitrogen.setQuantity('MassFraction');

    for (const sv of this.composition) {
      sv.setQuantity('MassFraction');
    }

    this.quantity = 'MassFraction';
  }

  override detectChanges(another: SuncorFluidAnalysis) {
    let changes = super.detectChanges(another);

    for (let idx = 0; idx < another.composition.length; idx++) {
      changes = changes || this.composition[idx].detectValueChange(another.composition[idx]);
    }
    return changes;
  }

  override getSimVars() {
    const simVars = super.getSimVars();
    for (const ce of this.composition) {
      simVars.push(ce);
    }

    return simVars;
  }

  protected override isPropertyBlackListed(property: any): boolean {
    return property instanceof Case || typeof property === 'function' || property === this.ownerBaseObject;
  }

  override getChildrenObjectsForRemoval(): BaseObject[] {
    const children = super.getChildrenObjectsForRemoval();
    children.push(this.lightEndsCut);
    children.push(this.lightNaphthaCut);
    children.push(this.heavyNaphthaCut);
    children.push(this.distillateCut);
    children.push(this.LGOCut);
    children.push(this.HGOCut);
    children.push(this.VGOCut);
    children.push(this.HVGOCut);
    children.push(this.residCut);
    return children;
  }

  loadResourceData(resourceData: AssayManagerFluidAnalysisResourceData) {
    this.lightEndsCut.loadResourceData(resourceData.cut);
    this.lightNaphthaCut.loadResourceData(resourceData.cut);
    this.heavyNaphthaCut.loadResourceData(resourceData.cut);
    this.distillateCut.loadResourceData(resourceData.cut);
    this.LGOCut.loadResourceData(resourceData.cut);
    this.HGOCut.loadResourceData(resourceData.cut);
    this.VGOCut.loadResourceData(resourceData.cut);
    this.HVGOCut.loadResourceData(resourceData.cut);
    this.residCut.loadResourceData(resourceData.cut);

    this.upperBound = resourceData.composition.upperBound;
    this.lowerBound = resourceData.composition.lowerBound;

    for (const sv of this.composition) {
      // TODO create a method inside SimulationVariable for loading resource data
      sv.upperBound = resourceData.composition.upperBound;
      sv.lowerBound = resourceData.composition.lowerBound;
    }
  }

  // TODO: this should be at BaseObject class check if the isPropertyBlackListed affects.
  public toJSON(): any {
    // wait, wat, why not just return a key-value object? O.o
    const objectToSerialize: any = {};
    let val: any;
    for (val in this) {
      // only simulation variable id will be serialized
      // maybe detect all base objects?
      if (this[val] instanceof SimulationVariable) {
        objectToSerialize[val] = (<SimulationVariable>this[val]).id;
      } else if (this[val] === this.composition) {
        objectToSerialize.composition = [];
        for (const c of this.composition) {
          objectToSerialize.composition.push(c.id);
        }
      } else if (this.isPropertyBlackListed(this[val])) {
        // welp, nothing
      } else {
        objectToSerialize[val] = this[val];
      }
    }

    return objectToSerialize;
  }

  override dePersist(sfa: any) {
    this.massDensity = this.getSimulationVariableSafe(sfa.massDensity);
    this.ccr = this.getSimulationVariableSafe(sfa.ccr);
    this.sulfur = this.getSimulationVariableSafe(sfa.sulfur);
    this.nitrogen = this.getSimulationVariableSafe(sfa.nitrogen);

    if (sfa.composition.length === 9) {
      this.lightEndsCut.dePersist(sfa.lightEndsCut);
      this.lightNaphthaCut.dePersist(sfa.lightNaphthaCut);
      this.heavyNaphthaCut.dePersist(sfa.heavyNaphthaCut);
      this.distillateCut.dePersist(sfa.distillateCut);
      this.LGOCut.dePersist(sfa.LGOCut);
      this.HGOCut.dePersist(sfa.HGOCut);
      this.VGOCut.dePersist(sfa.VGOCut);
      this.HVGOCut.dePersist(sfa.HVGOCut);
      this.residCut.dePersist(sfa.residCut);

      for (let idx = 0; idx < 9; idx++) {
        this.composition.push(this.ownerCase.getSimulationVariable(sfa.composition[idx]));
      }
      this.setCompositionNames();
    }
  }

  private initFreeGasComposition(plainComposition: FreeGasComposition) {
    if (!plainComposition) {
      this.freeGasComposition = new FreeGasComposition();
      return;
    }

    this.freeGasComposition = FreeGasComposition.createFromPlainObject(plainComposition);
  }
}
