import * as go from 'gojs';
import { UnitOperation } from '../_models';
import { DCU } from '../_models/_unit-operations/dcu';
import { SeparateGasStreamUserUnitBlockUpdater } from '../_services/flowsheet-diagram/separate-gas-stream-user-unit-block-updater';
import { CoreService } from '../_services/core.service';
import { FluidCoker } from '../_models/_unit-operations/fluid-coker';

export class DiagramMigrationsHandler {
  static updateSeparateGasStreamUserUnitNode(gojsDiagram: go.Diagram, coreService: CoreService) {
    const filteredSeparateGasStreamUserUnits = coreService.currentCase.filterUnitOperations(
      uo => uo instanceof DCU || uo instanceof FluidCoker
    ) as DCU[] | FluidCoker[];

    for (const unitOp of filteredSeparateGasStreamUserUnits) {
      SeparateGasStreamUserUnitBlockUpdater.create().updateGoJsBlock(
        unitOp.id,
        unitOp.hasSeparateGasStream,
        coreService,
        gojsDiagram
      );
      this.updateLinksAttachedToSeparateGasStreamUserUnit(gojsDiagram, unitOp);
    }
  }

  static updateLinksAttachedToSeparateGasStreamUserUnit(gojsDiagram: go.Diagram, unitOp: UnitOperation) {
    const model = gojsDiagram.model as go.GraphLinksModel;

    model.startTransaction('renameLinkPorts');
    let links: go.ObjectData[] = [];

    if (unitOp instanceof DCU) {
      links = model.linkDataArray.filter(
        link => link['fromPort'] === 'lightEnds' && link['inletUnitOperationId'] === unitOp.id
      );
    } else if (unitOp instanceof FluidCoker) {
      links = model.linkDataArray.filter(
        link => link['fromPort'] === 'butane' && link['inletUnitOperationId'] === unitOp.id
      );
    }

    links.forEach(link => {
      model.setFromPortIdForLinkData(link, 'gas');
    });

    model.commitTransaction('renameLinkPorts');
  }
}
