import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DiluentSource } from '../../../_models/_unit-operations/diluent-source';
import { CoreService } from '../../../_services/core.service';
import { SuncorFluidAnalysis } from '../../../_models/_fluid/suncor-fluid-analysis';
import { AssayType } from '../../../_config/assay-type.enum';

@Component({
  selector: 'sob-diluent-source',
  templateUrl: './diluent-source.component.html',
  styleUrls: ['./diluent-source.component.css'],
})
export class DiluentSourceComponent implements OnInit {
  @Input()
  unitOperation: DiluentSource;

  currentAnalysis: SuncorFluidAnalysis;

  constructor(private coreService: CoreService, private ref: ChangeDetectorRef) {}

  ngOnInit() {
    this.updateCurrentAnalysis();
  }

  isOptimizableChanged(checked: boolean) {
    if (checked) {
      this.unitOperation.volumetricFlowrate.value = null;
    }
  }

  get fluidAnalyses() {
    return this.coreService.currentCase.assayManager.getAssaysByType(AssayType.DILUENT);
  }

  updateCurrentAnalysis(id?) {
    if (id) {
      this.unitOperation.fluidAnalysisId = id;
    }
    if (this.unitOperation.fluidAnalysisId) {
      this.currentAnalysis = this.coreService.currentCase.assayManager.findFluidAnalysisById(
        this.unitOperation.fluidAnalysisId
      );
    }
  }
}
