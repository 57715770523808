<div [formGroup]="propertyWindowFormGroup">
  <div class="form-horizontal">
    <div class="form-group">
      <label class="pw-label control-label col-sm-4"> Assay Name </label>
      <div class="col-sm-6">
        <sob-assay-selector
          [fluidAnalyses]="fluidAnalyses"
          [currentAnalysis]="currentAnalysis"
          (updateCurrentAssay)="updateCurrentAnalysis($event)"
          formControlName="fluidAnalysisId">
        </sob-assay-selector>
      </div>
    </div>
  </div>
  <hr />
  <ul class="nav nav-tabs tabs-margin" role="tablist">
    <li role="presentation" class="active">
      <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">General</a>
    </li>
    <li role="presentation">
      <a href="#economics" aria-controls="economics" role="tab" data-toggle="tab">Economics</a>
    </li>
    <li *ngIf="currentAnalysis" role="presentation">
      <a href="#productspecs" aria-controls="productspecs" role="tab" data-toggle="tab">Product Specifications</a>
    </li>

    <li role="presentation">
      <a href="#constraints" aria-controls="constraints" role="tab" data-toggle="tab"> Constraints </a>
    </li>

    <li role="presentation">
      <a href="#utility" aria-controls="utility" role="tab" data-toggle="tab">Utilities</a>
    </li>

    <li role="presentation">
      <a href="#comments" aria-controls="comments" role="tab" data-toggle="tab">
        <i class="fa fa-comments fa-lg" aria-hidden="true"> </i>
        Comments
      </a>
    </li>
  </ul>

  <div class="tab-content pw-margin tab-content-padding">
    <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
      <div class="tab-content no-padding no-border">
        <div class="form-horizontal">
          <div class="form-group">
            <label for="separateGasStream" class="control-label col-sm-4"> Separate Gas Stream </label>
            <div class="col-sm-4">
              <label class="control-label">
                <input
                  formControlName="separateLightEndsProduct"
                  type="checkbox"
                  id="separateGasStream"
                  (change)="separateGasStreamChanged()" />
              </label>
            </div>
          </div>
          <ng-container>
            <div class="form-group" formGroupName="totalYield">
              <label class="control-label col-sm-4"> Volumetric Yield </label>

              <div class="col-sm-8" sobReactiveSimVarInput>
                <div class="row">
                  <div class="col-xs-6">
                    <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.totalYield.quantity">
                    </sob-reactive-unit-select>
                  </div>
                  <div class="col-xs-6">
                    <sob-reactive-variable-value-input
                      formControlName="value"
                      [valueMetaData]="unitOperation.totalYield">
                    </sob-reactive-variable-value-input>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group" formGroupName="totalInletVolume">
              <label class="control-label col-sm-4"> Total Inlet Volume </label>

              <div class="col-sm-8" sobReactiveSimVarInput>
                <div class="row">
                  <div class="col-xs-6">
                    <sob-reactive-unit-select
                      formControlName="unit"
                      [quantity]="unitOperation.totalInletVolume.quantity">
                    </sob-reactive-unit-select>
                  </div>
                  <div class="col-xs-6">
                    <sob-reactive-variable-value-input
                      formControlName="value"
                      [valueMetaData]="unitOperation.totalInletVolume">
                    </sob-reactive-variable-value-input>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group" *ngIf="lightEndsProduct" formGroupName="butaneRecovery">
              <label class="control-label col-sm-4"> Butane Recovery </label>

              <div class="col-sm-8" sobReactiveSimVarInput>
                <div class="row">
                  <div class="col-xs-6">
                    <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.butaneRecovery.quantity">
                    </sob-reactive-unit-select>
                  </div>
                  <div class="col-xs-6">
                    <sob-reactive-variable-value-input
                      formControlName="value"
                      [valueMetaData]="unitOperation.butaneRecovery">
                    </sob-reactive-variable-value-input>
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <h4 class="subtitle">GHG</h4>

            <div class="form-group" formGroupName="ghgIntensity">
              <label class="control-label col-sm-4"> GHG Intensity </label>

              <div class="col-sm-8" sobReactiveSimVarInput>
                <div class="row">
                  <div class="col-xs-6">
                    <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.ghgIntensity.quantity">
                    </sob-reactive-unit-select>
                  </div>
                  <div class="col-xs-6">
                    <sob-reactive-variable-value-input
                      formControlName="value"
                      [valueMetaData]="unitOperation.ghgIntensity">
                    </sob-reactive-variable-value-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group" formGroupName="ghgEmissions">
              <label class="control-label col-sm-4"> GHG Emissions </label>

              <div class="col-sm-8" sobReactiveSimVarInput>
                <div class="row">
                  <div class="col-xs-6">
                    <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.ghgEmissions.quantity">
                    </sob-reactive-unit-select>
                  </div>
                  <div class="col-xs-6">
                    <sob-reactive-variable-value-input
                      formControlName="value"
                      [valueMetaData]="unitOperation.ghgEmissions">
                    </sob-reactive-variable-value-input>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div role="tabpanel" class="tab-pane fade in" id="economics">
      <div class="tab-content no-padding no-border">
        <div class="form-horizontal">
          <h4 class="subtitle">OPEX</h4>
          <ng-container>
            <div class="form-group" formGroupName="opexFixed">
              <label class="control-label col-sm-4"> Fixed </label>

              <div class="col-sm-8" sobReactiveSimVarInput>
                <div class="row">
                  <div class="col-xs-6">
                    <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.opexFixed.quantity">
                    </sob-reactive-unit-select>
                  </div>
                  <div class="col-xs-6">
                    <sob-reactive-variable-value-input
                      formControlName="value"
                      [valueMetaData]="unitOperation.opexFixed">
                    </sob-reactive-variable-value-input>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group" formGroupName="opexVar">
              <label class="control-label col-sm-4"> Variable </label>

              <div class="col-sm-8" sobReactiveSimVarInput>
                <div class="row">
                  <div class="col-xs-6">
                    <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.opexVar.quantity">
                    </sob-reactive-unit-select>
                  </div>
                  <div class="col-xs-6">
                    <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.opexVar">
                    </sob-reactive-variable-value-input>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group" formGroupName="opexCarbonDioxide">
              <label class="control-label col-sm-4"> CO2 </label>

              <div class="col-sm-8" sobReactiveSimVarInput>
                <div class="row">
                  <div class="col-xs-6">
                    <sob-reactive-unit-select
                      formControlName="unit"
                      [quantity]="unitOperation.opexCarbonDioxide.quantity">
                    </sob-reactive-unit-select>
                  </div>
                  <div class="col-xs-6">
                    <sob-reactive-variable-value-input
                      formControlName="value"
                      [valueMetaData]="unitOperation.opexCarbonDioxide">
                    </sob-reactive-variable-value-input>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group" formGroupName="opexTotal">
              <label class="control-label col-sm-4"> Total </label>

              <div class="col-sm-8" sobReactiveSimVarInput>
                <div class="row">
                  <div class="col-xs-6">
                    <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.opexTotal.quantity">
                    </sob-reactive-unit-select>
                  </div>
                  <div class="col-xs-6">
                    <sob-reactive-variable-value-input
                      formControlName="value"
                      [valueMetaData]="unitOperation.opexTotal">
                    </sob-reactive-variable-value-input>
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <h4 class="subtitle">CAPEX</h4>

            <div class="form-group" formGroupName="capexAmortized">
              <label class="control-label col-sm-4"> Amortized CAPEX </label>

              <div class="col-sm-8" sobReactiveSimVarInput>
                <div class="row">
                  <div class="col-xs-6">
                    <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.capexAmortized.quantity">
                    </sob-reactive-unit-select>
                  </div>
                  <div class="col-xs-6">
                    <sob-reactive-variable-value-input
                      formControlName="value"
                      [valueMetaData]="unitOperation.capexAmortized">
                    </sob-reactive-variable-value-input>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group" formGroupName="capexTotal">
              <label class="control-label col-sm-4"> Total </label>

              <div class="col-sm-8" sobReactiveSimVarInput>
                <div class="row">
                  <div class="col-xs-6">
                    <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.capexTotal.quantity">
                    </sob-reactive-unit-select>
                  </div>
                  <div class="col-xs-6">
                    <sob-reactive-variable-value-input
                      formControlName="value"
                      [valueMetaData]="unitOperation.capexTotal">
                    </sob-reactive-variable-value-input>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div *ngIf="currentAnalysis" role="tabpanel" class="tab-pane fade in" id="productspecs">
      <sob-reactive-suncor-fluid-analysis-properties
        [currentAnalysis]="currentAnalysis"></sob-reactive-suncor-fluid-analysis-properties>
    </div>

    <div role="tabpanel" class="tab-pane fade in" id="constraints">
      <div class="tab-content no-padding no-border">
        <div class="form-horizontal">
          <div class="form-group">
            <label for="constraintOption" class="pw-label control-label col-sm-4"> Constraint Option </label>
            <div class="col-sm-6">
              <select
                id="constraintOption"
                (change)="disableFeedSlateCheckBox()"
                class="form-control"
                formControlName="constraintOption">
                <option value="blendRatio">Blend Ratio</option>
                <option value="properties">Properties</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label class="pw-label control-label col-sm-4 subtitle-v2"> Feedslate </label>
          </div>
          <div class="form-group">
            <label for="feedSlateDisabled" class="control-label col-sm-4"> Disable FeedSlate </label>

            <div class="col-sm-4">
              <label class="control-label">
                <input
                  type="checkbox"
                  id="feedSlateDisabled"
                  formControlName="feedSlateDisabled"
                  (change)="feedSlateCheckBoxChange()" />
              </label>
            </div>
          </div>

          <div class="form-group">
            <label for="typeDD" class="pw-label control-label col-sm-4"> Blend Recipe Option </label>
            <div class="col-sm-6">
              <select
                id="typeDD"
                class="form-control"
                (change)="updateRecipeOption()"
                formControlName="blendRecipeOption">
                <option value="exact">Exact</option>
                <option value="range">Range</option>
              </select>
            </div>
          </div>

          <sob-range-distribution-ratio-variable
            [blendRecipeOption]="unitOperation.blendRecipeOption"
            [readonly]="feedSlateDisabled.value || constraintOption === 'properties'"
            [ratioVariables]="unitOperation.distributionRatioVariables">
          </sob-range-distribution-ratio-variable>

          <div class="form-group">
            <hr class="constraints-label-padding" />
            <label class="pw-label control-label col-sm-4 subtitle-v2"> Constraints </label>
          </div>
          <ng-container>
            <div class="form-group" formGroupName="capacity">
              <label class="control-label col-sm-4"> Maximum Feed Capacity </label>

              <div class="col-sm-8" sobReactiveSimVarInput>
                <div class="row">
                  <div class="col-xs-5">
                    <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.capacity.quantity">
                    </sob-reactive-unit-select>
                  </div>
                  <div class="col-xs-5">
                    <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.capacity">
                    </sob-reactive-variable-value-input>
                  </div>
                  <div class="col-xs-2">
                    <sob-reactive-constraint-check-box
                      formControlName="isActive"
                      [isConstraint]="true"
                      [valueMetaData]="unitOperation.capacity"></sob-reactive-constraint-check-box>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group" formGroupName="minimumFlow">
              <label class="control-label col-sm-4"> Minimum Flow </label>

              <div class="col-sm-8" sobReactiveSimVarInput>
                <div class="row">
                  <div class="col-xs-5">
                    <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.minimumFlow.quantity">
                    </sob-reactive-unit-select>
                  </div>
                  <div class="col-xs-5">
                    <sob-reactive-variable-value-input
                      formControlName="value"
                      [valueMetaData]="unitOperation.minimumFlow">
                    </sob-reactive-variable-value-input>
                  </div>
                  <div class="col-xs-2">
                    <sob-reactive-constraint-check-box
                      formControlName="isActive"
                      [isConstraint]="true"
                      [valueMetaData]="unitOperation.minimumFlow"></sob-reactive-constraint-check-box>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group" formGroupName="maximumSulfurContent">
              <label class="control-label col-sm-4"> Maximum Sulfur Content</label>

              <div class="col-sm-8" sobReactiveSimVarInput>
                <div class="row">
                  <div class="col-xs-5">
                    <sob-reactive-unit-select
                      formControlName="unit"
                      [quantity]="unitOperation.maximumSulfurContent.quantity">
                    </sob-reactive-unit-select>
                  </div>
                  <div class="col-xs-5">
                    <sob-reactive-variable-value-input
                      formControlName="value"
                      [valueMetaData]="unitOperation.maximumSulfurContent">
                    </sob-reactive-variable-value-input>
                  </div>
                  <div class="col-xs-2">
                    <sob-reactive-constraint-check-box
                      formControlName="isActive"
                      [disabledConstraint]="constraintOption === 'blendRatio'"
                      [isConstraint]="true"
                      [valueMetaData]="unitOperation.maximumSulfurContent"></sob-reactive-constraint-check-box>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group" formGroupName="maximumNitrogen">
              <label class="control-label col-sm-4"> Maximum Nitrogen</label>

              <div class="col-sm-8" sobReactiveSimVarInput>
                <div class="row">
                  <div class="col-xs-5">
                    <sob-reactive-unit-select
                      formControlName="unit"
                      [quantity]="unitOperation.maximumNitrogen.quantity">
                    </sob-reactive-unit-select>
                  </div>
                  <div class="col-xs-5">
                    <sob-reactive-variable-value-input
                      formControlName="value"
                      [valueMetaData]="unitOperation.maximumNitrogen">
                    </sob-reactive-variable-value-input>
                  </div>
                  <div class="col-xs-2">
                    <sob-reactive-constraint-check-box
                      formControlName="isActive"
                      [disabledConstraint]="constraintOption === 'blendRatio'"
                      [isConstraint]="true"
                      [valueMetaData]="unitOperation.maximumNitrogen"></sob-reactive-constraint-check-box>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div role="tabpanel" class="tab-pane fade in" id="utility">
      <div class="tab-content no-padding no-border">
        <div class="form-horizontal">
          <h4 class="subtitle">Hydrogen</h4>
          <ng-container>
            <div class="form-group" formGroupName="hydrogenUse">
              <label class="control-label col-sm-4"> Hydrogen Use </label>

              <div class="col-sm-8" sobReactiveSimVarInput>
                <div class="row">
                  <div class="col-xs-6">
                    <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.hydrogenUse.quantity">
                    </sob-reactive-unit-select>
                  </div>
                  <div class="col-xs-6">
                    <sob-reactive-variable-value-input
                      formControlName="value"
                      [valueMetaData]="unitOperation.hydrogenUse">
                    </sob-reactive-variable-value-input>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-horizontal" [formGroup]="propertyWindowFormGroup">
              <div class="form-group" *ngIf="hydrogenUseRateVariablesFormArray.length > 0">
                <div class="col-md-12">
                  <h4 class="subtitle-sm col-md-4 text-right">Hydrogen Use Rate Variables</h4>
                  <br />
                </div>
                <label [attr.for]="'hydrogenUseRate'" class="pw-label control-label col-sm-4"> Unit </label>

                <div class="col-sm-4">
                  <select id="hydrogenUseRate" class="form-control">
                    <option>scf/bbl</option>
                  </select>
                </div>
              </div>
              <div
                class="form-group"
                formArrayName="hydrogenUseRateVariables"
                *ngFor="let dv of hydrogenUseRateVariablesFormArray.controls; let i = index">
                <label [attr.for]="'hydrogenUseRate' + i" class="pw-label control-label col-sm-4">
                  {{ findDistributionRatioName(i) }}
                </label>

                <div class="col-sm-8" formGroupName="{{ i }}">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="{
                      quantity: hydrogenUseRateVariablesQuantity,
                      unit: hydrogenUseRateVariablesUnit
                    }"></sob-reactive-variable-value-input>
                  <span
                    class="text-danger"
                    *ngIf="
                      hydrogenUseRateVariablesFormArray.invalid &&
                      (hydrogenUseRateVariablesFormArray.controls[i].dirty ||
                        hydrogenUseRateVariablesFormArray.controls[i].touched)
                    ">
                    <span *ngIf="hydrogenUseRateVariablesFormArray.controls[i].errors?.required"
                      >Please enter a value</span
                    >
                  </span>
                </div>
              </div>
            </div>

            <h4 class="subtitle">Amine</h4>

            <div class="form-group" formGroupName="h2sProductionRate">
              <label class="control-label col-sm-4"> H2S Production Rate </label>

              <div class="col-sm-8" sobReactiveSimVarInput>
                <div class="row">
                  <div class="col-xs-6">
                    <sob-reactive-unit-select
                      formControlName="unit"
                      [quantity]="unitOperation.h2sProductionRate.quantity">
                    </sob-reactive-unit-select>
                  </div>
                  <div class="col-xs-6">
                    <sob-reactive-variable-value-input
                      formControlName="value"
                      [valueMetaData]="unitOperation.h2sProductionRate">
                    </sob-reactive-variable-value-input>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group" formGroupName="h2sProduction">
              <label class="control-label col-sm-4"> H2S Production</label>

              <div class="col-sm-8" sobReactiveSimVarInput>
                <div class="row">
                  <div class="col-xs-6">
                    <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.h2sProduction.quantity">
                    </sob-reactive-unit-select>
                  </div>
                  <div class="col-xs-6">
                    <sob-reactive-variable-value-input
                      formControlName="value"
                      [valueMetaData]="unitOperation.h2sProduction">
                    </sob-reactive-variable-value-input>
                  </div>
                </div>
              </div>
            </div>

            <h4 class="subtitle">Power</h4>

            <div class="form-group" formGroupName="powerUseRate">
              <label class="control-label col-sm-4"> Power Use Rate</label>

              <div class="col-sm-8" sobReactiveSimVarInput>
                <div class="row">
                  <div class="col-xs-6">
                    <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.powerUseRate.quantity">
                    </sob-reactive-unit-select>
                  </div>
                  <div class="col-xs-6">
                    <sob-reactive-variable-value-input
                      formControlName="value"
                      [valueMetaData]="unitOperation.powerUseRate">
                    </sob-reactive-variable-value-input>
                  </div>
                </div>
              </div>
            </div>

            <h4 class="subtitle">Natural Gas</h4>

            <div class="form-group" formGroupName="naturalGasUseRate">
              <label class="control-label col-sm-4"> Natural Gas Use Rate</label>

              <div class="col-sm-8" sobReactiveSimVarInput>
                <div class="row">
                  <div class="col-xs-6">
                    <sob-reactive-unit-select
                      formControlName="unit"
                      [quantity]="unitOperation.naturalGasUseRate.quantity">
                    </sob-reactive-unit-select>
                  </div>
                  <div class="col-xs-6">
                    <sob-reactive-variable-value-input
                      formControlName="value"
                      [valueMetaData]="unitOperation.naturalGasUseRate">
                    </sob-reactive-variable-value-input>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <div class="form-horizontal">
            <sob-reactive-steam-use-utility [formGroupWrapper]="formGroupWrapper" [unitOperation]="unitOperation">
            </sob-reactive-steam-use-utility>
            <sob-reactive-gas-contributor [formGroupWrapper]="formGroupWrapper" [unitOperation]="unitOperation">
            </sob-reactive-gas-contributor>
          </div>
        </div>
      </div>
    </div>
    <div role="tabpanel" class="tab-pane fade in" id="comments">
      <div class="tab-content no-padding no-border">
        <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
      </div>
    </div>
  </div>
</div>
