import { UntypedFormGroup } from '@angular/forms';
import { DefaultBaseObjectFormChangeDetector } from './default-base-object-form-change-detector';
import { unitOperationsConfig } from '../_config/unit-operations.config';
import { WaterSinkImportFormChangeDetector } from './water-sink-import-form-change-detector';
import { WaterSourceImportFormChangeDetector } from './water-source-import-form-change-detector';
import { WaterHeaderFormChangeDetector } from './water-header-form-change-detector';
import { WaterSplitterFormChangeDetector } from './water-splitter-form-change-detector';
import { FuelGasSplitterFormChangeDetector } from './fuel-gas-splitter-form-change-detector';
import { FuelGasSinkImportFormChangeDetector } from './fuel-gas-sink-import-form-change-detector';
import { FuelGasSourceImportFormChangeDetector } from './fuel-gas-source-import-form-change-detector';
import { SplitterFormChangeDetector } from './splitter-form-change-detector';
import { LgoHydrotreaterFormChangeDetector } from './lgo-hydrotreater-form-change-detector';
import { ProductTankFormChangeDetector } from './product-tank-form-change-detector';
import { MixerFormChangeDetector } from './mixer-form-change-detector';
import { BitumenConversionFormChangeDetector } from './bitumen-conversion-form-change-detector';

export class BaseObjectFormChangeDetector {
  private readonly detectors: { [id: string]: DefaultBaseObjectFormChangeDetector };
  private readonly defaultChangeDetector: DefaultBaseObjectFormChangeDetector;

  constructor(private formGroup: UntypedFormGroup) {
    this.detectors = {};

    this.defaultChangeDetector = new DefaultBaseObjectFormChangeDetector(this.formGroup);

    this.detectors[unitOperationsConfig.bitumenConversion.key] = new BitumenConversionFormChangeDetector(
      this.formGroup
    );
    this.detectors[unitOperationsConfig.refinery.key] = this.defaultChangeDetector;
    this.detectors[unitOperationsConfig.productTank.key] = new ProductTankFormChangeDetector(this.formGroup);
    this.detectors[unitOperationsConfig.mixer.key] = new MixerFormChangeDetector(this.formGroup);
    this.detectors[unitOperationsConfig.splitter.key] = new SplitterFormChangeDetector(this.formGroup);
    this.detectors[unitOperationsConfig.hydrotreater.key] = this.defaultChangeDetector;
    this.detectors[unitOperationsConfig.lgoHydrotreater.key] = new LgoHydrotreaterFormChangeDetector(this.formGroup);
    this.detectors[unitOperationsConfig.gasExport.key] = this.defaultChangeDetector;
    this.detectors[unitOperationsConfig.lightEndsRecoveryUnit.key] = this.defaultChangeDetector;
    this.detectors[unitOperationsConfig.flexiformer.key] = this.defaultChangeDetector;

    this.detectors[unitOperationsConfig.pipe.key] = this.defaultChangeDetector;
    this.detectors[unitOperationsConfig.waterSource.key] = this.defaultChangeDetector;
    this.detectors[unitOperationsConfig.waterTurbine.key] = this.defaultChangeDetector;
    this.detectors[unitOperationsConfig.waterCogeneration.key] = this.defaultChangeDetector;
    this.detectors[unitOperationsConfig.waterBoiler.key] = this.defaultChangeDetector;
    this.detectors[unitOperationsConfig.sulfurPlant.key] = this.defaultChangeDetector;

    this.detectors[unitOperationsConfig.fuelGasSink.key] = this.defaultChangeDetector;
    this.detectors[unitOperationsConfig.fuelGasFlare.key] = this.defaultChangeDetector;
    this.detectors[unitOperationsConfig.fuelGasSource.key] = this.defaultChangeDetector;
    this.detectors[unitOperationsConfig.fuelGasAmineContactor.key] = this.defaultChangeDetector;

    this.detectors[unitOperationsConfig.waterHeader.key] = new WaterHeaderFormChangeDetector(this.formGroup);
    this.detectors[unitOperationsConfig.waterSplitter.key] = new WaterSplitterFormChangeDetector(this.formGroup);
    this.detectors[unitOperationsConfig.waterSinkImport.key] = new WaterSinkImportFormChangeDetector(this.formGroup);
    this.detectors[unitOperationsConfig.waterSourceImport.key] = new WaterSourceImportFormChangeDetector(
      this.formGroup
    );

    this.detectors[unitOperationsConfig.fuelGasSplitter.key] = new FuelGasSplitterFormChangeDetector(this.formGroup);
    this.detectors[unitOperationsConfig.fuelGasSinkImport.key] = new FuelGasSinkImportFormChangeDetector(
      this.formGroup
    );
    this.detectors[unitOperationsConfig.fuelGasSourceImport.key] = new FuelGasSourceImportFormChangeDetector(
      this.formGroup
    );
  }

  detectChangesInValues(originalFormValue: any, currentValue: any, unitOperationCategory: any) {
    let changes = false;
    changes = this.detectors[unitOperationCategory].detectChangesInValues(originalFormValue, currentValue);
    return changes;
  }
}
