<div class="form-horizontal">
  <div class="form-group">
    <label for="typeDD" class="pw-label control-label col-sm-4"> Assay Name </label>
    <div class="col-sm-6">
      <sob-assay-selector
        [fluidAnalyses]="fluidAnalyses"
        [currentAnalysis]="currentAnalysis"
        (updateCurrentAssay)="updateCurrentAnalysis($event)">
      </sob-assay-selector>
    </div>
  </div>
</div>
<hr />

<ul class="nav nav-tabs m-t-md" role="tablist">
  <li role="presentation" class="active">
    <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">General</a>
  </li>
  <li role="presentation">
    <a href="#utilities" aria-controls="utilities" role="tab" data-toggle="tab">Utilities</a>
  </li>

  <li *ngIf="currentAnalysis" role="presentation">
    <a href="#productspecs" aria-controls="productspecs" role="tab" data-toggle="tab">Product Specifications</a>
  </li>

  <li role="presentation">
    <a href="#constraints" aria-controls="constraints" role="tab" data-toggle="tab"> Constraints </a>
  </li>

  <li role="presentation">
    <a href="#hgoHydrotreaterComments" aria-controls="hgoHydrotreaterComments" role="tab" data-toggle="tab">
      <i class="fa fa-comments fa-lg" aria-hidden="true"> </i>
      Comments
    </a>
  </li>
</ul>

<div class="tab-content pw-margin tab-content-padding">
  <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <div class="form-group">
          <label [attr.for]="'catalystRun'" class="pw-label control-label col-sm-4"> Yield Basis </label>
          <div class="col-sm-6">
            <select class="form-control" [(ngModel)]="unitOperation.catalystRun">
              <option value="{{ HgoHydroTreaterCatalystRun.EOR }}">EOR</option>
              <option value="{{ HgoHydroTreaterCatalystRun.SOR }}">SOR</option>
            </select>
          </div>
        </div>

        <div class="form-group">
          <label [attr.for]="'totalInletVolume'" class="pw-label control-label col-sm-4"> Total Inlet Volume </label>
          <sim-var-input [inputId]="'totalInletVolume'" [simVar]="unitOperation.totalInletVolume" [readOnly]="true">
          </sim-var-input>
        </div>

        <div class="form-group">
          <label [attr.for]="'naphthaYield'" class="pw-label control-label col-sm-4"> Naphtha Yield </label>
          <sim-var-input
            [inputId]="'naphthaYield'"
            [simVar]="unitOperation.naphthaYield"
            [readOnly]="unitOperation.useCustomModel"></sim-var-input>
        </div>

        <div class="form-group">
          <label [attr.for]="'lgoYield'" class="pw-label control-label col-sm-4"> LGO Yield </label>
          <sim-var-input
            [inputId]="'lgoYield'"
            [simVar]="unitOperation.lgoYield"
            [readOnly]="unitOperation.useCustomModel"></sim-var-input>
        </div>

        <div class="form-group">
          <label [attr.for]="'hgoYield'" class="pw-label control-label col-sm-4"> HGO Yield </label>
          <sim-var-input
            [inputId]="'hgoYield'"
            [simVar]="unitOperation.hgoYield"
            [readOnly]="unitOperation.useCustomModel"></sim-var-input>
        </div>

        <div class="form-group">
          <label [attr.for]="'totalYield'" class="pw-label control-label col-sm-4"> Total Yield </label>
          <sim-var-input [inputId]="'totalYield'" [simVar]="unitOperation.totalYield" [readOnly]="true"></sim-var-input>
        </div>

        <hr />
        <h4 class="subtitle">GHG</h4>
        <div class="form-group">
          <label [attr.for]="'ghgIntensity'" class="pw-label control-label col-sm-4"> GHG Intensity </label>
          <sim-var-input [inputId]="'ghgIntensity'" [simVar]="unitOperation.ghgIntensity"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'ghgEmissions'" class="pw-label control-label col-sm-4"> GHG Emissions </label>
          <sim-var-input [inputId]="'ghgEmissions'" [simVar]="unitOperation.ghgEmissions" [readOnly]="true">
          </sim-var-input>
        </div>

        <hr />

        <div class="form-group">
          <label for="customModelEnabled" class="control-label col-sm-4"> Custom Model</label>
          <div class="col-sm-4">
            <label class="control-label">
              <input type="checkbox" id="customModelEnabled" [(ngModel)]="unitOperation.useCustomModel" />
            </label>
          </div>

          <div class="clearfix"></div>
        </div>

        <div>
          <sob-open-code-editor-button
            [code]="unitOperation.customModelScript"
            [defaultCodeProvider]="defaultCodeProvider"
            [disabled]="!unitOperation.useCustomModel"
            [instanceId]="'hgoHydrotreaterCodeEditor'"
            (codeSaved)="saveCustomModel($event)">
          </sob-open-code-editor-button>
        </div>

        <hr class="hr-margin" />

        <div class="form-horizontal">
          <div class="form-group mt-2">
            <label for="separateGasStreamEnabled" class="pw-label control-label col-sm-4"> Separate Gas Stream </label>
            <div class="col-sm-4">
              <label class="control-label">
                <input
                  type="checkbox"
                  id="separateGasStreamEnabled"
                  [(ngModel)]="unitOperation.hasSeparateGasStream"
                  (ngModelChange)="separateGasStreamChanged()" />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="utilities">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <h4 class="subtitle">Hydrogen</h4>
        <div class="form-group">
          <label [attr.for]="'hydrogenUseRate'" class="pw-label control-label col-sm-4"> Hydrogen Use Rate </label>
          <sim-var-input [inputId]="'hydrogenUseRate'" [simVar]="unitOperation.hydrogenUseRate"> </sim-var-input>
        </div>

        <div class="form-group">
          <label [attr.for]="'hydrogenUse'" class="pw-label control-label col-sm-4"> Hydrogen Use </label>
          <sim-var-input [inputId]="'hydrogenUse'" [simVar]="unitOperation.hydrogenUse" [readOnly]="true">
          </sim-var-input>
        </div>

        <hr />

        <h4 class="subtitle">Amine</h4>
        <div class="form-group">
          <label [attr.for]="'h2sProductionRate'" class="pw-label control-label col-sm-4"> H2S Production Rate </label>
          <sim-var-input
            [inputId]="'h2sProductionRate'"
            [simVar]="unitOperation.h2sProductionRate"
            [readOnly]="true"></sim-var-input>
        </div>

        <div class="form-group">
          <label [attr.for]="'h2sProduction'" class="pw-label control-label col-sm-4"> H2S Production </label>
          <sim-var-input [inputId]="'h2sProduction'" [simVar]="unitOperation.h2sProduction" [readOnly]="true">
          </sim-var-input>
        </div>

        <sob-steam-use-utility [unitOperation]="unitOperation"></sob-steam-use-utility>
        <sob-gas-contributor [unitOperation]="unitOperation"></sob-gas-contributor>
      </div>
    </div>
  </div>

  <div *ngIf="currentAnalysis" role="tabpanel" class="tab-pane fade in" id="productspecs">
    <sob-suncor-fluid-analysis-properties [currentAnalysis]="currentAnalysis"></sob-suncor-fluid-analysis-properties>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="constraints">
    <p class="constraints-active-label">
      <span>Active</span>
    </p>
    <div class="form-horizontal">
      <div class="form-group">
        <label [attr.for]="'capacity'" class="pw-label control-label col-sm-4"> Maximum Feed Capacity </label>
        <sim-var-input [inputId]="'capacity'" [isConstraint]="true" [simVar]="unitOperation.capacity"> </sim-var-input>
      </div>

      <div class="form-group">
        <label [attr.for]="'minimumFlow'" class="pw-label control-label col-sm-4"> Minimum Flow </label>
        <sim-var-input [inputId]="'minimumFlow'" [isConstraint]="true" [simVar]="unitOperation.minimumFlow">
        </sim-var-input>
      </div>

      <div class="form-group">
        <label [attr.for]="'maximumSulfurContent'" class="pw-label control-label col-sm-4">
          Maximum Sulfur Content
        </label>
        <sim-var-input
          [inputId]="'maximumSulfurContent'"
          [isConstraint]="true"
          [simVar]="unitOperation.maximumSulfurContent">
        </sim-var-input>
      </div>

      <div class="form-group">
        <label [attr.for]="'maximumNitrogen'" class="pw-label control-label col-sm-4"> Maximum Nitrogen </label>
        <sim-var-input [inputId]="'maximumNitrogen'" [isConstraint]="true" [simVar]="unitOperation.maximumNitrogen">
        </sim-var-input>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="hgoHydrotreaterComments">
    <div class="tab-content no-padding no-border">
      <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
    </div>
  </div>
</div>
