<ul class="nav nav-pills product-specs-margin" role="tablist">
  <li role="presentation" class="active">
    <a
      href="#bulkPropertiesTabContent"
      id="bulkPropertiesTab"
      aria-controls="bulkProperties"
      role="tab"
      data-toggle="tab"
      >Bulk Properties</a
    >
  </li>

  <li role="presentation">
    <a class="px-2" href="#cutProperties" aria-controls="cutProperties" role="tab" data-toggle="tab">Cut Properties</a>
  </li>

  <li role="presentation">
    <a href="#compositionUoTabContent" id="compositionUoTab" aria-controls="composition" role="tab" data-toggle="tab"
      >Composition</a
    >
  </li>

  <li role="presentation">
    <a
      id="gasCompositionUoTab"
      href="#gasCompositionUoTabContent"
      aria-controls="composition"
      role="tab"
      data-toggle="tab"
      >Gas Composition</a
    >
  </li>
</ul>

<div class="tab-content mt-4" *ngIf="currentAnalysis">
  <div role="tabpanel" class="tab-pane fade in active" id="bulkPropertiesTabContent">
    <sob-bulk-properties [currentAnalysis]="currentAnalysis"></sob-bulk-properties>
  </div>

  <div role="tabpanel" class="tab-pane fade in px-4" id="cutProperties">
    <sob-reactive-cut-properties [readonly]="true" [fluidAnalysis]="currentAnalysis"></sob-reactive-cut-properties>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="compositionUoTabContent">
    <sob-composition
      [currentAnalysis]="currentAnalysis"
      (showFreeGasCompositionClick)="showFreeGasCompositionTab()"></sob-composition>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="gasCompositionUoTabContent">
    <sob-free-gas-composition-form
      [freeGasComposition]="currentAnalysis.freeGasComposition"
      [widerInputs]="true"
      [disabled]="true"
      (returnToOilComposition)="showCompositionTab()"></sob-free-gas-composition-form>
  </div>
</div>
