export enum Quantity {
  ANGLE = 'Angle',
  AREA = 'Area',
  CURRENCY = 'Currency',
  CURRENCY_PER_ENERGY = 'CurrencyPerEnergy',
  CURRENCY_PER_ELECTRICITY = 'CurrencyPerElectricity',
  CURRENCY_PER_MASS = 'CurrencyPerMass',
  CURRENCY_PER_DISTANCE = 'CurrencyPerDistance',
  CURRENCY_PER_STD_H2_VOLUME = 'CurrencyPerStdH2Volume',
  CURRENCY_PER_TIME = 'CurrencyPerTime',
  CURRENCY_PER_VOLUME = 'CurrencyPerVolume',
  DELTA_PRESSURE = 'DeltaPressure',
  DELTA_TEMPERATURE = 'DeltaTemperature',
  DIMENSIONLESS = 'Dimensionless',
  ENERGY_PER_POWER = 'EnergyPerPower',
  ENERGY_PER_VOLUME = 'EnergyPerVolume',
  ENERGY_PER_MASS = 'EnergyPerMass',
  ENERGY_PER_MOLE = 'EnergyPerMole',
  ENERGY_PER_TIME = 'EnergyPerTime',
  ENERGYRATE = 'Energyrate',
  FLOW_COEFFICIENT = 'FlowCoefficient',
  FRACTION = 'Fraction',
  GAS_STD_VOLUME_PER_VOLUME = 'GasStdVolumePerVolume',
  GOR = 'Gor',
  HEAT_FLUX_DENSITY = 'HeatFluxDensity',
  LENGTH = 'Length',
  LIQUID_DENSITY = 'LiquidDensity',
  LIQUID_VOLUMETRIC_FLOWRATE = 'LiquidVolumetricFlowrate',
  MASS_DENSITY = 'MassDensity',
  MASS_EMISSIONS_PER_VOLUME = 'MassEmissionsPerVolume',
  MASS_EMISSIONS_PER_FUEL_MASS = 'MassEmissionsPerFuelMass',
  MASS_PER_ENERGY = 'MassPerEnergy',
  MASS_PER_STD_H2_VOLUME = 'MassPerStdH2Volume',
  MASS_PER_VOLUME = 'MassPerVolume',
  MASSFLOWRATE = 'Massflowrate',
  MASS_FRACTION = 'MassFraction',
  MOLARFLOWRATE = 'Molarflowrate',
  MOLEFLOWRATE = 'Moleflowrate',
  SMALL_GASFLOWRATE = 'SmallGasFlowRate',
  MOLAR_DENSITY = 'MolarDensity',
  MOLAR_ENTHALPY = 'MolarEnthalpy',
  MOLAR_ENTROPY = 'MolarEntropy',
  MOLAR_HEAT_CAPACITY = 'MolarHeatCapacity',
  MOLAR_WEIGHT = 'MolarWeight',
  MOLE_FRACTION = 'MoleFraction',
  PERCENT = 'Percent',
  POWER = 'Power',
  POWER_PER_VOLUMETRIC_FLOW = 'PowerPerVolumetricFlow',
  PRESSURE = 'Pressure',
  SPEED = 'Speed',
  SURFACE_TENSION = 'SurfaceTension',
  TEMPERATURE = 'Temperature',
  THERMAL_CONDUCTIVITY = 'ThermalConductivity',
  TIME = 'Time',
  U = 'U',
  UA = 'UA',
  VISCOSITY = 'Viscosity',
  VOLUME = 'Volume',
  VOLUME_FRACTION = 'VolumeFraction',
  VOLUMETRIC_FLOWRATE = 'Volumetricflowrate',
  LARGE_TIME = 'LargeTime',
  ENERGY = 'Energy',
  EMBODIED_ENERGY = 'EmbodiedEnergy',
  INTENSITY = 'Intensity',
  MASS_PER_AREA = 'MassPerArea',
  CURRENCY_PER_AREA = 'CurrencyPerArea',
  CURRENCY_PER_AREA_TIME = 'CurrencyPerAreaTime',
  CURRENCY_PER_POWER_CAPACITY = 'CurrencyPerPowerCapacity',
  MASS_PER_MASS = 'MassPerMass',
  CURRENCY_PER_MASS_TIME = 'CurrencyPerMassTime',
  CURRENCY_PER_POWER_CAPACITY_TIME = 'CurrencyPerPowerCapacityTime',
}
