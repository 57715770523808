import { Component, inject, Input, OnInit } from '@angular/core';
import { MineSource } from '../../../_models/_unit-operations/mine-source';
import { AssayType } from '../../../_config/assay-type.enum';
import { SuncorFluidAnalysis } from '../../../_models/_fluid/suncor-fluid-analysis';
import { CoreService } from '../../../_services/core.service';

@Component({
  selector: 'sob-mine-source',
  templateUrl: './mine-source.component.html',
  styleUrls: ['./mine-source.component.css'],
})
export class MineSourceComponent implements OnInit {
  @Input() unitOperation: MineSource;
  currentAnalysis: SuncorFluidAnalysis;
  private readonly coreService = inject(CoreService);

  ngOnInit() {
    this.updateCurrentAnalysis();
  }

  updateCurrentAnalysis(id?: string): void {
    if (id) {
      this.unitOperation.fluidAnalysisId = id;
    }

    if (this.unitOperation.fluidAnalysisId) {
      this.currentAnalysis = this.coreService.currentCase.assayManager.findFluidAnalysisById(
        this.unitOperation.fluidAnalysisId
      );
    }
  }

  get fluidAnalyses() {
    return this.coreService.currentCase.assayManager.getAssaysByType(AssayType.CRUDE_OIL_BITUMEN);
  }
}
