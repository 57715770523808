import { Quantity } from '../../_config/quantity.enum';
import { isTypeNumber } from '../../_utils/utils';
import { SimulationVariable } from '../simulation-variable';
import { DefaultMappedType } from '../default-mapped-type';
import { VariableStatus } from '../../_config/variable-status.enum';

declare let unitConverter: any;

export class FreeGasComposition {
  quantity = Quantity.MASS_FRACTION;
  unit = unitConverter.units.MassFraction.WATER_PERCENT;

  co = 0;
  co2 = 0;
  h2 = 0;
  n2 = 0;
  h2s = 0;
  so2 = 0;
  methane = 0;
  ethane = 0;
  ethylene = 0;
  propane = 0;
  propylene = 0;
  ic4 = 0;
  nc4 = 0;
  butylenes = 0;
  ic5 = 0;
  nc5 = 0;
  cyclopentane = 0;
  c6 = 0;

  static createFromPlainObject(plainComposition: DefaultMappedType<FreeGasComposition>) {
    const fgc = new FreeGasComposition();

    fgc.unit = plainComposition.unit || unitConverter.units.MassFraction.PPMW; // todo is fgc ok?

    fgc.co = plainComposition.co;
    fgc.co2 = plainComposition.co2;
    fgc.h2 = plainComposition.h2;
    fgc.n2 = plainComposition.n2;
    fgc.h2s = plainComposition.h2s;
    fgc.so2 = plainComposition.so2;
    fgc.methane = plainComposition.methane;
    fgc.ethane = plainComposition.ethane;
    fgc.ethylene = plainComposition.ethylene;
    fgc.propane = plainComposition.propane;
    fgc.propylene = plainComposition.propylene;
    fgc.ic4 = plainComposition.ic4;
    fgc.nc4 = plainComposition.nc4;
    fgc.butylenes = plainComposition.butylenes;
    fgc.ic5 = plainComposition.ic5;
    fgc.nc5 = plainComposition.nc5;
    fgc.cyclopentane = plainComposition.cyclopentane;
    fgc.c6 = plainComposition.c6;

    return fgc;
  }

  calculateTotal(): number {
    const keys = Object.keys(this);

    let total = 0;
    for (const key of keys) {
      const value = this[key];
      if (isTypeNumber(value)) {
        total += value;
      }
    }

    return total;
  }

  getTotalAsSimulationVariable(unit: string) {
    const value = this.calculateTotal();
    return new SimulationVariable(
      { value, quantity: this.quantity, unit, variableStatus: VariableStatus.SOLVER_CALCULATED },
      null,
      null
    );
  }
}
