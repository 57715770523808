<div class="form-horizontal" [formGroup]="compositionFormGroup">
  <ng-container sobReactiveMultipleVariableInput totalOutputId="compositionTotalUO">
    <div class="form-group">
      <div class="row">
        <label class="control-label col-sm-4">Unit</label>
        <div class="col-sm-4">
          <sob-reactive-unit-select
            formControlName="compositionUnit"
            [quantity]="currentAnalysis.composition[0].quantity">
          </sob-reactive-unit-select>
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="freeGasCompositionTotalVariable">
      <div class="row">
        <label class="control-label col-sm-4">Gas Fraction</label>
        <div class="col-sm-4">
          <sob-reactive-variable-value-input
            formControlName="freeGasCompositionTotal"
            [valueMetaData]="freeGasCompositionTotalVariable">
          </sob-reactive-variable-value-input>
        </div>

        <div class="col-sm-4 px-4 px-md-0 mt-2 mt-md-0">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            title="Gas Fraction"
            (click)="showFreeGasCompositionClicked()">
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>
    </div>

    <ng-container formArrayName="composition">
      <div class="form-group" *ngFor="let sv of currentAnalysis.composition; let i = index">
        <div class="row">
          <label class="control-label col-sm-4">
            {{ currentAnalysis.composition[i].name | titleCaseComposition }}
          </label>
          <div class="col-sm-4">
            <sob-reactive-variable-value-input [formControlName]="i" [valueMetaData]="sv">
            </sob-reactive-variable-value-input>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="form-group">
      <label class="pw-label control-label col-sm-4">Total</label>
      <div class="col-sm-4">
        <input type="text" class="form-control" id="compositionTotalUO" disabled />
      </div>
    </div>
  </ng-container>
</div>
