import { Pipe, PipeTransform } from '@angular/core';

declare let unitConverter: any;

@Pipe({
  name: 'numberToUnit',
  standalone: true,
})
export class NumberToUnitPipe implements PipeTransform {
  transform(value: number | undefined, args: { unit: string; quantity: string }): any {
    if (!args) {
      return value;
    }

    const uc = unitConverter(args.quantity);
    const internalUnit = uc.getInternalUnit();

    return uc.convert(value, internalUnit, args.unit).round().format();
  }
}
