<div class="modal fade" id="assayManagerModal" tabindex="-1" role="dialog" aria-labelledby="reportsModalLabel">
  <div class="modal-lg modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          [disabled]="currentAnalysis ? !validateName(currentAnalysis.assayName) : !validateName(newAnalysisName)">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Assay Manager</h4>
      </div>
      <div class="modal-body" *ngIf="assayManager">
        <div class="row" *ngIf="!assayManager.fluidAnalyses.length">
          <div class="col-md-12">
            <div class="form-inline">
              <div class="form-group" style="margin-bottom: 20px">
                <label for="fluidAnalysisType">Type</label>
                <select
                  style="margin-left: 18px"
                  id="fluidAnalysisType"
                  [(ngModel)]="newAnalysisType"
                  class="form-control">
                  <option value="Crude oil - Bitumen">Crude Oil - Bitumen</option>
                  <option value="Diluent">Diluent</option>
                  <option value="Gas">Gas</option>
                  <option value="Product">Product</option>
                </select>
              </div>
            </div>
            <div class="form-inline">
              <div class="form-group">
                <label for="assayManagerAssayName">Name</label>
                <input
                  style="margin-left: 10px"
                  type="text"
                  [(ngModel)]="newAnalysisName"
                  class="form-control"
                  id="assayManagerAssayName" />
                <button
                  style="margin-left: 10px"
                  (click)="addFluidAnalysis()"
                  class="btn btn-primary"
                  title="Add new assay">
                  <i class="fa fa-plus"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row" [style.display]="assayManager.fluidAnalyses.length ? 'block' : 'none'">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-3">
                <ng-container *ngIf="currentAnalysis"> </ng-container>
              </div>
              <div class="col-md-9">
                <div *ngIf="!currentAnalysis">
                  <div class="col-md-12">
                    <div class="col-md-5">
                      <label class="control-label">Type</label>
                      <select [(ngModel)]="newAnalysisType" class="form-control">
                        <option value="{{ AssayType.CRUDE_OIL_BITUMEN }}">Crude Oil - Bitumen</option>
                        <option value="{{ AssayType.DILUENT }}">Diluent</option>
                        <option value="{{ AssayType.GAS }}">Gas</option>
                        <option value="{{ AssayType.PRODUCT }}">Product</option>
                      </select>
                    </div>
                    <div class="col-md-5">
                      <label class="control-label">Name</label>
                      <input
                        type="text"
                        [(ngModel)]="newAnalysisName"
                        class="form-control"
                        id="newAnalysisName"
                        placeholder="Name" />

                      <div class="row">
                        <div class="col-sm-12">
                          <span class="text-danger" *ngIf="!validateName(newAnalysisName)">
                            <span>This Analysis name already exists</span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <button
                          style="margin-top: 23px"
                          (click)="addFluidAnalysis()"
                          class="btn btn-primary"
                          title="Add new assay"
                          [disabled]="!validateName(newAnalysisName)">
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="currentAnalysis" class="col-md-12">
                  <div class="col-md-5">
                    <label class="control-label">Type</label>
                    <select disabled [(ngModel)]="currentAnalysis.assayType" class="form-control">
                      <option value="Crude oil - Bitumen">Crude Oil - Bitumen</option>
                      <option value="Diluent">Diluent</option>
                      <option value="Gas">Gas</option>
                      <option value="Product">Product</option>
                    </select>
                  </div>
                  <div class="col-md-5">
                    <label class="control-label">Name</label>
                    <input
                      type="text"
                      [(ngModel)]="currentAnalysis.assayName"
                      class="form-control"
                      id="currentAnalysisName"
                      placeholder="Name" />

                    <div class="row">
                      <div class="col-sm-12">
                        <span class="text-danger" *ngIf="!validateName(currentAnalysis.assayName)">
                          <span>This Assay name already exists</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <button
                        (click)="removeCurrentAnalysis()"
                        style="margin-top: 23px"
                        class="btn btn-primary"
                        title="back"
                        [disabled]="!validateName(currentAnalysis.assayName)">
                        <i class="fa fa-arrow-left"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" id="currentAnalysisWrapper" *ngIf="assayManager.fluidAnalyses.length > 0">
              <div class="col-md-12">
                <div>
                  <div class="padding-20" style="padding-top: 10px !important">
                    <ul class="nav nav-tabs">
                      <ng-container *ngIf="!currentAnalysis">
                        <li class="active">
                          <a href="#crudeOilBitumenAnalysisList" data-toggle="tab">Crude Oil - Bitumen</a>
                        </li>
                        <li>
                          <a href="#diluentAnalysisList" data-toggle="tab">Diluent</a>
                        </li>
                        <li>
                          <a href="#gasAnalysisList" data-toggle="tab">Fuel Gas</a>
                        </li>
                        <li>
                          <a href="#productAnalysisList" data-toggle="tab">Product</a>
                        </li>
                      </ng-container>

                      <ng-container *ngIf="currentAnalysis">
                        <li class="active" *ngIf="currentAnalysis">
                          <a href="#amComposition" data-toggle="tab" id="amCompositionTab">
                            {{ currentAnalysis.assayType === AssayType.GAS ? 'Gas' : 'Oil' }} Composition
                          </a>
                        </li>

                        <li *ngIf="currentAnalysis.assayType !== AssayType.GAS">
                          <a href="#faCuts" data-toggle="tab">Oil Cuts</a>
                        </li>

                        <li *ngIf="currentAnalysis.assayType !== AssayType.GAS">
                          <a href="#amFreeGasComposition" data-toggle="tab" id="amFreeGasCompositionTab"
                            >Free Gas Composition</a
                          >
                        </li>
                      </ng-container>
                    </ul>
                    <div class="tab-content">
                      <ng-container *ngIf="!currentAnalysis">
                        <div class="tab-pane fade in active" id="crudeOilBitumenAnalysisList" style="padding-top: 20px">
                          <div class="row">
                            <div class="col-md-12">
                              <div
                                id="crudeOilBitumenList"
                                *ngIf="assayManager.getAssaysByType(AssayType.CRUDE_OIL_BITUMEN).length > 0">
                                <sob-assay-list
                                  [assayList]="assayManager.getAssaysByType(AssayType.CRUDE_OIL_BITUMEN)"
                                  (editClicked)="setCurrentAnalysisById($event)"
                                  (removeClicked)="removeFluidAnalysis($event.assayId, $event.tableId)"
                                  (cloneClicked)="cloneFluidAnalysis($event)"></sob-assay-list>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane fade in" id="diluentAnalysisList" style="padding-top: 20px">
                          <div class="row">
                            <div class="col-md-12">
                              <div id="diluentList" *ngIf="assayManager.getAssaysByType(AssayType.DILUENT).length > 0">
                                <sob-assay-list
                                  [assayList]="assayManager.getAssaysByType(AssayType.DILUENT)"
                                  (editClicked)="setCurrentAnalysisById($event)"
                                  (removeClicked)="removeFluidAnalysis($event.assayId, $event.tableId)"
                                  (cloneClicked)="cloneFluidAnalysis($event)"></sob-assay-list>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane fade in" id="gasAnalysisList" style="padding-top: 20px">
                          <div class="row">
                            <div class="col-md-12">
                              <div id="gasList" *ngIf="assayManager.getAssaysByType(AssayType.GAS).length > 0">
                                <sob-assay-list
                                  [assayList]="assayManager.getAssaysByType(AssayType.GAS)"
                                  (editClicked)="setCurrentAnalysisById($event)"
                                  (removeClicked)="removeFluidAnalysis($event.assayId, $event.tableId)"
                                  (cloneClicked)="cloneFluidAnalysis($event)"></sob-assay-list>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane fade in" id="productAnalysisList" style="padding-top: 20px">
                          <div class="row">
                            <div class="col-md-12">
                              <div id="productList" *ngIf="assayManager.getAssaysByType(AssayType.PRODUCT).length > 0">
                                <sob-assay-list
                                  [assayList]="assayManager.getAssaysByType(AssayType.PRODUCT)"
                                  (editClicked)="setCurrentAnalysisById($event)"
                                  (removeClicked)="removeFluidAnalysis($event.assayId, $event.tableId)"
                                  (cloneClicked)="cloneFluidAnalysis($event)"></sob-assay-list>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="currentAnalysis">
                        <div class="tab-pane fade in active" id="amComposition" style="padding-top: 20px">
                          <div class="row">
                            <div class="col-md-6">
                              <div
                                class="form-horizontal"
                                sfeMultipleSimVarInput
                                [objectToValidate]="currentAnalysis"
                                totalOutputId="amCompositionTotal">
                                <div class="form-group">
                                  <label [attr.for]="'compositionUnit'" class="pw-label control-label col-sm-4">
                                    Unit
                                  </label>

                                  <div class="col-sm-4">
                                    <sfe-unit-select
                                      [selectId]="'amCompositionUnit'"
                                      [simVar]="currentAnalysis.composition[0]"
                                      [elClass]="'form-control'"></sfe-unit-select>
                                  </div>
                                </div>

                                <div class="form-group" *ngIf="freeGasCompositionTotalVariable">
                                  <label class="pw-label control-label col-sm-4">Free Gas Composition</label>
                                  <div class="col-sm-4">
                                    <sfe-value-input
                                      [simVar]="freeGasCompositionTotalVariable"
                                      [disabled]="true"
                                      [elClass]="'form-control'"
                                      [inputId]="'amFreeGasCompositionCompound'"
                                      [multiple]="true"
                                      [convertedBy]="'compositionUnit'">
                                    </sfe-value-input>
                                  </div>

                                  <div class="col-sm-4 px-4 px-md-0 mt-2 mt-md-0">
                                    <button
                                      type="button"
                                      class="btn btn-sm btn-primary"
                                      title="Free Gas Composition"
                                      (click)="showFreeGasCompositionTab()">
                                      <i class="fa fa-plus"></i>
                                    </button>
                                  </div>
                                </div>

                                <ng-container *ngFor="let idx of currentAnalysis.composition | keys">
                                  <div class="form-group">
                                    <label [attr.for]="'composition' + idx" class="pw-label control-label col-sm-4">
                                      {{ currentAnalysis.composition[idx].name | titleCaseComposition }}
                                    </label>
                                    <div class="col-sm-4">
                                      <sfe-value-input
                                        [simVar]="currentAnalysis.composition[idx]"
                                        [elClass]="'form-control'"
                                        [inputId]="'AMcomposition' + idx"
                                        [multiple]="false"
                                        [convertedBy]="'compositionUnit'">
                                      </sfe-value-input>
                                    </div>
                                  </div>
                                </ng-container>

                                <div class="form-group">
                                  <label class="pw-label control-label col-sm-4">Total</label>
                                  <div class="col-sm-4">
                                    <input type="text" class="form-control" id="amCompositionTotal" readonly />
                                  </div>
                                </div>

                                <div class="col-sm-11" style="text-align: center">
                                  <span class="text-danger">
                                    <span *ngIf="this.totalOutputWarningMessage() === 0"
                                      >Total Composition cannot be more than 1
                                    </span>
                                    <span *ngIf="this.totalOutputWarningMessage() === 1"
                                      >Total Composition cannot be more than 100%
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          *ngIf="currentAnalysis.assayType !== AssayType.GAS"
                          class="tab-pane fade in"
                          id="faCuts"
                          style="padding-top: 20px">
                          <div class="row">
                            <div class="col-md-6">
                              <sob-cut-properties
                                [fluidAnalysis]="
                                  currentAnalysis.assayType !== AssayType.GAS ? currentAnalysis : undefined
                                "
                                [idPrefix]="'assayManager'"></sob-cut-properties>
                            </div>
                          </div>
                        </div>

                        <div
                          *ngIf="currentAnalysis.assayType !== AssayType.GAS"
                          class="tab-pane fade"
                          id="amFreeGasComposition"
                          style="padding-top: 20px">
                          <div class="row">
                            <div class="col-md-8">
                              <sob-free-gas-composition-form
                                [freeGasComposition]="asSuncorFluidAnalysis(this.currentAnalysis).freeGasComposition"
                                (returnToOilComposition)="showCompositionTab()"></sob-free-gas-composition-form>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" [style.display]="!assayManager.fluidAnalyses.length ? 'block' : 'none'">
          <div class="col-md-12">
            <h4 style="padding-top: 15px">Please add a fluid analysis</h4>
          </div>
        </div>
      </div>
      <div class="modal-footer"></div>
    </div>
  </div>
</div>

<div class="modal fade" id="excelUploadModal" tabindex="-1" role="dialog" aria-labelledby="excelUploadLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">Excel upload</h4>
      </div>
      <div class="modal-body">
        <div class="alert alert-info">
          To import assays, please
          <a href="../../../assets/files/Assay%20upload.xlsx" download class="font-bold">download</a>
          the generated Excel template and fill-in information
        </div>

        <div class="form form-horizontal">
          <div class="form-group">
            <div class="col-xs-12">
              <input id="excelFileInput" name="excelFileInput" type="file" accept=".xlsx" />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button id="download" (click)="uploadAssays()" type="button" class="btn btn-primary">Upload Excel file</button>
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
