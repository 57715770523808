import { Component, Input } from '@angular/core';
import { SuncorMaterialStream } from '../../../../_models';

@Component({
  selector: 'sob-ms-bulk-properties',
  templateUrl: './ms-bulk-properties.component.html',
})
export class MsBulkPropertiesComponent {
  @Input() stream: SuncorMaterialStream;
}
