import { Component, Input, OnInit } from '@angular/core';
import { ThirdPartySource } from '../../../_models/_unit-operations/third-party-source';
import { CoreService } from '../../../_services/core.service';
import { SuncorFluidAnalysis } from '../../../_models/_fluid/suncor-fluid-analysis';
import { AssayType } from '../../../_config/assay-type.enum';

@Component({
  selector: 'sob-third-party-source',
  templateUrl: './third-party-source.component.html',
  styleUrls: ['./third-party-source.component.css'],
})
export class ThirdPartySourceComponent implements OnInit {
  @Input()
  unitOperation: ThirdPartySource;

  currentAnalysis: SuncorFluidAnalysis;

  constructor(private coreService: CoreService) {}

  ngOnInit() {
    this.updateCurrentAnalysis();
  }

  isOptimizableChanged(checked: boolean) {
    if (checked) {
      this.unitOperation.volumetricFlowrate.value = null;
    }
  }

  get fluidAnalyses() {
    return this.coreService.currentCase.assayManager.getAssaysByTypeList([
      AssayType.CRUDE_OIL_BITUMEN,
      AssayType.DILUENT,
      AssayType.PRODUCT,
    ]);
  }

  updateCurrentAnalysis(id?) {
    if (id) {
      this.unitOperation.fluidAnalysisId = id;
    }
    if (this.unitOperation.fluidAnalysisId) {
      this.currentAnalysis = this.coreService.currentCase.assayManager.findFluidAnalysisById(
        this.unitOperation.fluidAnalysisId
      );
    }
  }
}
