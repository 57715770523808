<ul class="nav nav-tabs" role="tablist" style="margin-right: 15px">
  <li role="presentation" class="active">
    <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">General</a>
  </li>
  <li role="presentation">
    <a href="#utilities" aria-controls="utilities" role="tab" data-toggle="tab">Utilities</a>
  </li>
  <li role="presentation">
    <a href="#economics" aria-controls="economics" role="tab" data-toggle="tab">Economics</a>
  </li>
  <li role="presentation">
    <a href="#constraints" aria-controls="constraints" role="tab" data-toggle="tab"> Constraints </a>
  </li>
  <li role="presentation">
    <a href="#upgCustomModels" aria-controls="upgCustomModels" role="tab" data-toggle="tab"> Custom Models </a>
  </li>

  <li role="presentation">
    <a href="#dcuComments" aria-controls="dcuComments" role="tab" data-toggle="tab">
      <i class="fa fa-comments fa-lg" aria-hidden="true"> </i>
      Comments
    </a>
  </li>
</ul>

<div class="tab-content pw-margin tab-content-padding">
  <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <div class="form-group">
          <label for="separateGasStreamEnabled" class="pw-label control-label col-sm-4"> Separate Gas Stream </label>
          <div class="col-sm-4">
            <label class="control-label">
              <input
                type="checkbox"
                id="separateGasStreamEnabled"
                [(ngModel)]="unitOperation.hasSeparateGasStream"
                (ngModelChange)="separateGasStreamChanged()" />
            </label>
          </div>
        </div>

        <div class="form-group">
          <label [attr.for]="'sweetProductRatio'" class="pw-label control-label col-sm-4"> Volumetric Yield </label>
          <sim-var-input [inputId]="'volumetricYield'" [simVar]="unitOperation.volumetricYield" [readOnly]="true">
          </sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'producedCoke'" class="pw-label control-label col-sm-4"> Produced Coke </label>
          <sim-var-input [inputId]="'producedCoke'" [simVar]="unitOperation.producedCoke" [readOnly]="true">
          </sim-var-input>
        </div>

        <hr />
        <h4 class="subtitle">GHG</h4>
        <div class="form-group">
          <label [attr.for]="'ghgIntensity'" class="pw-label control-label col-sm-4"> GHG Intensity </label>
          <sim-var-input [inputId]="'ghgIntensity'" [simVar]="unitOperation.ghgIntensity"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'percentCokeToCombustion'" class="pw-label control-label col-sm-4">
            Coke to Combustion
          </label>
          <sim-var-input [inputId]="'percentCokeToCombustion'" [simVar]="unitOperation.percentCokeToCombustion">
          </sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'cokeEmissionFactor'" class="pw-label control-label col-sm-4">
            Coke Emission Factor
          </label>
          <sim-var-input [inputId]="'cokeEmissionFactor'" [simVar]="unitOperation.cokeEmissionFactor"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'ghgEmissions'" class="pw-label control-label col-sm-4"> GHG Emissions </label>
          <sim-var-input [inputId]="'ghgEmissions'" [simVar]="unitOperation.ghgEmissions" [readOnly]="true">
          </sim-var-input>
        </div>
      </div>
    </div>
  </div>
  <div role="tabpanel" class="tab-pane fade in" id="utilities">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <h4 class="subtitle">Amine</h4>
        <div class="form-group">
          <label [attr.for]="'h2sProductionRate'" class="pw-label control-label col-sm-4"> H2S Production Rate </label>
          <sim-var-input [inputId]="'h2sProductionRate'" [simVar]="unitOperation.h2sProductionRate"></sim-var-input>
        </div>

        <div class="form-group">
          <label [attr.for]="'h2sProduction'" class="pw-label control-label col-sm-4"> H2S Production </label>
          <sim-var-input [inputId]="'h2sProduction'" [readOnly]="true" [simVar]="unitOperation.h2sProduction">
          </sim-var-input>
        </div>
        <sob-steam-use-utility [unitOperation]="unitOperation"></sob-steam-use-utility>
        <sob-gas-contributor [unitOperation]="unitOperation"></sob-gas-contributor>
      </div>
    </div>
  </div>
  <div role="tabpanel" class="tab-pane fade in" id="economics">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <h4 class="subtitle">OPEX</h4>

        <div class="form-group">
          <label [attr.for]="'opexVar'" class="pw-label control-label col-sm-4"> Variable </label>
          <sim-var-input [inputId]="'opexVar'" [simVar]="unitOperation.opexVar"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'opexGasDiesel'" class="pw-label control-label col-sm-4"> Gas Diesel </label>
          <sim-var-input [inputId]="'opexGasDiesel'" [simVar]="unitOperation.opexGasDiesel"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'opexPower'" class="pw-label control-label col-sm-4"> Power </label>
          <sim-var-input [inputId]="'opexPower'" [simVar]="unitOperation.opexPower"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'opexCarbonDioxide'" class="pw-label control-label col-sm-4"> CO2 </label>
          <sim-var-input [inputId]="'opexCarbonDioxide'" [simVar]="unitOperation.opexCarbonDioxide" [readOnly]="true">
          </sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'opexFixed'" class="pw-label control-label col-sm-4"> Fixed </label>
          <sim-var-input [inputId]="'opexFixed'" [simVar]="unitOperation.opexFixed"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'opexTotal'" class="pw-label control-label col-sm-4"> Total </label>
          <sim-var-input [inputId]="'opexTotal'" [simVar]="unitOperation.opexTotal" [readOnly]="true"></sim-var-input>
        </div>

        <hr />
        <h4 class="subtitle">CAPEX</h4>

        <div class="form-group">
          <label [attr.for]="'capexAmortized'" class="pw-label control-label col-sm-4"> Amortized CAPEX </label>
          <sim-var-input [inputId]="'capexAmortized'" [simVar]="unitOperation.capexAmortized"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'capexTotal'" class="pw-label control-label col-sm-4"> Total </label>
          <sim-var-input [inputId]="'capexTotal'" [simVar]="unitOperation.capexTotal" [readOnly]="true"></sim-var-input>
        </div>
      </div>
    </div>
  </div>
  <div role="tabpanel" class="tab-pane fade in" id="constraints">
    <p style="text-align: right; position: relative">
      <span style="position: absolute; right: -25px; top: -20px; font-weight: bold">Active</span>
    </p>
    <div class="form-horizontal">
      <div class="form-group">
        <label [attr.for]="'capacity'" class="pw-label control-label col-sm-4"> Maximum Feed Capacity </label>
        <sim-var-input [inputId]="'capacity'" [isConstraint]="true" [simVar]="unitOperation.capacity"> </sim-var-input>
      </div>
      <div class="form-group">
        <label [attr.for]="'minimumFlow'" class="pw-label control-label col-sm-4"> Minimum Flow </label>
        <sim-var-input [inputId]="'minimumFlow'" [isConstraint]="true" [simVar]="unitOperation.minimumFlow">
        </sim-var-input>
      </div>
      <div class="form-group">
        <label [attr.for]="'minimumAPI'" class="pw-label control-label col-sm-4"> Minimum API </label>
        <sim-var-input [inputId]="'minimumAPI'" [isConstraint]="true" [simVar]="unitOperation.minimumAPI">
        </sim-var-input>
      </div>
      <div class="form-group">
        <label [attr.for]="'maximumCCR'" class="pw-label control-label col-sm-4"> Maximum CCR </label>
        <sim-var-input [inputId]="'maximumCCR'" [isConstraint]="true" [simVar]="unitOperation.maximumCCR">
        </sim-var-input>
      </div>
    </div>
  </div>
  <div role="tabpanel" class="tab-pane fade in" id="upgCustomModels">
    <div class="form-group">
      <label for="customModelEnabled" class="control-label col-sm-4"> Enable Custom Models </label>
      <div class="col-sm-4">
        <label class="control-label">
          <input type="checkbox" id="customModelEnabled" [(ngModel)]="unitOperation.useCustomModel" />
        </label>
      </div>

      <div class="clearfix"></div>
    </div>

    <div>
      <sob-open-code-editor-button
        [code]="unitOperation.modelScript"
        [defaultCodeProvider]="defaultCodeProvider"
        [instanceId]="'dcuCodeEditor'"
        [disabled]="!unitOperation.useCustomModel"
        (codeSaved)="saveCustomModel($event)">
      </sob-open-code-editor-button>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="dcuComments">
    <div class="tab-content no-padding no-border">
      <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
    </div>
  </div>
</div>
