import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SimulationVariable } from '../../../_models';
import { SuncorFluidAnalysis } from '../../../_models/_fluid/suncor-fluid-analysis';

@Component({
  selector: 'sob-composition',
  templateUrl: './composition.component.html',
  styleUrls: ['./composition.component.css'],
})
export class CompositionComponent implements OnInit, OnChanges {
  @Input() currentAnalysis: SuncorFluidAnalysis;
  @Output() showFreeGasCompositionClick = new EventEmitter();

  compositionFormGroup: UntypedFormGroup;
  freeGasCompositionTotalVariable?: SimulationVariable;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.compositionFormGroup = this.fb.group({});
    this.initFreeGasCompositionTotalVariable();
    this.addControls();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['currentAnalysis'] && !changes['currentAnalysis']?.isFirstChange()) {
      this.initFreeGasCompositionTotalVariable();
      this.updateControls();
    }
  }

  initFreeGasCompositionTotalVariable() {
    const selectedUnit = this.currentAnalysis.composition[0].unit;
    this.freeGasCompositionTotalVariable =
      this.currentAnalysis.freeGasComposition.getTotalAsSimulationVariable(selectedUnit);
  }

  addControls() {
    this.compositionFormGroup.addControl('compositionUnit', this.fb.control(this.currentAnalysis.composition[0].unit));

    const compositionArray = this.fb.array([]);

    if (this.freeGasCompositionTotalVariable) {
      this.compositionFormGroup.addControl(
        'freeGasCompositionTotal',
        this.fb.control({ value: this.freeGasCompositionTotalVariable.value, disabled: true })
      );
    }

    for (const sv of this.currentAnalysis.composition) {
      compositionArray.push(this.fb.control({ value: sv.value, disabled: true }));
    }

    this.compositionFormGroup.addControl('composition', compositionArray);
    this.compositionFormGroup.controls['compositionUnit'].disable();
  }

  updateControls() {
    if (this.freeGasCompositionTotalVariable) {
      this.compositionFormGroup.get('freeGasCompositionTotal').setValue(this.freeGasCompositionTotalVariable.value);
    }

    this.compositionFormGroup.get('compositionUnit').setValue(this.currentAnalysis.composition[0].unit);
    const compArray = this.compositionFormGroup.get('composition') as UntypedFormArray;
    compArray.controls.forEach((sv, index) => {
      sv.setValue(this.currentAnalysis.composition[index].value);
    });
  }

  showFreeGasCompositionClicked() {
    this.showFreeGasCompositionClick.emit();
  }
}
