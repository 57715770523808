import { Component, Input, OnInit } from '@angular/core';
import { OffshoreSource } from '../../../_models/_unit-operations/offshore-source';
import { CoreService } from '../../../_services/core.service';
import { SuncorFluidAnalysis } from '../../../_models/_fluid/suncor-fluid-analysis';
import { AssayType } from '../../../_config/assay-type.enum';

@Component({
  selector: 'sob-offshore-source',
  templateUrl: './offshore-source.component.html',
  styleUrls: ['./offshore-source.component.css'],
})
export class OffshoreSourceComponent implements OnInit {
  @Input() unitOperation: OffshoreSource;
  currentAnalysis: SuncorFluidAnalysis;

  constructor(private coreService: CoreService) {}

  ngOnInit() {
    this.updateCurrentAnalysis();
  }

  get fluidAnalyses() {
    return this.coreService.currentCase.assayManager.getAssaysByType(AssayType.CRUDE_OIL_BITUMEN);
  }

  updateCurrentAnalysis(id?) {
    if (id) {
      this.unitOperation.fluidAnalysisId = id;
    }
    if (this.unitOperation.fluidAnalysisId) {
      this.currentAnalysis = this.coreService.currentCase.assayManager.findFluidAnalysisById(
        this.unitOperation.fluidAnalysisId
      );
    }
  }
}
