import { Case, SuncorMaterialStream } from '../../_models';
import { ExcelReportUtils } from './excel-report.utils';
import { WaterMaterialStream } from '../../_models/_streams/water-material-stream';
import { FuelGasMaterialStream } from '../../_models/_streams/fuel-gas-material-stream';
import { Splitter } from '../../_models/_unit-operations/splitter';
import { Mixer } from '../../_models/_unit-operations/mixer';
import { isTypeUndefined } from '../../_utils/utils';

declare let unitConverter: any;

export class InletOutletExcelReport {
  static generateDataArray(c: Case): any[] {
    const dataArray = [];

    Object.values(c.unitOperationPool).forEach(unitOperation => {
      if (
        !(unitOperation instanceof Splitter) &&
        !(unitOperation instanceof Mixer) &&
        unitOperation.flowsheetId === ''
      ) {
        const outletStreams = c.getStreamsByInletUnitOperationId(unitOperation.id) as SuncorMaterialStream[];
        const inletStreams = c.getStreamsByOutletUnitOperationId(unitOperation.id) as SuncorMaterialStream[];

        inletStreams.forEach(stream => {
          this.addMainFlowsheetStreamReport(dataArray, stream, `${unitOperation.name} Inlet`);
        });

        outletStreams.forEach(stream => {
          this.addMainFlowsheetStreamReport(dataArray, stream, `${unitOperation.name} Outlet`);
        });
      }
    });

    return dataArray;
  }

  static generateUnitOperationFlowsheetDataArray(
    c: Case,
    flowsheetOwnerId: string,
    isUpgraderOwnerFlowsheet: boolean
  ): any[] {
    const dataArray = [];
    const streamIds = c.getAllSuncorMaterialStreamIds();

    for (const id of streamIds) {
      const stream = c.getSuncorMaterialStream(id);
      if (stream) {
        const inletUnitOperationflowsheetId = stream.inletUnitOperation.flowsheetId;
        if (inletUnitOperationflowsheetId === flowsheetOwnerId) {
          this.addStreamReport(dataArray, stream, isUpgraderOwnerFlowsheet);
        }
      }
    }

    return dataArray;
  }

  static generateWaterUtilityFlowsheetDataArray(c: Case, flowsheetOwnerId: string): any[] {
    const dataArray = [];
    const streamIds = c.getAllWaterMaterialStreamIds();

    for (const id of streamIds) {
      const waterStream = c.getWaterStream(id);
      if (waterStream) {
        const inletUnitOperationflowsheetId = waterStream.inletUnitOperation.flowsheetId;
        if (inletUnitOperationflowsheetId === flowsheetOwnerId) {
          this.addWaterStreamReport(dataArray, waterStream);
        }
      }
    }

    return dataArray;
  }

  static generateFuelGasUtilityFlowsheetDataArray(c: Case, flowsheetOwnerId: string): any[] {
    const dataArray = [];
    const streamIds = c.getAllFuelGasMaterialStreamIds();

    for (const id of streamIds) {
      const fuelGasStream = c.getFuelGasStream(id);
      if (fuelGasStream) {
        const inletUnitOperationflowsheetId = fuelGasStream.inletUnitOperation.flowsheetId;
        if (inletUnitOperationflowsheetId === flowsheetOwnerId) {
          this.addFuelGasStreamReport(dataArray, fuelGasStream);
        }
      }
    }

    return dataArray;
  }

  private static addMainFlowsheetStreamReport(dataArray: any[], stream: SuncorMaterialStream, columnHeader: string) {
    if (typeof dataArray[0] === 'undefined') {
      dataArray.push(['Stream name', '']);
      this.addStreamReportRowHeadersInCommon(dataArray);

      dataArray.push(['']);
      dataArray.push(['Mass Composition (wt%)', '']);
      dataArray.push(['Light Ends', '']);
      dataArray.push(['Light Naphtha', '']);
      dataArray.push(['Heavy Naphtha', '']);
      dataArray.push(['Distillate', '']);
      dataArray.push(['LGO', '']);
      dataArray.push(['HGO', '']);
      dataArray.push(['VGO', '']);
      dataArray.push(['HVGO', '']);
      dataArray.push(['Resid', '']);
      dataArray.push(['Free Gas', '']);
      dataArray.push(['']);

      this.addFreeGasCompositionRowHeaders(dataArray);
      this.addDistillationCompositionRowHeaders(dataArray);
    }

    let rowNum = 0;
    dataArray[rowNum++].push(`${columnHeader} - ${stream.name}`);
    rowNum = this.addStreamReportValuesOfRowsInCommon(dataArray, stream, rowNum++);

    dataArray[rowNum++].push('');
    dataArray[rowNum++].push('');
    dataArray[rowNum++].push('');

    stream.composition.forEach(sc => {
      dataArray[rowNum++].push(
        ExcelReportUtils.roundFormat(sc.convertToAnotherUnit(unitConverter.units.MassFraction.WATER_PERCENT), -2)
      );
    });

    if (!isTypeUndefined(stream.freeGasComposition)) {
      dataArray[rowNum++].push(ExcelReportUtils.roundFormat(stream.freeGasComposition.calculateTotal() || 0, -2));
      this.addFreeGasCompositionRowValues(dataArray, stream, rowNum++);
    } else {
      dataArray[rowNum++].push(0);
      dataArray[rowNum++].push('');
      dataArray[rowNum++].push('');
      dataArray[rowNum++].push('');
      for (let i = 0; i < 18; i++) {
        dataArray[rowNum++].push(0);
      }
    }

    this.addDistillationCompositionRowValues(dataArray, stream, rowNum++);
  }

  private static addStreamReport(dataArray: any[], stream: SuncorMaterialStream, isUpgraderOwnerFlowsheet: boolean) {
    if (typeof dataArray[0] === 'undefined') {
      dataArray.push(['Stream name', '']);
      dataArray.push(['From', '']);
      dataArray.push(['To', '']);
      this.addStreamReportRowHeadersInCommon(dataArray);
      this.addFreeGasCompositionRowHeaders(dataArray);

      if (isUpgraderOwnerFlowsheet) {
        this.addDistillationCompositionRowHeaders(dataArray);
      }
    }

    let rowNum = 0;
    dataArray[rowNum++].push(stream.name);
    dataArray[rowNum++].push(stream.inletUnitOperation.name);
    dataArray[rowNum++].push(stream.outletUnitOperation.name);
    rowNum = this.addStreamReportValuesOfRowsInCommon(dataArray, stream, rowNum++);

    if (!isTypeUndefined(stream.freeGasComposition)) {
      rowNum = this.addFreeGasCompositionRowValues(dataArray, stream, rowNum++);
    } else {
      dataArray[rowNum++].push('');
      dataArray[rowNum++].push('');
      dataArray[rowNum++].push('');
      for (let i = 0; i < 18; i++) {
        dataArray[rowNum++].push(0);
      }
    }

    if (isUpgraderOwnerFlowsheet) {
      this.addDistillationCompositionRowValues(dataArray, stream, rowNum++);
    }
  }

  private static addWaterStreamReport(dataArray: any[], stream: WaterMaterialStream) {
    if (typeof dataArray[0] === 'undefined') {
      dataArray.push(['Stream name', '']);
      dataArray.push(['From', '']);
      dataArray.push(['To', '']);
      dataArray.push(['']);
      dataArray.push(['Mass Flowrate (kpph)', '']);
      dataArray.push(['Temperature (C)', '']);
      dataArray.push(['Pressure (kPaa)', '']);
      dataArray.push(['']);
    }

    let rowNum = 0;
    dataArray[rowNum++].push(stream.name);
    dataArray[rowNum++].push(stream.inletUnitOperation.name);
    dataArray[rowNum++].push(stream.outletUnitOperation.name);
    dataArray[rowNum++].push('');
    dataArray[rowNum++].push(ExcelReportUtils.roundFormat(stream.massFlowrate.convertToDefaultUnit(), -2));
    dataArray[rowNum++].push(ExcelReportUtils.roundFormat(stream.temperature.convertToDefaultUnit(), -2));
    dataArray[rowNum++].push(ExcelReportUtils.roundFormat(stream.pressure.convertToDefaultUnit(), -2));
    dataArray[rowNum++].push('');
  }

  private static addFuelGasStreamReport(dataArray: any[], stream: FuelGasMaterialStream) {
    if (typeof dataArray[0] === 'undefined') {
      dataArray.push(['Stream name', '']);
      dataArray.push(['From', '']);
      dataArray.push(['To', '']);
      dataArray.push(['']);
      dataArray.push(['Volumetric Flowrate (MMSCFD)', '']);
      dataArray.push(['Heat Flow (GJ/h)', '']);
      dataArray.push(['Lower Heating Value (BTU/scf)', '']);
      dataArray.push(['']);
      dataArray.push(['Composition (mol%)']);

      const compositionNames = [
        'CO',
        'CO2',
        'H2',
        'N2',
        'H2S',
        'SO2',
        'Methane',
        'Ethane',
        'Propane',
        'IC4',
        'NC4',
        'IC5',
        'NC5',
        'C6+',
      ];

      for (const idx in compositionNames) {
        dataArray.push([compositionNames[idx], '']);
      }
    }

    let rowNum = 0;
    dataArray[rowNum++].push(stream.name);
    dataArray[rowNum++].push(stream.inletUnitOperation.name);
    dataArray[rowNum++].push(stream.outletUnitOperation.name);
    dataArray[rowNum++].push('');
    dataArray[rowNum++].push(ExcelReportUtils.roundFormat(stream.flowrate.convertToDefaultUnit(), -2));
    dataArray[rowNum++].push(
      ExcelReportUtils.roundFormat(stream.heatFlow.convertToAnotherUnit(unitConverter.units.Energyrate.GJ_H), -2)
    );
    dataArray[rowNum++].push(
      ExcelReportUtils.roundFormat(stream.lhv.convertToAnotherUnit(unitConverter.units.EnergyPerVolume.BTU_SCF), -2)
    );
    dataArray[rowNum++].push('');
    dataArray[rowNum++].push('');

    for (const idx in stream.composition) {
      dataArray[rowNum++].push(ExcelReportUtils.roundFormat(stream.composition[idx].convertToDefaultUnit(), -4));
    }
  }

  private static addStreamReportRowHeadersInCommon(dataArray: any[]) {
    dataArray.push(['']);
    dataArray.push(['Mass Flowrate (tpd)', '']);
    dataArray.push(['Volumetric Flowrate (kbpcd)', '']);
    dataArray.push(['GHG Emissions (MMtpa)', '']);
    dataArray.push(['GHG Intensity (kg/bbl)', '']);
    dataArray.push(['Price (CAD $/bbl)', '']);
    dataArray.push(['']);

    dataArray.push(['']);
    dataArray.push(['Bulk Properties', '']);
    dataArray.push(['Mass density (API)', '']);
    dataArray.push(['CCR (wt%)', '']);
    dataArray.push(['Sulfur (wt%)', '']);
    dataArray.push(['Nitrogen (wt%)', '']);
    dataArray.push(['']);
  }

  private static addStreamReportValuesOfRowsInCommon(dataArray: any[], stream: SuncorMaterialStream, rowNum: number) {
    dataArray[rowNum++].push('');
    dataArray[rowNum++].push(
      ExcelReportUtils.roundFormat(
        stream.massFlowrate.convertToAnotherUnit(unitConverter.units.Massflowrate.TONNES_D),
        -2
      )
    );
    dataArray[rowNum++].push(ExcelReportUtils.roundFormat(stream.volumetricFlowrate.convertToDefaultUnit(), -2));
    dataArray[rowNum++].push(ExcelReportUtils.roundFormat(stream.ghgEmissions.convertToDefaultUnit(), -2));
    dataArray[rowNum++].push(ExcelReportUtils.roundFormat(stream.ghgIntensity.convertToDefaultUnit(), -2));
    dataArray[rowNum++].push(ExcelReportUtils.roundFormat(stream.price.convertToDefaultUnit(), -2));
    dataArray[rowNum++].push('');
    dataArray[rowNum++].push('');

    dataArray[rowNum++].push('');
    dataArray[rowNum++].push(ExcelReportUtils.roundFormat(stream.massDensity.convertToDefaultUnit(), -2));
    dataArray[rowNum++].push(ExcelReportUtils.roundFormat(stream.ccr.convertToDefaultUnit(), -4));
    dataArray[rowNum++].push(ExcelReportUtils.roundFormat(stream.sulfur.convertToDefaultUnit(), -4));
    dataArray[rowNum++].push(ExcelReportUtils.roundFormat(stream.nitrogen.convertToDefaultUnit(), -4));

    return rowNum++;
  }

  private static addDistillationCompositionRowHeaders(dataArray: any[]) {
    dataArray.push(['']);
    dataArray.push(['Distillation Composition (vol%)', '']);
    dataArray.push(['Naphtha', '']);
    dataArray.push(['Kerosene', '']);
    dataArray.push(['Light Gas Oil', '']);
    dataArray.push(['Heavy Gas Oil', '']);
    dataArray.push(['Bottoms', '']);
    dataArray.push(['']);
  }

  private static addDistillationCompositionRowValues(dataArray: any[], stream: SuncorMaterialStream, rowNum: number) {
    dataArray[rowNum++].push('');
    dataArray[rowNum++].push('');
    dataArray[rowNum++].push('');

    stream.distillationComposition.forEach(sc => {
      dataArray[rowNum++].push(
        ExcelReportUtils.roundFormat(sc.convertToAnotherUnit(unitConverter.units.VolumeFraction.VOLUME_PERCENT), -2)
      );
    });

    return rowNum++;
  }

  private static addFreeGasCompositionRowHeaders(dataArray: any[]) {
    dataArray.push(['']);
    dataArray.push(['Free Gas Composition (wt%)', '']);
    dataArray.push(['CO', '']);
    dataArray.push(['CO2', '']);
    dataArray.push(['H2', '']);
    dataArray.push(['N2', '']);
    dataArray.push(['H2S', '']);
    dataArray.push(['SO2', '']);
    dataArray.push(['Methane', '']);
    dataArray.push(['Ethane', '']);
    dataArray.push(['Ethylene', '']);
    dataArray.push(['Propane', '']);
    dataArray.push(['Propylene', '']);
    dataArray.push(['IC4', '']);
    dataArray.push(['NC4', '']);
    dataArray.push(['Butylenes', '']);
    dataArray.push(['IC5', '']);
    dataArray.push(['NC5', '']);
    dataArray.push(['Cyclopentane', '']);
    dataArray.push(['C6+', '']);
    dataArray.push(['']);
  }

  private static addFreeGasCompositionRowValues(dataArray: any[], stream: SuncorMaterialStream, rowNum: number) {
    dataArray[rowNum++].push('');
    dataArray[rowNum++].push('');
    dataArray[rowNum++].push('');

    dataArray[rowNum++].push(ExcelReportUtils.roundFormat(stream.freeGasComposition.co, -2));
    dataArray[rowNum++].push(ExcelReportUtils.roundFormat(stream.freeGasComposition.co2, -2));
    dataArray[rowNum++].push(ExcelReportUtils.roundFormat(stream.freeGasComposition.h2, -2));
    dataArray[rowNum++].push(ExcelReportUtils.roundFormat(stream.freeGasComposition.n2, -2));
    dataArray[rowNum++].push(ExcelReportUtils.roundFormat(stream.freeGasComposition.h2s, -2));
    dataArray[rowNum++].push(ExcelReportUtils.roundFormat(stream.freeGasComposition.so2, -2));
    dataArray[rowNum++].push(ExcelReportUtils.roundFormat(stream.freeGasComposition.methane, -2));
    dataArray[rowNum++].push(ExcelReportUtils.roundFormat(stream.freeGasComposition.ethane, -2));
    dataArray[rowNum++].push(ExcelReportUtils.roundFormat(stream.freeGasComposition.ethylene, -2));
    dataArray[rowNum++].push(ExcelReportUtils.roundFormat(stream.freeGasComposition.propane, -2));
    dataArray[rowNum++].push(ExcelReportUtils.roundFormat(stream.freeGasComposition.propylene, -2));
    dataArray[rowNum++].push(ExcelReportUtils.roundFormat(stream.freeGasComposition.ic4, -2));
    dataArray[rowNum++].push(ExcelReportUtils.roundFormat(stream.freeGasComposition.nc4, -2));
    dataArray[rowNum++].push(ExcelReportUtils.roundFormat(stream.freeGasComposition.butylenes, -2));
    dataArray[rowNum++].push(ExcelReportUtils.roundFormat(stream.freeGasComposition.ic5, -2));
    dataArray[rowNum++].push(ExcelReportUtils.roundFormat(stream.freeGasComposition.nc5, -2));
    dataArray[rowNum++].push(ExcelReportUtils.roundFormat(stream.freeGasComposition.cyclopentane, -2));
    dataArray[rowNum++].push(ExcelReportUtils.roundFormat(stream.freeGasComposition.c6, -2));

    return rowNum++;
  }
}
