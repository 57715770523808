export enum SimulationVariableName {
  ANGLE = 'Angle',
  AMINE_RATE_CAPACITY = 'Amine Rate Capacity',
  CAPACITY = 'Capacity',
  COKE_EMISSION_FACTOR = 'Coke Emission Factor',
  DUTY = 'Duty',
  DEA_USE_RATE = 'DEA Use Rate',
  EFFICIENCY = 'Efficiency',
  ENERGY_DEMAND = 'Energy Demand',
  ENERGY_FLOW = 'Energy Flow',
  FLOWRATE = 'Flowrate',
  FUEL_GAS_EMISSION_FACTOR = 'Fuel Gas Emission Factor',
  FUEL_GAS_FLOWRATE = 'Fuel Gas Flowrate',
  FUEL_GAS_HEATING_VALUE = 'Fuel Gas Heating Value',
  GAS_FEED_CAPACITY = 'Gas Feed Capacity',
  GAS_LIQUID_CARRY_OVER = 'Gas Liquid Carry Over',
  GEOMETRY_FACTOR = 'Geometry Factor',
  GHG_EMISSIONS = 'GHG Emissions',
  GHG_INTENSITY = 'GHG Intensity',
  H2S_PRODUCTION = 'H2S Production',
  H2S_PRODUCTION_RATE = 'H2S Production Rate',
  H2S_REMOVAL = 'H2S Removal',
  HEAT_RATE = 'Heat Rate',
  HGO_YIELD = 'HGO Yield',
  PROCESS_GHG_INTENSITY = 'Process GHG Intensity',
  HYDROGEN_IMBALANCE = 'Hydrogen Excess',
  HYDROGEN_USE = 'Hydrogen Use',
  HYDROGEN_USE_RATE = 'Hydrogen Use Rate',
  HYDROGEN_PRICE = 'Hydrogen Price',
  HYDROGEN_PRODUCTION = 'Hydrogen Production',
  INSIDE_DIAMETER = 'Inside Diameter',
  ISENTROPIC_EFFICIENCY = 'Isentropic Efficiency',
  LENGTH = 'Length',
  LGO_YIELD = 'LGO Yield',
  LIQUID_GAS_CARRY_OVER = 'Liquid Gas Carry Over',
  LMTD = 'LMTD',
  MASS_FLOWRATE = 'Mass Flowrate',
  MAXIMUM_CCR = 'Maximum CCR',
  MAXIMUM_DEMAND = 'Maximum Demand',
  MAXIMUM_FEED_CAPACITY = 'Maximum Feed Capacity',
  MAXIMUM_HYDROGEN_PRODUCTION = 'Maximum Hydrogen Production',
  MAXIMUM_NITROGEN = 'Maximum Nitrogen',
  MAXIMUM_SULFUR = 'Maximum Sulfur',
  MAXIMUM_SULFUR_CONTENT = 'Maximum Sulfur Content',
  MINIMUM_API = 'Minimum API',
  MAXIMUM_MASS_DENSITY = 'Maximum Mass Density',
  MAXIMUM_MASS_FLOWRATE = 'Maximum Mass Flowrate',
  MINIMUM_DEMAND = 'Minimum Demand',
  MINIMUM_MASS_FLOWRATE = 'Minimum Mass Flowrate',
  MINIMUM_FLOW = 'Minimum Flow',
  MAXIMUM_FLOW = 'Maximum Flow',
  MINIMUM_DUTY = 'Minimum Duty',
  MAXIMUM_DUTY = 'Maximum Duty',
  MINIMUM_FEED_GAS_FLOW = 'Minimum Feed Gas Flow',
  OTSG_ENERGY_DEMAND = 'OTSG Energy Demand',
  NAPHTHA_FEED_CAPACITY = 'Naphtha Feed Capacity',
  NAPHTHA_YIELD = 'Naphtha Yield',
  OIL_YIELD = 'Gas Oil Yield ',
  OUTLET_PRESSURE = 'Outlet Pressure',
  OUTLET_TEMPERATURE = 'Outlet Temperature',
  OUTLET_VAPOR_FRACTION = 'Outlet Vapor Fraction',
  OUTSIDE_DIAMETER = 'Outside Diameter',
  PERCENT_COKE_TO_COMBUSTION = 'Percent Coke To Combustion',
  POLYTROPIC_EFFICIENCY = 'Polytropic Efficiency',
  POWER = 'Power',
  PRESSURE = 'Pressure',
  PRESSURE_DROP = 'Pressure Drop',
  PRESSURE_GAIN = 'Pressure Gain',
  PRESSURE_RATIO = 'Pressure Ratio',
  PRESSURE_DROP_SHELL = 'Pressure Drop Shell',
  PRESSURE_DROP_TUBE = 'Pressure Drop Tube',
  QUALITY = 'Quality',
  RELATIVE_ROUGHNESS = 'Relative Roughness',
  STEP_LENGTH = 'Step Length',
  SULFUR_CAPACITY = 'Sulfur Capacity',
  SULFUR_FLOWRATE = 'Sulfur Flowrate',
  SUNCOR_PROFIT_PERCENTAGE = 'Suncor Profit Percentage',
  TEMPERATURE = 'Temperature',
  TEMPERATURE_DROP = 'Temperature Drop',
  TEMPERATURE_GAIN = 'Temperature Gain',
  TOTAL_ENERGY_DEMAND = 'Total Energy Demand',
  TOTAL_INLET_VOLUME = 'Total Inlet Volume',
  TOTAL_YIELD = 'Total Yield',
  TURBINE_ENERGY_DEMAND = 'Turbine Energy Demand',
  UA = 'UA',
  UA_RESULT = 'UA Result',
  VAPOR_FRACTION = 'Vapor Fraction',
}
