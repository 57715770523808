import { Component, Input } from '@angular/core';
import { SuncorFluidAnalysis } from '../../../_models/_fluid/suncor-fluid-analysis';

@Component({
  selector: 'sob-reactive-suncor-fluid-analysis-properties',
  templateUrl: './reactive-suncor-fluid-analysis-properties.component.html',
  styleUrls: ['./reactive-suncor-fluid-analysis-properties.component.css'],
})
export class ReactiveSuncorFluidAnalysisPropertiesComponent {
  @Input() currentAnalysis?: SuncorFluidAnalysis;

  showFreeGasCompositionTab() {
    $('#gasCompositionUoTab').tab('show');
  }

  showCompositionTab() {
    $('#compositionUoTab').tab('show');
  }
}
