import { ConstraintsReport } from '../../_models/_reports/_optimization-settings-table/constraints-report';
import { Case } from '../../_models';
import { SuncorUnitOperation } from '../../_models/_unit-operations/suncor-unit-operation';
import { ExcelReportUtils } from './excel-report.utils';

export class MassBalanceExcelReport {
  static generateDataArray(c: Case, flowsheetOwnerId?: string): any[] {
    const dataArray = [
      ['All Values Are Reported in kg/s'],
      [
        'Block name',
        'Stream Totals',
        'CO',
        'CO2',
        'H2',
        'N2',
        'H2S',
        'SO2',
        'Methane',
        'Ethane',
        'Ethylene',
        'Propane',
        'Propylene',
        'IC4',
        'NC4',
        'Butylenes',
        'IC5',
        'NC5',
        'Cyclopentane',
        'C6+',
        'Light ends',
        'Light naphtha',
        'Heavy naphtha',
        'Distillate',
        'LGO',
        'HGO',
        'VGO',
        'HVGO',
        'Resid',
      ],
      [''],
    ];

    const uos = c.filterUnitOperations((uo: SuncorUnitOperation) => {
      if (flowsheetOwnerId) {
        return uo instanceof SuncorUnitOperation && uo.flowsheetId === flowsheetOwnerId
          ? uo.outletCutMassFlows.length > 0 || uo.inletCutMassFlows.length > 0
          : false;
      }
      return uo instanceof SuncorUnitOperation && uo.flowsheetId === ''
        ? uo.outletCutMassFlows.length > 0 || uo.inletCutMassFlows.length > 0
        : false;
    });

    uos.forEach((uo: SuncorUnitOperation) => {
      const inlets = [uo.name, 'Total inlet'];
      const outlets = ['', 'Total outlet'];

      this.addFreeGasMassFlows(uo.inletFreeGasMassFlows, inlets);

      uo.inletCutMassFlows.forEach(value => {
        inlets.push(ExcelReportUtils.roundFormat(value, -2));
      });

      this.addFreeGasMassFlows(uo.outletFreeGasMassFlows, outlets);

      uo.outletCutMassFlows.forEach(value => {
        outlets.push(ExcelReportUtils.roundFormat(value, -2));
      });

      dataArray.push(inlets);
      dataArray.push(outlets);
      dataArray.push(['']);
    });
    return dataArray;
  }

  private static addFreeGasMassFlows(freeGasMasFlowsArray: number[], dataArray: string[]) {
    if (freeGasMasFlowsArray.length) {
      freeGasMasFlowsArray.forEach(value => {
        dataArray.push(ExcelReportUtils.roundFormat(value, -2));
      });
    } else {
      for (let i = 0; i < 18; i++) {
        dataArray.push(0 as any);
      }
    }
  }
}
