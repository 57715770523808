export const unitOperationsConfig = {
  diluentSource: {
    displayName: 'Diluent',
    key: 'diluent-source',
    diagramIcon: '/assets/img/blocks/icons/diluentSource.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/diluentSource.svg',
  },
  extraction: {
    displayName: 'Extraction',
    key: 'extraction',
    diagramIcon: '/assets/img/blocks/icons/extraction.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/extraction.svg',
  },
  flexibleExtraction: {
    displayName: 'Extraction',
    key: 'flexible-extraction',
    diagramIcon: '/assets/img/blocks/icons/extraction.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/extraction.svg',
  },
  mine: {
    displayName: 'Mine',
    key: 'mine-source',
    diagramIcon: '/assets/img/blocks/icons/mine.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/mine.svg',
  },
  mixer: {
    displayName: 'Mixer',
    key: 'mixer',
    diagramIcon: '/assets/img/blocks/icons/mixer.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/mixer.svg',
  },
  offshore: {
    displayName: 'Offshore',
    key: 'offshore-source',
    diagramIcon: '/assets/img/blocks/icons/offshore.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/offshore.svg',
  },
  pipe: {
    displayName: 'Pipe',
    key: 'pipe',
    diagramIcon: '/assets/img/blocks/icons/pipe.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/pipe.svg',
  },
  refinery: {
    displayName: 'Refinery',
    key: 'refinery',
    diagramIcon: '/assets/img/blocks/icons/refinery.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/refinery.svg',
  },
  multipleInletRefinery: {
    displayName: 'Refinery',
    key: 'multipleInletRefinery',
    diagramIcon: '/assets/img/blocks/icons/refinery.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/refinery.svg',
  },
  suncorRenewableBlock: {
    displayName: 'Renewable',
    key: 'suncor-renewable-block',
    diagramIcon: '/assets/img/blocks/icons/suncorRenewableBlock.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/suncorRenewableBlock.svg',
  },
  sagd: {
    displayName: 'SAGD',
    key: 'sagd-source',
    diagramIcon: '/assets/img/blocks/icons/sagd.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/sagd.svg',
  },
  splitter: {
    displayName: 'Splitter',
    key: 'splitter',
    diagramIcon: '/assets/img/blocks/icons/splitter.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/splitter.svg',
  },
  thirdPartyRefinery: {
    displayName: 'Third Party Refinery',
    key: 'third-party-refinery',
    diagramIcon: '/assets/img/blocks/icons/thirdPartyRefinery.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/thirdPartyRefinery.svg',
  },
  thirdPartySource: {
    displayName: 'Third Party Source',
    key: 'third-party-source',
    diagramIcon: '/assets/img/blocks/icons/thirdPartySource.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/thirdPartySource.svg',
  },
  upgrader: {
    displayName: 'Upgrader',
    key: 'upgrader',
    diagramIcon: '/assets/img/blocks/icons/upgrader.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/upgrader.svg',
  },
  multipleInletUpgrader: {
    displayName: 'Upgrader',
    key: 'multipleInletUpgrader',
    diagramIcon: '/assets/img/blocks/icons/upgrader.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/upgrader.svg',
  },
  flexibleUpgrader: {
    displayName: 'Upgrader',
    key: 'flexibleUpgrader',
    diagramIcon: '/assets/img/blocks/icons/upgrader.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/upgrader.svg',
  },
  level2Upgrader: {
    displayName: 'Upgrader',
    key: 'level2-upgrader',
    diagramIcon: '/assets/img/blocks/icons/upgrader.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/upgrader.svg',
  },
  // region Upgrader subflowsheet
  upgraderInlet: {
    displayName: 'Upgrader Inlet',
    key: 'upgrader-inlet',
    diagramIcon: '/assets/img/blocks/icons/upgraderInlet.svg',
    paletteIcon: '/assets/img/blocks/icons/upgraderInlet.svg',
  },
  waterUtilityUnitOperation: {
    displayName: 'Steam and Power',
    key: 'water-utility-unit-operation',
    diagramIcon: '/assets/img/blocks/icons/water-utility-unit-operation.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/water-utility-unit-operation.svg',
  },
  bitumenConversion: {
    displayName: 'Bitumen Conversion',
    key: 'bitumen-conversion',
    diagramIcon: '/assets/img/blocks/icons/bitumenConversion.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/bitumenConversion.svg',
  },
  dru: {
    displayName: 'DRU',
    key: 'dru',
    diagramIcon: '/assets/img/blocks/icons/dru.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/dru.svg',
  },
  hydrogenPlant: {
    displayName: 'Hydrogen Plant',
    key: 'hydrogen-plant',
    diagramIcon: '/assets/img/blocks/icons/hydrogenPlant.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/hydrogenPlant.svg',
  },
  sulfurPlant: {
    displayName: 'Sulfur Plant',
    key: 'sulfur-plant',
    diagramIcon: '/assets/img/blocks/icons/sulfurPlant.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/sulfurPlant.svg',
  },
  vac: {
    displayName: 'VAC',
    key: 'vac',
    diagramIcon: '/assets/img/blocks/icons/vac.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/vac.svg',
  },
  hydrotreater: {
    displayName: 'Hydrotreater',
    key: 'hydrotreater',
    diagramIcon: '/assets/img/blocks/icons/hydrotreater.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/hydrotreater.svg',
  },
  hgoHydrotreater: {
    displayName: 'HGO Hydrotreater',
    key: 'hgo-hydrotreater',
    diagramIcon: '/assets/img/blocks/icons/hydrotreater.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/hydrotreater.svg',
  },
  lgoHydrotreater: {
    displayName: 'LGO Hydrotreater',
    key: 'lgo-hydrotreater',
    diagramIcon: '/assets/img/blocks/icons/hydrotreater.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/hydrotreater.svg',
  },
  separatedHydrotreater: {
    displayName: 'Hydrotreater',
    key: 'separated-hydrotreater',
    diagramIcon: '/assets/img/blocks/icons/hydrotreater.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/hydrotreater.svg',
  },
  dcu: {
    displayName: 'DCU',
    key: 'dcu',
    diagramIcon: '/assets/img/blocks/icons/dcu.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/dcu.svg',
  },
  fluidCoker: {
    displayName: 'Fluid Coker',
    key: 'fluid-coker',
    diagramIcon: '/assets/img/blocks/icons/dcu.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/dcu.svg',
  },
  genericSink: {
    displayName: 'Generic Sink',
    key: 'generic-sink',
    diagramIcon: '/assets/img/blocks/icons/genericSink.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/genericSink.svg',
  },
  commodityTank: {
    displayName: 'Commodity Tank',
    key: 'commodity-tank',
    diagramIcon: '/assets/img/blocks/icons/commodityTank.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/commodityTank.svg',
  },
  productTank: {
    displayName: 'Product Tank',
    key: 'product-tank',
    diagramIcon: '/assets/img/blocks/icons/productTank.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/productTank.svg',
  },
  hydrocracker: {
    displayName: 'Hydrocracker',
    key: 'hydrocracker',
    diagramIcon: '/assets/img/blocks/icons/dcu.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/dcu.svg',
  },
  inletTransferUnit: {
    displayName: 'Inlet Transfer Unit',
    key: 'inlet-transfer-unit',
    diagramIcon: '/assets/img/blocks/icons/inletTransferUnit.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/inletTransferUnit.svg',
  },
  outletTransferUnit: {
    displayName: 'Outlet Transfer Unit',
    key: 'outlet-transfer-unit',
    diagramIcon: '/assets/img/blocks/icons/outletTransferUnit.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/outletTransferUnit.svg',
  },
  gasExport: {
    displayName: 'Gas Export',
    key: 'gas-export',
    diagramIcon: '/assets/img/blocks/icons/genericSink.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/genericSink.svg',
  },
  lightEndsRecoveryUnit: {
    displayName: 'Light Ends Recovery Unit',
    key: 'light-ends-recovery-unit',
    diagramIcon: '/assets/img/blocks/icons/hydrotreater.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/hydrotreater.svg',
  },
  flexiformer: {
    displayName: 'Flexiformer',
    key: 'flexiformer',
    diagramIcon: '/assets/img/blocks/icons/hydrotreater.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/hydrotreater.svg',
  },
  // endregion

  // region water-utility-unit-operation
  waterSource: {
    displayName: 'Source',
    key: 'water-source',
    diagramIcon: '/assets/img/blocks/icons/diluentSource.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/diluentSource.svg',
  },
  waterSourceImport: {
    displayName: 'Upgrader Steam Make',
    key: 'water-source-import',
    diagramIcon: '/assets/img/blocks/icons/SteamMake.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/SteamMake.svg',
  },
  waterSinkImport: {
    displayName: 'Upgrader Steam Use',
    key: 'water-sink-import',
    diagramIcon: '/assets/img/blocks/icons/SteamUse.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/SteamUse.svg',
  },
  waterHeatExchanger: {
    displayName: 'Heat Exchanger',
    key: 'water-heat-exchanger',
    diagramIcon: '/assets/img/blocks/icons/heatExchanger.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/heatExchanger.svg',
  },
  waterTurbine: {
    displayName: 'Turbine',
    key: 'water-turbine',
    diagramIcon: '/assets/img/blocks/icons/GasTurbine.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/GasTurbine.svg',
  },
  waterHeater: {
    displayName: 'Heater',
    key: 'water-heater',
    diagramIcon: '/assets/img/blocks/icons/heater.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/heater.svg',
  },
  waterCooler: {
    displayName: 'Cooler',
    key: 'water-cooler',
    diagramIcon: '/assets/img/blocks/icons/cooler.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/cooler.svg',
  },
  waterTwoPhaseSeparator: {
    displayName: 'Two Phase Separator',
    key: 'water-two-phase-separator',
    diagramIcon: '/assets/img/blocks/icons/two-phase-separator.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/two-phase-separator.svg',
  },
  waterPump: {
    displayName: 'Pump',
    key: 'water-pump',
    diagramIcon: '/assets/img/blocks/icons/pump.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/pump.svg',
  },
  waterValve: {
    displayName: 'Valve',
    key: 'water-valve',
    diagramIcon: '/assets/img/blocks/icons/valve.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/valve.svg',
  },
  waterSink: {
    displayName: 'Sink',
    key: 'water-sink',
    diagramIcon: '/assets/img/blocks/icons/genericSink.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/genericSink.svg',
  },
  waterMixer: {
    displayName: 'Mixer',
    key: 'water-mixer',
    diagramIcon: '/assets/img/blocks/icons/mixer.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/mixer.svg',
  },
  waterSplitter: {
    displayName: 'Splitter',
    key: 'water-splitter',
    diagramIcon: '/assets/img/blocks/icons/splitter.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/splitter.svg',
  },
  waterPipe: {
    displayName: 'Pipe',
    key: 'water-pipe',
    diagramIcon: '/assets/img/blocks/icons/pipe.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/pipe.svg',
  },
  waterBoiler: {
    displayName: 'Boiler',
    key: 'water-boiler',
    diagramIcon: '/assets/img/blocks/icons/Boiler.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/waterBoiler.svg',
  },
  waterHeader: {
    displayName: 'Header',
    key: 'water-header',
    diagramIcon: '/assets/img/blocks/icons/waterHeader.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/waterHeader.svg',
  },
  waterCogeneration: {
    displayName: 'Cogeneration',
    key: 'water-cogeneration',
    diagramIcon: '/assets/img/blocks/icons/waterCogeneration.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/waterCogeneration.svg',
  },
  fuelGasUtilityUnitOperation: {
    displayName: 'Fuel Gas',
    key: 'fuel-gas-utility-unit-operation',
    diagramIcon: '/assets/img/blocks/icons/fuelGasUtilityUnitOperation.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/fuelGasUtilityUnitOperation.svg',
  },
  fuelGasFlare: {
    displayName: 'Flare',
    key: 'fuel-gas-flare',
    diagramIcon: '/assets/img/blocks/icons/fuelGasFlare.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/thirdPartySource.svg',
  },
  fuelGasSink: {
    displayName: 'Sink',
    key: 'fuel-gas-sink',
    diagramIcon: '/assets/img/blocks/icons/genericSink.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/genericSink.svg',
  },
  fuelGasSinkImport: {
    displayName: 'Gas Use',
    key: 'fuel-gas-sink-import',
    diagramIcon: '/assets/img/blocks/icons/fuelGasSinkImport.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/fuelGasSinkImport.svg',
  },
  fuelGasSourceImport: {
    displayName: 'Gas Make',
    key: 'fuel-gas-source-import',
    diagramIcon: '/assets/img/blocks/icons/fuelGasSourceImport.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/fuelGasSourceImport.svg',
  },
  fuelGasMixer: {
    displayName: 'Mixer',
    key: 'fuel-gas-mixer',
    diagramIcon: '/assets/img/blocks/icons/genericSink.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/genericSink.svg',
  },
  fuelGasSource: {
    displayName: 'Source',
    key: 'fuel-gas-source',
    diagramIcon: '/assets/img/blocks/icons/diluentSource.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/diluentSource.svg',
  },
  fuelGasSplitter: {
    displayName: 'Splitter',
    key: 'fuel-gas-splitter',
    diagramIcon: '/assets/img/blocks/icons/splitter.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/splitter.svg',
  },
  fuelGasAmineContactor: {
    displayName: 'Amine Contactor',
    key: 'fuel-gas-amine-contactor',
    diagramIcon: '/assets/img/blocks/icons/AmineContactor.svg',
    paletteIcon: '/assets/img/blocks/palette_icons/AmineContactor.svg',
  },
  // endregion
  recycleStream: {
    diagramIcon: 'assets/img/blocks/icons/recycle.svg',
  },
};

export function findUnitOperationDisplayNameByCategory(category: string) {
  const result = Object.entries(unitOperationsConfig).find((entry: any) => entry[1].key === category)[1];

  return (result as any).displayName;
}
