<ul class="nav nav-pills" role="tablist">
  <li role="presentation" class="active">
    <a
      class="px-2"
      id="bulkPropertiesTab"
      href="#bulkProperties"
      aria-controls="bulkProperties"
      role="tab"
      data-toggle="tab"
      >Bulk Properties</a
    >
  </li>

  <li role="presentation">
    <a class="px-2" href="#cutProperties" aria-controls="cutProperties" role="tab" data-toggle="tab">Cut Properties</a>
  </li>
  <li role="presentation" class="">
    <a
      class="px-2"
      id="compositionUoTab"
      href="#compositionUoTabContent"
      aria-controls="composition"
      role="tab"
      data-toggle="tab"
      >Composition</a
    >
  </li>

  <li role="presentation">
    <a
      class="px-2"
      id="gasCompositionUoTab"
      href="#gasCompositionUoTabContent"
      aria-controls="composition"
      role="tab"
      data-toggle="tab"
      >Gas Composition</a
    >
  </li>
</ul>

<div class="tab-content mt-4">
  <div role="tabpanel" class="tab-pane fade in active" id="bulkProperties">
    <div class="form-horizontal">
      <div class="form-group">
        <label [attr.for]="'fluidAnalysisMassDensity'" class="pw-label control-label col-sm-4"> Mass Density </label>
        <sim-var-input
          [inputId]="'fluidAnalysisMassDensity'"
          [readOnly]="true"
          [transientValue]="true"
          [simVar]="currentAnalysis.massDensity"></sim-var-input>
      </div>
      <div class="form-group">
        <label [attr.for]="'fluidAnalysisCcr'" class="pw-label control-label col-sm-4"> CCR </label>
        <sim-var-input
          [inputId]="'fluidAnalysisCcr'"
          [readOnly]="true"
          [transientValue]="true"
          [simVar]="currentAnalysis.ccr"></sim-var-input>
      </div>
      <div class="form-group">
        <label [attr.for]="'fluidAnalysisSulfur'" class="pw-label control-label col-sm-4"> Sulfur </label>
        <sim-var-input
          [inputId]="'fluidAnalysisSulfur'"
          [readOnly]="true"
          [transientValue]="true"
          [simVar]="currentAnalysis.sulfur"></sim-var-input>
      </div>
      <div class="form-group">
        <label [attr.for]="'fluidAnalysisNitrogen'" class="pw-label control-label col-sm-4"> Nitrogen </label>
        <sim-var-input
          [inputId]="'fluidAnalysisNitrogen'"
          [readOnly]="true"
          [transientValue]="true"
          [simVar]="currentAnalysis.nitrogen"></sim-var-input>
      </div>
    </div>
  </div>
  <div role="tabpanel" class="tab-pane fade in px-4" id="cutProperties">
    <sob-cut-properties [readonly]="true" [fluidAnalysis]="currentAnalysis"></sob-cut-properties>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="compositionUoTabContent">
    <div class="form-horizontal" sfeMultipleSimVarInput totalOutputId="compositionTotalUO">
      <div class="form-group">
        <label [attr.for]="'compositionUnit'" class="pw-label control-label col-sm-4"> Unit </label>

        <div class="col-sm-4">
          <sfe-unit-select
            [selectId]="'compositionUnit' + componentId"
            [simVar]="currentAnalysis.composition[0]"
            [disabled]="true"
            [elClass]="'form-control'"></sfe-unit-select>
        </div>
      </div>

      <div class="form-group" *ngIf="freeGasCompositionTotalVariable">
        <label class="pw-label control-label col-sm-4">Gas Fraction</label>
        <div class="col-sm-4">
          <sfe-value-input
            [simVar]="freeGasCompositionTotalVariable"
            [disabled]="true"
            [elClass]="'form-control'"
            [inputId]="'uoFreeGasCompositionCompound'"
            [multiple]="true"
            [convertedBy]="'compositionUnit' + componentId">
          </sfe-value-input>
        </div>

        <div class="col-sm-4 px-4 px-md-0 mt-2 mt-md-0">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            title="Gas Fraction"
            (click)="showFreeGasCompositionClicked()">
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>

      <ng-container *ngFor="let idx of currentAnalysis.composition | keys">
        <div class="form-group">
          <label [attr.for]="'composition' + idx" class="pw-label control-label col-sm-4">
            {{ currentAnalysis.composition[idx].name | titleCaseComposition }}
          </label>
          <div class="col-sm-4">
            <sfe-value-input
              [simVar]="currentAnalysis.composition[idx]"
              [elClass]="'form-control'"
              [inputId]="'composition' + idx"
              [multiple]="true"
              [readOnlyValue]="true"
              [disabled]="true"
              [convertedBy]="'compositionUnit' + componentId">
            </sfe-value-input>
          </div>
        </div>
      </ng-container>

      <div class="form-group">
        <label class="pw-label control-label col-sm-4">Total</label>
        <div class="col-sm-4">
          <input type="text" class="form-control" id="compositionTotalUO" readonly />
        </div>
      </div>
    </div>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="gasCompositionUoTabContent">
    <sob-free-gas-composition-form
      [freeGasComposition]="currentAnalysis.freeGasComposition"
      [widerInputs]="true"
      [disabled]="true"
      (returnToOilComposition)="showCompositionTab()"></sob-free-gas-composition-form>
  </div>
</div>
