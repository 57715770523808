import { SimulationVariable } from '../simulation-variable';
import { Case } from '../case';
import { DistributionRatioVariablePerCut } from './distribution-ratio-variable-per-cut';
import { UnitOperationConstraints } from './unit-operation-constraints';
import { ConstrainableObject } from '../_interfaces/constrainable-object';
import { BoilingPointCut } from './boiling-point-cut';
import { KpiProvider } from '../_case-study/kpi-provider';
import { GasContributorUnitOperation } from './gas-contributor-unit-operation';
import { SimulationVariableName } from '../../_config/simulation-variable-name.enum';
import { MultiPeriodParameterProvider } from '../_multi-period/multi-period-parameter-provider';
import { ParameterProvider } from '../_case-study/parameter-provider';
import { unitOperationsConfig } from '../../_config/unit-operations.config';
import { SeparateGasStreamUser } from '../_interfaces/separateGasStreamUser';

declare let unitConverter: any;

export class VAC
  extends GasContributorUnitOperation
  implements ConstrainableObject, MultiPeriodParameterProvider, KpiProvider, ParameterProvider, SeparateGasStreamUser
{
  category = unitOperationsConfig.vac.key;
  distributionRatioVariables: Array<DistributionRatioVariablePerCut> = [];
  boilingPointCuts: Array<BoilingPointCut> = [];
  absoluteGhgEmissions: SimulationVariable;
  solveOption: string;
  yieldBasis: string;
  percentCuts = [0, 0.25, 0.5, 0.75, 1];
  h2sProductionRate: SimulationVariable;
  h2sProduction: SimulationVariable;
  constraints: UnitOperationConstraints;
  hasSeparateGasStream: boolean;

  constructor(unitOperation: any, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  override initValues(unitOperation: any): void {
    super.initValues(unitOperation);
    this.absoluteGhgEmissions = new SimulationVariable(unitOperation.absoluteGhgEmissions, this.ownerCase, this);
    this.h2sProductionRate = new SimulationVariable(unitOperation.h2sProductionRate || {}, this.ownerCase, this);
    this.h2sProduction = new SimulationVariable(unitOperation.h2sProduction || {}, this.ownerCase, this);
    if (unitOperation.distributionRatioVariables instanceof Array) {
      for (let i = 0; i < unitOperation.distributionRatioVariables.length; i++) {
        const dv = unitOperation.distributionRatioVariables[i];
        this.distributionRatioVariables.push(new DistributionRatioVariablePerCut(dv.unitOperationId, dv));
        const bp = unitOperation.boilingPointCuts ? unitOperation.boilingPointCuts[i] : [];
        const bpc = new BoilingPointCut(bp, this.percentCuts.length, this.ownerCase, this);
        bpc.unitOperationId = dv.unitOperationId;
        this.boilingPointCuts.push(bpc);
      }
    }
    this.solveOption = typeof unitOperation.solveOption === 'undefined' ? 'Component split' : unitOperation.solveOption;

    this.yieldBasis = typeof unitOperation.yieldBasis === 'undefined' ? 'Weight basis' : unitOperation.yieldBasis;
    this.hasSeparateGasStream = !!unitOperation.hasSeparateGasStream;

    this.initConstraints();
    this.setQuantities();
  }

  override setDefaultValues(): void {
    super.setDefaultValues();
    if (this.absoluteGhgEmissions.isUndefined()) {
      this.absoluteGhgEmissions.setDefaultValue(0.0);
    }
    if (this.energyFlow.isUndefined()) {
      this.energyFlow.setDefaultValue(0.0, unitConverter.units.Energyrate.GJ_H);
    }
  }

  override setSimulationVariableNames() {
    super.setSimulationVariableNames();
    this.steamMake900.setName('#900 Steam Make');
    this.steamUse900.setName('#900 Steam Use');
    this.steamMake600.setName('#600 Steam Make');
    this.steamUse600.setName('#600 Steam Use');
    this.steamMake150.setName('#150 Steam Make');
    this.steamUse150.setName('#150 Steam Use');
    this.steamMake50.setName('#50 Steam Make');
    this.steamUse50.setName('#50 Steam Use');
    this.h2sProductionRate.setName('H2S Production Rate');
    this.h2sProduction.setName('H2S Production');
    this.opexTotal.setName('Total OPEX');
    this.capexTotal.setName('Total CAPEX');
    this.capacity.setName(SimulationVariableName.CAPACITY);
    this.fuelGasHeatingValue.setName(SimulationVariableName.FUEL_GAS_HEATING_VALUE);
    this.fuelGasEmissionFactor.setName(SimulationVariableName.FUEL_GAS_EMISSION_FACTOR);
    this.energyFlow.setName(SimulationVariableName.ENERGY_FLOW);
    this.flowrate.setName(SimulationVariableName.FUEL_GAS_FLOWRATE);
  }

  override setQuantities() {
    super.setQuantities();
    this.absoluteGhgEmissions.setQuantity('Massflowrate');
    this.h2sProduction.setQuantity('Moleflowrate');
    this.h2sProductionRate.setQuantity('GasStdVolumePerVolume');
    for (const sv of this.boilingPointCuts) {
      sv.setQuantities();
    }
  }

  override dePersist(serializedUnitOperation: any) {
    super.dePersist(serializedUnitOperation);
    this.absoluteGhgEmissions = this.getSimulationVariableSafe(serializedUnitOperation.absoluteGhgEmissions);
    this.h2sProduction = this.getSimulationVariableSafe(serializedUnitOperation.h2sProduction);
    this.h2sProductionRate = this.getSimulationVariableSafe(serializedUnitOperation.h2sProductionRate);
    if (serializedUnitOperation.boilingPointCuts) {
      for (let idx = 0; idx < this.boilingPointCuts.length; idx++) {
        this.boilingPointCuts[idx].dePersist(serializedUnitOperation.boilingPointCuts[idx]);
      }
    }
    this.setQuantities();
    this.setDefaultValues();
    this.initConstraints();
  }

  override overwriteValues(another: any) {
    super.overwriteValues(another);
    const s = another as VAC;
    this.name = another.name;
    for (let i = 0; i < s.distributionRatioVariables.length; i++) {
      this.distributionRatioVariables[i].lightEnds = s.distributionRatioVariables[i].lightEnds;
      this.distributionRatioVariables[i].lightNaphtha = s.distributionRatioVariables[i].lightNaphtha;
      this.distributionRatioVariables[i].heavyNaphtha = s.distributionRatioVariables[i].heavyNaphtha;
      this.distributionRatioVariables[i].distillate = s.distributionRatioVariables[i].distillate;
      this.distributionRatioVariables[i].LGO = s.distributionRatioVariables[i].LGO;
      this.distributionRatioVariables[i].HGO = s.distributionRatioVariables[i].HGO;
      this.distributionRatioVariables[i].VGO = s.distributionRatioVariables[i].VGO;
      this.distributionRatioVariables[i].HVGO = s.distributionRatioVariables[i].HVGO;
      this.distributionRatioVariables[i].resid = s.distributionRatioVariables[i].resid;
      this.boilingPointCuts[i].overwriteValues(another.boilingPointCuts[i]);
    }
  }

  protected override ignoreForOverWrite(propertyName: string): boolean {
    return super.ignoreForOverWrite(propertyName) || this[propertyName] instanceof Array;
  }

  override detectChanges(another: any): boolean {
    let changes = super.detectChanges(another);
    for (let idx = 0; idx < another.boilingPointCuts.length; idx++) {
      changes = changes || this.boilingPointCuts[idx].detectChanges(another.boilingPointCuts[idx]);
    }
    return changes;
  }

  override convertToInternalUnit(): void {
    super.convertToInternalUnit();
    for (let idx = 0; idx < this.boilingPointCuts.length; idx++) {
      this.boilingPointCuts[idx].convertToInternalUnit();
    }
  }

  override addSimVarsToPool(): void {
    super.addSimVarsToPool();
    for (let idx = 0; idx < this.boilingPointCuts.length; idx++) {
      this.boilingPointCuts[idx].addSimVarsToPool();
    }
  }

  // region constraints
  initConstraints() {
    this.constraints = new UnitOperationConstraints();
    this.constraints.addConstraint('capacity', this.capacity);
    this.constraints.addConstraint('minimumFlow', this.minimumFlow);
  }

  constraintViolated() {
    const inletStream = this.ownerCase.filterSuncorMaterialStreams(s => {
      return s.outletUnitOperationId === this.id;
    })[0];

    return inletStream && this.constraints.constraintValueViolated('capacity', inletStream.volumetricFlowrate.value);
  }

  constraintValueDefined() {
    return this.constraints.anyConstraintValueDefined();
  }
  // endregion

  // region distribution variables
  addDistributionVariable(unitOperationId: string) {
    const dv = new DistributionRatioVariablePerCut(unitOperationId, {});
    dv.initEmpty();
    const bpc = new BoilingPointCut('', this.percentCuts.length, this.ownerCase, this);
    bpc.unitOperationId = unitOperationId;
    this.boilingPointCuts.push(bpc);
    this.distributionRatioVariables.push(dv);
    if (this.distributionRatioVariables.length === 1) {
      this.distributionRatioVariables[0].lightEnds = 1;
      this.distributionRatioVariables[0].lightNaphtha = 1;
      this.distributionRatioVariables[0].heavyNaphtha = 1;
      this.distributionRatioVariables[0].distillate = 1;
      this.distributionRatioVariables[0].LGO = 1;
      this.distributionRatioVariables[0].HGO = 1;
      this.distributionRatioVariables[0].VGO = 1;
      this.distributionRatioVariables[0].HVGO = 1;
      this.distributionRatioVariables[0].resid = 1;
    }
  }

  calculateDistributionVariables() {
    const components = [
      'lightEnds',
      'lightNaphtha',
      'heavyNaphtha',
      'distillate',
      'LGO',
      'HGO',
      'VGO',
      'HVGO',
      'resid',
    ];
    for (let j = 0; j < components.length; j++) {
      const component = components[j];
      const sum = this.distributionRatioVariables.reduce((prev, curr) => Number(prev) + Number(curr[component]), 0);
      for (let i = 0; i < this.distributionRatioVariables.length; i++) {
        if (sum !== 1) {
          this.distributionRatioVariables[i][component] /= sum;
        }
        if (sum === 0) {
          this.distributionRatioVariables[i][component] = 1 / this.distributionRatioVariables.length;
        }
      }
    }
  }

  emptyDistributionValues() {
    for (const dv of this.distributionRatioVariables) {
      dv.lightEnds = null;
      dv.lightNaphtha = null;
      dv.heavyNaphtha = null;
      dv.distillate = null;
      dv.LGO = null;
      dv.HGO = null;
      dv.VGO = null;
      dv.HVGO = null;
      dv.resid = null;
    }
  }

  findDistributionRatioVariable(unitOperationId: string) {
    return this.distributionRatioVariables.find(drv => {
      return drv.unitOperationId === unitOperationId;
    });
  }

  removeDistributionRatioVariable(unitOperationId: string) {
    const index = this.distributionRatioVariables.indexOf(this.findDistributionRatioVariable(unitOperationId));
    if (index >= 0) {
      this.boilingPointCuts.splice(index, 1);
      this.distributionRatioVariables.splice(index, 1);
    }
    if (this.distributionRatioVariables.length === 1) {
      this.distributionRatioVariables[0].lightEnds = 1;
      this.distributionRatioVariables[0].lightNaphtha = 1;
      this.distributionRatioVariables[0].heavyNaphtha = 1;
      this.distributionRatioVariables[0].distillate = 1;
      this.distributionRatioVariables[0].LGO = 1;
      this.distributionRatioVariables[0].HGO = 1;
      this.distributionRatioVariables[0].VGO = 1;
      this.distributionRatioVariables[0].HVGO = 1;
      this.distributionRatioVariables[0].resid = 1;
    }
    this.calculateDistributionVariables();
  }

  findDistributionRatioVariableName(i: number) {
    const dv = this.distributionRatioVariables[i];

    if (dv) {
      const uo = this.ownerCase.getUnitOperation(dv.unitOperationId);
      return uo ? uo.name : null;
    }

    return null;
  }

  clearComponent(component: string) {
    for (let i = 0; i < this.distributionRatioVariables.length; i++) {
      this.distributionRatioVariables[i][component] = null;
    }
  }
  // endregion

  // region kpis & parameters
  getAvailableKpis(): SimulationVariable[] {
    if (this.ownerCase.multiPeriodEnabled) {
      return [
        this.ghgEmissions,
        this.ghgIntensity,
        this.h2sProductionRate,
        this.h2sProduction,
        this.opexTotal,
        this.capexTotal,
      ];
    }
    return [
      this.ghgEmissions,
      this.ghgIntensity,
      this.steamMake600,
      this.steamUse600,
      this.steamUse900,
      this.steamMake900,
      this.steamMake150,
      this.steamUse150,
      this.steamMake50,
      this.steamUse50,
      this.h2sProductionRate,
      this.h2sProduction,
      this.opexTotal,
      this.capexTotal,
      this.fuelGasHeatingValue,
      this.fuelGasEmissionFactor,
      this.energyFlow,
      this.flowrate,
    ];
  }

  getAvailableParametricStudyParameters(): SimulationVariable[] {
    return [
      this.h2sProductionRate,
      this.opexVar,
      this.opexGasDiesel,
      this.opexPower,
      this.opexFixed,
      this.capexAmortized,
    ];
  }

  getAvailableParameters() {
    return undefined;
  }

  getAvailableMultiPeriodParameters(): SimulationVariable[] {
    return [this.capacity];
  }
  // endregion

  getAlternativeGhgIntensity(): SimulationVariable {
    return undefined;
  }
}
