<div
  [formGroup]="formGroup"
  (focusout)="map()"
  sobReactiveMultipleVariableInput
  totalOutputId="{{ componentId }}FreeGasCompositionTotal">
  <div class="row">
    <div class="col-sm-6">
      <div class="form-horizontal">
        <div class="form-group">
          <label class="pw-label control-label" [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-5': widerInputs }"
            >Unit</label
          >
          <div [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-7': widerInputs }">
            <sob-reactive-unit-select
              formControlName="unit"
              [quantity]="freeGasComposition.quantity"></sob-reactive-unit-select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-6">
      <div class="form-horizontal">
        <div class="form-group">
          <label
            class="pw-label control-label col-sm-6"
            [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-5': widerInputs }"
            >CO</label
          >
          <div [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-7': widerInputs }">
            <sob-reactive-variable-value-input formControlName="co" [valueMetaData]="freeGasComposition">
            </sob-reactive-variable-value-input>
          </div>
        </div>

        <div class="form-group">
          <label
            class="pw-label control-label col-sm-6"
            [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-5': widerInputs }"
            >CO2</label
          >
          <div [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-7': widerInputs }">
            <sob-reactive-variable-value-input formControlName="co2" [valueMetaData]="freeGasComposition">
            </sob-reactive-variable-value-input>
          </div>
        </div>

        <div class="form-group">
          <label
            class="pw-label control-label col-sm-6"
            [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-5': widerInputs }"
            >H2</label
          >
          <div [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-7': widerInputs }">
            <sob-reactive-variable-value-input formControlName="h2" [valueMetaData]="freeGasComposition">
            </sob-reactive-variable-value-input>
          </div>
        </div>

        <div class="form-group">
          <label
            class="pw-label control-label col-sm-6"
            [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-5': widerInputs }"
            >N2</label
          >
          <div [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-7': widerInputs }">
            <sob-reactive-variable-value-input formControlName="n2" [valueMetaData]="freeGasComposition">
            </sob-reactive-variable-value-input>
          </div>
        </div>

        <div class="form-group">
          <label
            class="pw-label control-label col-sm-6"
            [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-5': widerInputs }"
            >H2S</label
          >
          <div [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-7': widerInputs }">
            <sob-reactive-variable-value-input formControlName="h2s" [valueMetaData]="freeGasComposition">
            </sob-reactive-variable-value-input>
          </div>
        </div>

        <div class="form-group">
          <label
            class="pw-label control-label col-sm-6"
            [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-5': widerInputs }"
            >SO2</label
          >
          <div [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-7': widerInputs }">
            <sob-reactive-variable-value-input formControlName="so2" [valueMetaData]="freeGasComposition">
            </sob-reactive-variable-value-input>
          </div>
        </div>

        <div class="form-group">
          <label
            class="pw-label control-label col-sm-6"
            [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-5': widerInputs }"
            >Methane</label
          >
          <div [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-7': widerInputs }">
            <sob-reactive-variable-value-input formControlName="methane" [valueMetaData]="freeGasComposition">
            </sob-reactive-variable-value-input>
          </div>
        </div>

        <div class="form-group">
          <label
            class="pw-label control-label col-sm-6"
            [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-5': widerInputs }"
            >Ethane</label
          >
          <div [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-7': widerInputs }">
            <sob-reactive-variable-value-input formControlName="ethane" [valueMetaData]="freeGasComposition">
            </sob-reactive-variable-value-input>
          </div>
        </div>

        <div class="form-group">
          <label
            class="pw-label control-label col-sm-6"
            [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-5': widerInputs }"
            >Ethylene</label
          >
          <div [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-7': widerInputs }">
            <sob-reactive-variable-value-input formControlName="ethylene" [valueMetaData]="freeGasComposition">
            </sob-reactive-variable-value-input>
          </div>
        </div>

        <div class="row" *ngIf="formGroup.errors && formGroup.errors['freeGasCompositionTotal']">
          <div class="col-xs-12 text-center">
            <span class="text-danger"
              >Total Composition cannot be more than
              {{ 1 | numberToUnit : { unit: formGroup.value.unit, quantity: freeGasComposition.quantity } }}</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="col-xs-6">
      <div class="form-horizontal">
        <div class="form-group">
          <label
            class="pw-label control-label col-sm-6"
            [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-5': widerInputs }"
            >Propane</label
          >
          <div [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-7': widerInputs }">
            <sob-reactive-variable-value-input formControlName="propane" [valueMetaData]="freeGasComposition">
            </sob-reactive-variable-value-input>
          </div>
        </div>

        <div class="form-group">
          <label
            class="pw-label control-label col-sm-6"
            [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-5': widerInputs }"
            >Propylene</label
          >
          <div [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-7': widerInputs }">
            <sob-reactive-variable-value-input formControlName="propylene" [valueMetaData]="freeGasComposition">
            </sob-reactive-variable-value-input>
          </div>
        </div>

        <div class="form-group">
          <label
            class="pw-label control-label col-sm-6"
            [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-5': widerInputs }"
            >Isobutane</label
          >
          <div [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-7': widerInputs }">
            <sob-reactive-variable-value-input formControlName="ic4" [valueMetaData]="freeGasComposition">
            </sob-reactive-variable-value-input>
          </div>
        </div>

        <div class="form-group">
          <label
            class="pw-label control-label col-sm-6"
            [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-5': widerInputs }"
            >NC4</label
          >
          <div [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-7': widerInputs }">
            <sob-reactive-variable-value-input formControlName="nc4" [valueMetaData]="freeGasComposition">
            </sob-reactive-variable-value-input>
          </div>
        </div>

        <div class="form-group">
          <label
            class="pw-label control-label col-sm-6"
            [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-5': widerInputs }"
            >Butylenes</label
          >
          <div [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-7': widerInputs }">
            <sob-reactive-variable-value-input formControlName="butylenes" [valueMetaData]="freeGasComposition">
            </sob-reactive-variable-value-input>
          </div>
        </div>

        <div class="form-group">
          <label
            class="pw-label control-label col-sm-6"
            [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-5': widerInputs }"
            >IC5</label
          >
          <div [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-7': widerInputs }">
            <sob-reactive-variable-value-input formControlName="ic5" [valueMetaData]="freeGasComposition">
            </sob-reactive-variable-value-input>
          </div>
        </div>

        <div class="form-group">
          <label
            class="pw-label control-label col-sm-6"
            [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-5': widerInputs }"
            >NC5</label
          >
          <div [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-7': widerInputs }">
            <sob-reactive-variable-value-input formControlName="nc5" [valueMetaData]="freeGasComposition">
            </sob-reactive-variable-value-input>
          </div>
        </div>

        <div class="form-group">
          <label
            class="pw-label control-label col-sm-6"
            [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-5': widerInputs }"
            >Cyclopentane</label
          >
          <div [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-7': widerInputs }">
            <sob-reactive-variable-value-input formControlName="cyclopentane" [valueMetaData]="freeGasComposition">
            </sob-reactive-variable-value-input>
          </div>
        </div>

        <div class="form-group">
          <label
            class="pw-label control-label col-sm-6"
            [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-5': widerInputs }"
            >C6+</label
          >
          <div [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-7': widerInputs }">
            <sob-reactive-variable-value-input formControlName="c6" [valueMetaData]="freeGasComposition">
            </sob-reactive-variable-value-input>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-6">
      <div class="form-horizontal">
        <div class="form-group mb-0 mb-md-3">
          <label
            class="pw-label control-label col-sm-6"
            [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-5': widerInputs }"
            >Total</label
          >
          <div [ngClass]="{ 'col-sm-6': !widerInputs, 'col-sm-7': widerInputs }">
            <input type="text" disabled class="form-control" id="{{ componentId }}FreeGasCompositionTotal" />
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-6 px-4 px-md-0 mt-1 mt-md-0">
      <div class="col-sm-4 px-0">
        <button class="btn btn-primary" title="Return to Oil Composition" (click)="returnToOilCompositionClicked()">
          <i class="fa fa-arrow-left"></i>
        </button>
      </div>
    </div>
  </div>
</div>
