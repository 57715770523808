// region number utils
export function hasNumericValue(value: number | undefined) {
  return value !== null && typeof value !== 'undefined' && value.toString() !== '' && !Number.isNaN(+value);
}

export function roundNumber(n: number, fractionDigits: number) {
  return parseFloat(n.toFixed(fractionDigits));
}
// endregion

// region type/value utils
export function isTypeUndefined(value): value is undefined {
  return typeof value === 'undefined';
}

export function isNullOrUndefined(value: any) {
  return typeof value === 'undefined' || value === null;
}

export function isEmptyValue(value: any) {
  return isNullOrUndefined(value) || `${value}`.trim() === '';
}

export function isTypeBoolean(value: any): value is boolean {
  return typeof value === 'boolean';
}

export function isTypeNumber(value: any): value is number {
  return typeof value === 'number';
}

export function isTypeString(value: any): value is string {
  return typeof value === 'string';
}

export function isPrimitiveType(value: any): boolean {
  return isTypeNumber(value) || isNullOrUndefined(value) || isTypeString(value) || isTypeBoolean(value);
}

export function isTypeFunction(value: any): boolean {
  return typeof value === 'function';
}

// taken from unit converter - there are times when this is useful and needs to be used
// outside the unitConverter...
export function commaNumberFormat(nStr): string {
  nStr += '';
  const x = nStr.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? `.${x[1]}` : '';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }

  return x1 + x2;
}

export function generateUuid() {
  return (window as any).uuid() as string;
}
