import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { generateUuid, isTypeUndefined } from '../../../_utils/utils';
import { FreeGasComposition } from '../../../_models/_fluid/free-gas-composition';
import { CustomValidators } from '../../../shared/custom-validators';

@Component({
  selector: 'sob-free-gas-composition-form',
  templateUrl: './free-gas-composition-form.component.html',
  styleUrls: ['./free-gas-composition-form.component.css'],
})
export class FreeGasCompositionFormComponent implements OnInit, OnChanges {
  protected readonly componentId = generateUuid();

  @Input() disabled: boolean;
  @Input() widerInputs = false;
  @Input() freeGasComposition?: FreeGasComposition;

  @Output() returnToOilComposition = new EventEmitter();

  protected formGroup = new FormGroup(
    {
      unit: new FormControl('', Validators.required),

      co: new FormControl(0, [Validators.required]),
      co2: new FormControl(0, [Validators.required]),
      h2: new FormControl(0, [Validators.required]),
      n2: new FormControl(0, [Validators.required]),
      h2s: new FormControl(0, [Validators.required]),
      so2: new FormControl(0, [Validators.required]),
      methane: new FormControl(0, [Validators.required]),
      ethane: new FormControl(0, [Validators.required]),
      ethylene: new FormControl<number>(0, [Validators.required]),
      propane: new FormControl(0, [Validators.required]),
      propylene: new FormControl(0, [Validators.required]),
      ic4: new FormControl(0, [Validators.required]),
      nc4: new FormControl(0, [Validators.required]),
      butylenes: new FormControl(0, [Validators.required]),
      ic5: new FormControl(0, [Validators.required]),
      nc5: new FormControl(0, [Validators.required]),
      cyclopentane: new FormControl(0, [Validators.required]),
      c6: new FormControl(0, [Validators.required]),
    },
    [CustomValidators.freeGasCompositionTotal]
  );

  ngOnInit() {
    if (!this.freeGasComposition) {
      this.freeGasComposition = new FreeGasComposition();
    }

    this.formGroup.patchValue(this.freeGasComposition);

    if (this.disabled) {
      this.formGroup.disable();
      this.formGroup.controls.unit.enable();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const freeGasChanges = changes['freeGasComposition'];
    if (!freeGasChanges || changes['freeGasComposition'].isFirstChange()) {
      return;
    }

    this.formGroup.patchValue(this.freeGasComposition);
    // this.formGroup.controls.unit.change
  }

  protected map() {
    if (this.disabled) {
      return;
    }

    const formGroupKeys = Object.keys(this.formGroup.value);
    const { freeGasComposition } = this;
    for (const key of formGroupKeys) {
      freeGasComposition[key] = isTypeUndefined(this.formGroup.value[key]) ? 0 : this.formGroup.value[key];
    }

    this.formGroup.patchValue(freeGasComposition, { emitEvent: false });
  }

  returnToOilCompositionClicked() {
    this.map();
    this.returnToOilComposition.emit();
  }
}
