import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StreamCompositionType } from '../../../../_config/stream-composition-type.enum';
import { UnitSubset } from '../../../../_config/unitSubset.enum';
import { SimulationVariable, SuncorMaterialStream } from '../../../../_models';
import { FreeGasComposition } from '../../../../_models/_fluid/free-gas-composition';

@Component({
  selector: 'sob-ms-composition',
  templateUrl: './ms-composition.component.html',
})
export class MsCompositionComponent implements OnInit {
  protected readonly StreamCompositionType = StreamCompositionType;
  protected readonly UnitSubset = UnitSubset;

  @Input() stream: SuncorMaterialStream;
  @Output() showFreeGasCompositionClick = new EventEmitter();

  compositionTypeSelected: string = StreamCompositionType.MASS;
  freeGasCompositionTotalVariable?: SimulationVariable;

  ngOnInit() {
    let { freeGasComposition } = this.stream;

    if (!freeGasComposition) {
      freeGasComposition = new FreeGasComposition();
    }

    this.freeGasCompositionTotalVariable = freeGasComposition.getTotalAsSimulationVariable(
      this.stream.composition[0].unit
    );
  }

  compositionTypeChanged(target: EventTarget | null) {
    this.compositionTypeSelected = (target as HTMLButtonElement).name;
  }

  showFreeGasCompositionClicked() {
    this.showFreeGasCompositionClick.emit();
  }
}
