import { unitOperationsConfig } from '../_config/unit-operations.config';

export class V231 {
  static updateStreamPortsAttachedToSeparateGasStreamUserUnit(serializedCase: any) {
    const unitOperations = Object.values(serializedCase.unitOperationPool) as any[];
    const materialStreams = Object.values(serializedCase.materialStreamPool) as any[];

    const filteredStreamsByFromPort = materialStreams.filter(
      s => s.fromPort === 'lightEnds' || s.fromPort === 'butane'
    );

    filteredStreamsByFromPort.forEach(stream => {
      const inletUnitOperation = unitOperations.find(uo => uo.id === stream.inletUnitOperationId);

      if (
        inletUnitOperation.category === unitOperationsConfig.dcu.key ||
        inletUnitOperation.category === unitOperationsConfig.fluidCoker.key
      ) {
        stream.fromPort = 'gas';
      }
    });
  }
}
