import { Component, inject, Input, OnInit } from '@angular/core';
import { HgoHydrotreater } from '../../../_models/_unit-operations/hgo-hydrotreater';
import { SuncorFluidAnalysis } from '../../../_models/_fluid/suncor-fluid-analysis';
import { FluidAnalysis } from '../../../_models/_fluid/fluid-analysis';
import { CoreService } from '../../../_services/core.service';
import { HgoHydroteaterCatalystRun } from '../../../_config/unit-operations/hgo-hydroteater-catalyst-run.enum';
import { FlowsheetService } from '../../../_services/flowsheet.service';
import { HgoHydrotreaterCodeProvider } from '../../../_services/custom-code-providers/hgo-hydrotreater-code-provider';
import { FlowsheetDiagramService } from '../../../_services/flowsheet-diagram/flowsheet-diagram.service';
import { SeparateGasStreamUserUnitBlockUpdater } from '../../../_services/flowsheet-diagram/separate-gas-stream-user-unit-block-updater';
import { AssayType } from '../../../_config/assay-type.enum';

@Component({
  selector: 'sob-hgo-hydrotreater',
  templateUrl: './hgo-hydrotreater.component.html',
  styleUrls: ['./hgo-hydrotreater.component.css'],
})
export class HgoHydrotreaterComponent implements OnInit {
  @Input() unitOperation: HgoHydrotreater;
  currentAnalysis: SuncorFluidAnalysis;
  HgoHydroTreaterCatalystRun = HgoHydroteaterCatalystRun;
  defaultCodeProvider: HgoHydrotreaterCodeProvider;

  private readonly flowsheetDiagramService = inject(FlowsheetDiagramService);

  constructor(private coreService: CoreService, private flowsheetService: FlowsheetService) {
    this.defaultCodeProvider = new HgoHydrotreaterCodeProvider();
  }

  ngOnInit() {
    this.updateCurrentAnalysis();
  }

  useDataDrivenYieldModelChanged(target: EventTarget): void {
    if ((target as HTMLInputElement).checked) {
      this.unitOperation.naphthaYield.value = undefined;
      this.unitOperation.lgoYield.value = undefined;
      this.unitOperation.hgoYield.value = undefined;
    }
  }

  updateCurrentAnalysis(id?) {
    if (id) {
      this.unitOperation.fluidAnalysisId = id;
    }
    if (this.unitOperation.fluidAnalysisId) {
      this.currentAnalysis = this.coreService.currentCase.assayManager.findFluidAnalysisById(
        this.unitOperation.fluidAnalysisId
      );
    }
  }

  get fluidAnalyses(): FluidAnalysis[] {
    return this.coreService.currentCase.assayManager.getAssaysByType(AssayType.PRODUCT);
  }

  saveCustomModel(res: any) {
    if (res.instanceId === 'hgoHydrotreaterCodeEditor') {
      this.unitOperation.customModelScript = res.code;
      this.flowsheetService.saveCustomCodeChanges();
    }
  }

  separateGasStreamChanged() {
    SeparateGasStreamUserUnitBlockUpdater.create().updateGoJsBlock(
      this.unitOperation.id,
      this.unitOperation.hasSeparateGasStream,
      this.coreService,
      this.flowsheetDiagramService.gojsDiagram
    );
  }
}
