<div class="modal fade" id="streamModal" tabindex="-1" role="dialog" aria-labelledby="streamModalLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" id="">Stream Information</h4>
      </div>
      <div class="modal-body" *ngIf="stream">
        <ul class="nav nav-tabs" role="tablist">
          <li role="presentation" class="active">
            <a href="#streamGeneralTab" class="px-3" role="tab" data-toggle="tab" aria-controls="streamGeneralTab">
              General
            </a>
          </li>

          <li role="presentation">
            <a
              href="#streamPropertiesTab"
              class="px-3"
              role="tab"
              data-toggle="tab"
              aria-controls="streamPropertiesTab">
              Oil Properties
            </a>
          </li>
          <li role="presentation">
            <a
              href="#streamCompositionTabContent"
              id="streamCompositionTab"
              class="px-3"
              role="tab"
              data-toggle="tab"
              aria-controls="streamCompositionTabContent">
              Oil Composition
            </a>
          </li>

          <li role="presentation">
            <a
              href="#streamFreeGasCompositionTabContent"
              class="px-3"
              id="streamFreeGasCompositionTab"
              role="tab"
              data-toggle="tab"
              aria-controls="streamFreeGasCompositionTabContent">
              Gas Composition
            </a>
          </li>
          <li role="presentation">
            <a href="#streamColorTab" class="px-3" role="tab" data-toggle="tab" aria-controls="streamColorTab">
              Color
            </a>
          </li>
        </ul>

        <div class="tab-content pw-margin tab-content-padding">
          <div role="tabpanel" class="tab-pane fade in active" id="streamGeneralTab">
            <div class="form-horizontal">
              <div class="form-group" [formGroup]="streamGroup">
                <label for="streamName" class="control-label col-sm-4"> Name </label>

                <div class="col-sm-8">
                  <div class="row">
                    <div class="col-sm-6">
                      <input type="text" class="form-control" id="streamName" formControlName="name" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <span
                        class="text-danger"
                        *ngIf="nameControl.invalid && (nameControl.dirty || nameControl.touched)">
                        <span *ngIf="nameControl.errors.required">Please enter a name for the stream</span>
                        <span *ngIf="nameControl.errors.unique">Entered name is already in use</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="" class="pw-label control-label col-sm-4">From</label>
                <span style="padding-top: 7px; display: inline-block" class="col-sm-4">
                  {{ stream.inletUnitOperation.name }}
                </span>
              </div>

              <div class="form-group">
                <label for="" class="pw-label control-label col-sm-4">To</label>
                <span style="padding-top: 7px; display: inline-block" class="col-sm-4">
                  {{ stream.outletUnitOperation.name }}
                </span>
              </div>

              <div class="form-group">
                <label [attr.for]="'stream-volumetricFlowrate'" class="pw-label control-label col-sm-4">
                  Volumetric Flowrate
                </label>
                <sim-var-input
                  [inputId]="'stream-volumetricFlowrate'"
                  [simVar]="stream.volumetricFlowrate"
                  [readOnly]="true"
                  [transientValue]="true"></sim-var-input>
              </div>

              <div class="form-group">
                <label [attr.for]="'stream-massFlowrate'" class="pw-label control-label col-sm-4">
                  Mass Flowrate
                </label>
                <sim-var-input
                  [inputId]="'stream-massFlowrate'"
                  [simVar]="stream.massFlowrate"
                  [readOnly]="true"
                  [transientValue]="true"></sim-var-input>
              </div>

              <div class="form-group">
                <label [attr.for]="'stream-ghgEmissions'" class="pw-label control-label col-sm-4">
                  GHG Emissions
                </label>
                <sim-var-input
                  [inputId]="'stream-ghgEmissions'"
                  [simVar]="stream.ghgEmissions"
                  [readOnly]="true"
                  [transientValue]="true"></sim-var-input>
              </div>

              <div class="form-group">
                <label [attr.for]="'stream-ghgIntensity'" class="pw-label control-label col-sm-4">
                  GHG Intensity
                </label>
                <sim-var-input
                  [inputId]="'stream-ghgIntensity'"
                  [simVar]="stream.ghgIntensity"
                  [readOnly]="true"
                  [transientValue]="true"></sim-var-input>
              </div>

              <div class="form-group">
                <label [attr.for]="'stream-price'" class="pw-label control-label col-sm-4"> Price </label>
                <sim-var-input
                  [inputId]="'stream-price'"
                  [simVar]="stream.price"
                  [readOnly]="true"
                  [transientValue]="true"></sim-var-input>
              </div>
            </div>
          </div>

          <div role="tabpanel" class="tab-pane fade" id="streamPropertiesTab">
            <ul class="nav nav-pills" role="tablist">
              <li role="presentation" class="active">
                <a
                  href="#streamBulkPropertiesTabContent"
                  role="tab"
                  data-toggle="tab"
                  aria-controls="streamBulkPropertiesTabContent">
                  Bulk Properties
                </a>
              </li>
            </ul>

            <div class="tab-content pw-margin tab-content-padding">
              <div role="tabpanel" class="tab-pane fade in active" id="streamBulkPropertiesTabContent">
                <sob-ms-bulk-properties [stream]="stream"></sob-ms-bulk-properties>
              </div>
            </div>
          </div>

          <div role="tabpanel" class="tab-pane fade" id="streamCompositionTabContent">
            <sob-ms-composition
              [stream]="stream"
              (showFreeGasCompositionClick)="showFreeGasCompositionTab()"></sob-ms-composition>
          </div>

          <div role="tabpanel" class="tab-pane fade" id="streamFreeGasCompositionTabContent">
            <div class="row">
              <div class="col-md-12 ms-0 ms-md-n5">
                <sob-free-gas-composition-form
                  [disabled]="true"
                  (returnToOilComposition)="showOilCompositionTab()"
                  [freeGasComposition]="stream.freeGasComposition"></sob-free-gas-composition-form>
              </div>
            </div>
          </div>

          <div role="tabpanel" class="tab-pane fade" id="streamColorTab">
            <div class="form-horizontal">
              <div class="form-group">
                <label for="streamColor" class="pw-label control-label col-sm-4"> Color </label>
                <div class="col-sm-4">
                  <input
                    [(colorPicker)]="stream.streamColor"
                    [(ngModel)]="stream.streamColor"
                    class="form-control"
                    id="streamColor"
                    readonly="readonly" />
                </div>
              </div>

              <div class="form-group">
                <label class="control-label pw-label col-sm-4"> Preview </label>
                <div class="col-sm-4">
                  <span class="form-control" [style.backgroundColor]="stream.streamColor"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
