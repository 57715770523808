import { Component, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AbstractFormGroupPropertyWindow } from '../abstract-form-group-property-window';
import { BaseObjectFormGroupWrapper } from '../../../_form-utils/base-object-form-group-wrapper';
import { SimVarFormBuilder } from '../../../_form-utils/sim-var-form-builder';
import { Flexiformer } from '../../../_models/_unit-operations/upgrader/flexiformer';
import { FlowsheetService } from '../../../_services/flowsheet.service';
import { DefaultCodeProvider } from '../../../_services/custom-code-providers/default-code-provider';
import { FlexiformerUnitBlockUpdater } from '../../../_services/flowsheet-diagram/flexiformer-unit-block-updater';
import { FlowsheetDiagramService } from '../../../_services/flowsheet-diagram/flowsheet-diagram.service';
import { CoreService } from '../../../_services/core.service';

@Component({
  selector: 'sob-flexiformer',
  templateUrl: './flexiformer.component.html',
  styleUrls: ['./flexiformer.component.css'],
})
export class FlexiformerComponent extends AbstractFormGroupPropertyWindow implements OnInit {
  @Input() unitOperation: Flexiformer;
  @Input() formGroupWrapper!: BaseObjectFormGroupWrapper;

  defaultCodeProvider: DefaultCodeProvider;
  readonly codeEditorInstanceId = 'flexiformerCodeEditor';

  private readonly svfb = inject(SimVarFormBuilder);
  private readonly flowsheetService = inject(FlowsheetService);
  private readonly flowsheetDiagramService = inject(FlowsheetDiagramService);
  private readonly coreService = inject(CoreService);

  formGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    allowNaphthaFeed: new FormControl<boolean>(false),
    c4sRecovery: this.svfb.emptyControl(),
    c5Recovery: this.svfb.emptyControl(),
    ghgEmissions: this.svfb.emptyControl(true),
    opexVar: this.svfb.emptyControl(),
    opexGasDiesel: this.svfb.emptyControl(),
    opexPower: this.svfb.emptyControl(),
    opexCarbonDioxide: this.svfb.emptyControl(true),
    opexFixed: this.svfb.emptyControl(),
    opexTotal: this.svfb.emptyControl(true),

    capexAmortized: this.svfb.emptyControl(),
    capexTotal: this.svfb.emptyControl(true),
    gasFeedCapacity: this.svfb.emptyConstraintControl(),
    naphthaFeedCapacity: this.svfb.emptyConstraintControl(),
    minimumFeedGasFlow: this.svfb.emptyConstraintControl(),

    useCustomModel: new FormControl<boolean>(false),
    customModelScript: new FormControl<string>(''),
  });

  constructor() {
    super();

    this.defaultCodeProvider = new DefaultCodeProvider('');
  }

  ngOnInit(): void {
    this.init();
  }

  addControls() {
    this.formGroup.controls.name.patchValue(this.unitOperation.name);
    this.svfb.patchValue(this.unitOperation.c4sRecovery, this.formGroup.controls.c4sRecovery);
    this.svfb.patchValue(this.unitOperation.c5Recovery, this.formGroup.controls.c5Recovery);
    this.svfb.patchValue(this.unitOperation.ghgEmissions, this.formGroup.controls.ghgEmissions);
    this.svfb.patchValue(this.unitOperation.opexVar, this.formGroup.controls.opexVar);
    this.svfb.patchValue(this.unitOperation.opexGasDiesel, this.formGroup.controls.opexGasDiesel);
    this.svfb.patchValue(this.unitOperation.opexPower, this.formGroup.controls.opexPower);
    this.svfb.patchValue(this.unitOperation.opexCarbonDioxide, this.formGroup.controls.opexCarbonDioxide);
    this.svfb.patchValue(this.unitOperation.opexFixed, this.formGroup.controls.opexFixed);
    this.svfb.patchValue(this.unitOperation.opexTotal, this.formGroup.controls.opexTotal);
    this.svfb.patchValue(this.unitOperation.capexAmortized, this.formGroup.controls.capexAmortized);
    this.svfb.patchValue(this.unitOperation.capexTotal, this.formGroup.controls.capexTotal);
    this.svfb.patchConstraintValue(this.unitOperation.gasFeedCapacity, this.formGroup.controls.gasFeedCapacity);
    this.svfb.patchConstraintValue(this.unitOperation.naphthaFeedCapacity, this.formGroup.controls.naphthaFeedCapacity);
    this.svfb.patchConstraintValue(this.unitOperation.minimumFeedGasFlow, this.formGroup.controls.minimumFeedGasFlow);
    this.formGroup.controls.allowNaphthaFeed.patchValue(this.unitOperation.allowNaphthaFeed);

    for (const key of Object.keys(this.formGroup.controls)) {
      this.propertyWindowFormGroup.addControl(key, this.formGroup.controls[key]);
    }

    this.formGroup.controls.useCustomModel.patchValue(this.unitOperation.useCustomModel);
    this.formGroup.controls.customModelScript.patchValue(this.unitOperation.customModelScript);
  }

  // region naphtha feed
  allowNaphthaFeedChanged() {
    FlexiformerUnitBlockUpdater.create().updateGoJsBlock(
      this.unitOperation.id,
      this.isAllowNaphthaFeedEnabled,
      this.coreService,
      this.flowsheetDiagramService.gojsDiagram
    );
  }

  protected get isAllowNaphthaFeedEnabled() {
    return this.formGroup.controls.allowNaphthaFeed.value;
  }
  // endregion

  // region custom model script
  protected get isCustomModelEnabled() {
    return this.formGroup.controls.useCustomModel.value;
  }

  saveCustomModel(target: { code: string; instanceId: string }) {
    if (target.instanceId === this.codeEditorInstanceId) {
      this.formGroup.controls.customModelScript.patchValue(target.code);
      this.unitOperation.customModelScript = this.propertyWindowFormGroup.get('customModelScript').value;
      this.flowsheetService.saveCustomCodeChanges();
    }
  }
  // endregion
}
