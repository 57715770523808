// To map unit operations from property windows
import { BaseObject } from '../_models/base-object';
import { SimulationVariableMapper } from './simulation-variable-mapper';
import { unitOperationsConfig } from '../_config/unit-operations.config';
import { WaterBoilerFormGroupMapper } from './water-boiler-form-group.mapper';
import { WaterSourceImportFormGroupMapper } from './water-source-import-form-group.mapper';
import { DefaultBaseObjectFormGroupMapper } from './default-base-object-form-group.mapper';
import { WaterSinkImportFormGroupMapper } from './water-sink-import-form-group.mapper';
import { WaterHeaderFormGroupMapper } from './water-header-form-group.mapper';
import { WaterSplitterFormGroupMapper } from './water-splitter-form-group.mapper';
import { FuelGasSinkImportFormGroupMapper } from './fuel-gas-sink-import-form-group.mapper';
import { FuelGasSourceImportFormGroupMapper } from './fuel-gas-source-import-form-group.mapper';
import { FuelGasSplitterFormGroupMapper } from './fuel-gas-splitter-form-group.mapper';
import { WaterSourceFormGroupMapper } from './water-source-form-group.mapper';
import { SplitterFormGroupMapper } from './splitter-form-group.mapper';
import { ProductTankFormGroupMapper } from './product-tank-form-group.mapper';
import { HydrotreaterFormGroupMapper } from './hydrotreater-form-group-mapper';
import { MixerFormGroupMapper } from './mixer-form-group.mapper';
import { BitumenConversionFormGroupMapper } from './bitumen-conversion-form-group-mapper';

export class BaseObjectFormGroupMapper {
  private readonly mappers: { [id: string]: DefaultBaseObjectFormGroupMapper };
  private readonly defaultMapper: DefaultBaseObjectFormGroupMapper;

  constructor(private simVarMapper: SimulationVariableMapper) {
    this.mappers = {};
    // add here the mappers that correspond to each unit operation... by category
    this.mappers[unitOperationsConfig.bitumenConversion.key] = new BitumenConversionFormGroupMapper(this.simVarMapper);
    this.mappers[unitOperationsConfig.refinery.key] = new DefaultBaseObjectFormGroupMapper(this.simVarMapper);
    this.mappers[unitOperationsConfig.pipe.key] = new DefaultBaseObjectFormGroupMapper(this.simVarMapper);
    this.mappers[unitOperationsConfig.hydrotreater.key] = new HydrotreaterFormGroupMapper(this.simVarMapper);
    this.mappers[unitOperationsConfig.lgoHydrotreater.key] = new DefaultBaseObjectFormGroupMapper(this.simVarMapper);
    this.mappers[unitOperationsConfig.splitter.key] = new SplitterFormGroupMapper(this.simVarMapper);
    this.mappers[unitOperationsConfig.mixer.key] = new MixerFormGroupMapper(this.simVarMapper);
    this.mappers[unitOperationsConfig.productTank.key] = new ProductTankFormGroupMapper(this.simVarMapper);
    this.mappers[unitOperationsConfig.gasExport.key] = new DefaultBaseObjectFormGroupMapper(this.simVarMapper);
    this.mappers[unitOperationsConfig.lightEndsRecoveryUnit.key] = new DefaultBaseObjectFormGroupMapper(
      this.simVarMapper
    );
    this.mappers[unitOperationsConfig.flexiformer.key] = new DefaultBaseObjectFormGroupMapper(this.simVarMapper);

    this.mappers[unitOperationsConfig.waterSource.key] = new WaterSourceFormGroupMapper(this.simVarMapper);
    this.mappers[unitOperationsConfig.waterSourceImport.key] = new WaterSourceImportFormGroupMapper(this.simVarMapper);
    this.mappers[unitOperationsConfig.waterSinkImport.key] = new WaterSinkImportFormGroupMapper(this.simVarMapper);

    this.mappers[unitOperationsConfig.waterBoiler.key] = new WaterBoilerFormGroupMapper(this.simVarMapper);

    this.mappers[unitOperationsConfig.waterHeader.key] = new WaterHeaderFormGroupMapper(this.simVarMapper);
    this.mappers[unitOperationsConfig.waterSplitter.key] = new WaterSplitterFormGroupMapper(this.simVarMapper);
    this.mappers[unitOperationsConfig.sulfurPlant.key] = new DefaultBaseObjectFormGroupMapper(this.simVarMapper);
    this.mappers[unitOperationsConfig.waterTurbine.key] = new DefaultBaseObjectFormGroupMapper(this.simVarMapper);
    this.mappers[unitOperationsConfig.waterCogeneration.key] = new DefaultBaseObjectFormGroupMapper(this.simVarMapper);

    this.mappers[unitOperationsConfig.fuelGasSinkImport.key] = new FuelGasSinkImportFormGroupMapper(this.simVarMapper);
    this.mappers[unitOperationsConfig.fuelGasSourceImport.key] = new FuelGasSourceImportFormGroupMapper(
      this.simVarMapper
    );
    this.mappers[unitOperationsConfig.fuelGasSink.key] = new DefaultBaseObjectFormGroupMapper(this.simVarMapper);
    this.mappers[unitOperationsConfig.fuelGasFlare.key] = new DefaultBaseObjectFormGroupMapper(this.simVarMapper);

    this.mappers[unitOperationsConfig.fuelGasSource.key] = new DefaultBaseObjectFormGroupMapper(this.simVarMapper);
    this.mappers[unitOperationsConfig.fuelGasSplitter.key] = new FuelGasSplitterFormGroupMapper(this.simVarMapper);
    this.mappers[unitOperationsConfig.fuelGasAmineContactor.key] = new DefaultBaseObjectFormGroupMapper(
      this.simVarMapper
    );
  }

  /**
   *
   * @param source the source of the values, normally it should be a plain javascript object coming from
   * FormGroup.value, however it should work for base objects as well
   * @param destination a base object that will receive the new values
   */
  map(source: BaseObject, destination: BaseObject) {
    this.mappers[destination.category].map(source, destination);
  }

  updateVariableStatus(source: BaseObject, destination: BaseObject) {
    this.mappers[destination.category].updateVariableStatus(source, destination);
  }
}
