import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FluidAnalysis } from '../../../_models/_fluid/fluid-analysis';
import { AssayType } from '../../../_config/assay-type.enum';
import { generateUuid } from '../../../_utils/utils';

@Component({
  selector: 'sob-assay-list',
  templateUrl: './assay-list.component.html',
  styleUrls: ['./assay-list.component.css'],
})
export class AssayListComponent {
  @Input() assayList: FluidAnalysis[];
  @Output() editClicked = new EventEmitter<string>();
  @Output() cloneClicked = new EventEmitter<string>();
  @Output() removeClicked = new EventEmitter<{ tableId: string; assayId: string }>();

  protected readonly tableId = generateUuid();

  protected readonly AssayType = AssayType;

  onEditClicked(assay: FluidAnalysis) {
    this.editClicked.emit(assay.id);
  }

  onCloneClicked(assay: FluidAnalysis) {
    this.cloneClicked.emit(assay.id);
  }

  onRemoveClicked(assay: FluidAnalysis) {
    this.removeClicked.emit({ tableId: this.tableId, assayId: assay.id });
  }
}
