import { SimulationVariable } from '../../simulation-variable';
import { GasContributorUnitOperation } from '../gas-contributor-unit-operation';
import { ConstrainableObject } from '../../_interfaces/constrainable-object';
import { KpiProvider } from '../../_case-study/kpi-provider';
import { ParameterProvider } from '../../_case-study/parameter-provider';
import { UnitOperationConstraints } from '../unit-operation-constraints';
import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { Case } from '../../case';
import { isTypeUndefined } from '../../../_utils/utils';
import { Quantity } from '../../../_config/quantity.enum';
import { SimulationVariableName } from '../../../_config/simulation-variable-name.enum';

export class Flexiformer
  extends GasContributorUnitOperation
  implements ConstrainableObject, KpiProvider, ParameterProvider
{
  category = unitOperationsConfig.flexiformer.key;

  allowNaphthaFeed: boolean;
  c4sRecovery: SimulationVariable;
  c5Recovery: SimulationVariable;
  gasFeedCapacity: SimulationVariable;
  naphthaFeedCapacity: SimulationVariable;
  minimumFeedGasFlow: SimulationVariable;
  constraints: UnitOperationConstraints;

  useCustomModel = false;
  customModelScript = '';

  constructor(unitOperation: any | Flexiformer, ownerCase: Case) {
    super(unitOperation, ownerCase);
    this.initValues(unitOperation);
    this.setSimulationVariableNames();
  }

  override initValues(unitOperation: any) {
    super.initValues(unitOperation);

    this.allowNaphthaFeed = isTypeUndefined(unitOperation.allowNaphthaFeed) ? false : unitOperation.allowNaphthaFeed;
    this.c4sRecovery = new SimulationVariable(unitOperation.c4sRecovery, this.ownerCase, this);
    this.c5Recovery = new SimulationVariable(unitOperation.c5Recovery, this.ownerCase, this);
    this.gasFeedCapacity = new SimulationVariable(unitOperation.gasFeedCapacity, this.ownerCase, this);
    this.naphthaFeedCapacity = new SimulationVariable(unitOperation.naphthaFeedCapacity, this.ownerCase, this);
    this.minimumFeedGasFlow = new SimulationVariable(unitOperation.minimumFeedGasFlow, this.ownerCase, this);

    this.customModelScript = unitOperation.customModelScript ?? '';
    this.useCustomModel = !!unitOperation.useCustomModel;

    this.initConstraints();
    this.setQuantities();
  }

  override setQuantities() {
    super.setQuantities();
    this.c4sRecovery.setQuantity(Quantity.PERCENT);
    this.c5Recovery.setQuantity(Quantity.PERCENT);
    this.gasFeedCapacity.setQuantity(Quantity.MOLEFLOWRATE);
    this.minimumFeedGasFlow.setQuantity(Quantity.MOLEFLOWRATE);
    this.naphthaFeedCapacity.setQuantity(Quantity.LIQUID_VOLUMETRIC_FLOWRATE);
  }

  override setDefaultValues() {
    super.setDefaultValues();

    if (this.c4sRecovery.isUndefined()) {
      this.c4sRecovery.setDefaultValue(100);
    }

    if (this.c5Recovery.isUndefined()) {
      this.c5Recovery.setDefaultValue(100);
    }
  }

  override setSimulationVariableNames() {
    super.setSimulationVariableNames();

    this.gasFeedCapacity.setName(SimulationVariableName.GAS_FEED_CAPACITY);
    this.minimumFeedGasFlow.setName(SimulationVariableName.MINIMUM_FEED_GAS_FLOW);
    this.naphthaFeedCapacity.setName(SimulationVariableName.NAPHTHA_FEED_CAPACITY);
    this.fuelGasHeatingValue.setName(SimulationVariableName.FUEL_GAS_HEATING_VALUE);
    this.fuelGasEmissionFactor.setName(SimulationVariableName.FUEL_GAS_EMISSION_FACTOR);
    this.energyFlow.setName(SimulationVariableName.ENERGY_FLOW);
    this.flowrate.setName(SimulationVariableName.FUEL_GAS_FLOWRATE);
    this.opexTotal.setName('Total OPEX');
    this.capexTotal.setName('Total CAPEX');
  }

  override dePersist(uo: any) {
    super.dePersist(uo);
    this.c4sRecovery = this.getSimulationVariableSafe(uo.c4sRecovery);
    this.c5Recovery = this.getSimulationVariableSafe(uo.c5Recovery);
    this.gasFeedCapacity = this.getSimulationVariableSafe(uo.gasFeedCapacity);
    this.naphthaFeedCapacity = this.getSimulationVariableSafe(uo.naphthaFeedCapacity);
    this.minimumFeedGasFlow = this.getSimulationVariableSafe(uo.minimumFeedGasFlow);
    this.setQuantities();
    this.setDefaultValues();
    this.initConstraints();
  }

  // region constraints
  initConstraints() {
    this.constraints = new UnitOperationConstraints();
    this.constraints.addConstraint('gasFeedCapacity', this.gasFeedCapacity);
    this.constraints.addConstraint('naphthaFeedCapacity', this.naphthaFeedCapacity);
    this.constraints.addConstraint('minimumFeedGasFlow', this.minimumFeedGasFlow);
  }

  constraintValueDefined(): boolean {
    return this.constraints.anyConstraintValueDefined();
  }

  constraintViolated(): boolean {
    const inletStream = this.ownerCase.filterSuncorMaterialStreams(s => {
      return s.outletUnitOperationId === this.id;
    })[0];

    return (
      inletStream && this.constraints.constraintValueViolated('gasFeedCapacity', inletStream.volumetricFlowrate.value)
    );
  }
  // endregion

  // region kpis & parameters
  getAvailableKpis(): Array<SimulationVariable> {
    return [
      this.ghgEmissions,
      this.steamUse900,
      this.steamMake900,
      this.steamMake600,
      this.steamUse600,
      this.steamMake150,
      this.steamUse150,
      this.steamMake50,
      this.steamUse50,
      this.fuelGasHeatingValue,
      this.fuelGasEmissionFactor,
      this.energyFlow,
      this.flowrate,
      this.opexTotal,
      this.capexTotal,
    ];
  }

  getAvailableParameters(): Array<SimulationVariable> {
    return undefined;
  }

  getAvailableParametricStudyParameters(): Array<SimulationVariable> {
    return [this.ghgIntensity, this.opexVar, this.opexGasDiesel, this.opexPower, this.opexFixed, this.capexAmortized];
  }
  // endregion

  getAlternativeGhgIntensity(): SimulationVariable {
    return undefined;
  }
}
