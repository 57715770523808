<ng-container [formGroup]="formGroup">
  <ul class="nav nav-tabs tabs-margin" role="tablist">
    <li role="presentation" class="active">
      <a href="#general" aria-controls="general" role="tab" data-toggle="tab">General</a>
    </li>
    <li role="presentation">
      <a href="#utilities" aria-controls="utilities" role="tab" data-toggle="tab">Utilities</a>
    </li>
    <li role="presentation">
      <a href="#economics" aria-controls="economics" role="tab" data-toggle="tab">Economics</a>
    </li>
    <li role="presentation">
      <a href="#constraints" aria-controls="constraints" role="tab" data-toggle="tab"> Constraints </a>
    </li>

    <li role="presentation">
      <a href="#customModels" aria-controls="customModels" role="tab" data-toggle="tab"> Custom Models </a>
    </li>

    <li role="presentation">
      <a href="#flexiformerComments" aria-controls="flexiformerComments" role="tab" data-toggle="tab">
        <i class="fa fa-comments fa-lg" aria-hidden="true"> </i>
        Comments
      </a>
    </li>
  </ul>

  <div class="tab-content pw-margin tab-content-padding">
    <div role="tabpanel" class="tab-pane fade in active" id="general">
      <div class="tab-content no-padding no-border">
        <div class="form-horizontal">
          <div class="form-group">
            <label for="allowNaphthaFeed" class="pw-label control-label col-sm-4"> Allow Naphtha Feed </label>
            <div class="col-sm-4">
              <label class="control-label">
                <input
                  formControlName="allowNaphthaFeed"
                  type="checkbox"
                  id="allowNaphthaFeed"
                  (change)="allowNaphthaFeedChanged()" />
              </label>
            </div>
          </div>

          <div class="form-group" formGroupName="c4sRecovery">
            <label class="pw-label control-label col-sm-4"> C4s Recovery </label>
            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select
                    formControlName="unit"
                    [quantity]="unitOperation.c4sRecovery.quantity"></sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.c4sRecovery"></sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="c5Recovery">
            <label class="pw-label control-label col-sm-4"> C5+ Recovery </label>
            <div class="col-sm-8">
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select
                    formControlName="unit"
                    [quantity]="unitOperation.c5Recovery.quantity"></sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.c5Recovery"></sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div role="tabpanel" class="tab-pane fade in" id="utilities">
      <div class="tab-content no-padding no-border">
        <h4 class="subtitle">GHG</h4>
        <div class="form-horizontal">
          <div class="form-group" formGroupName="ghgEmissions">
            <label class="pw-label control-label col-sm-4"> GHG Emissions </label>
            <div class="col-sm-8">
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select
                    formControlName="unit"
                    [quantity]="unitOperation.ghgEmissions.quantity"></sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.ghgEmissions"></sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div class="form-horizontal utilities-mt">
          <sob-reactive-steam-use-utility
            [formGroupWrapper]="formGroupWrapper"
            [unitOperation]="unitOperation"></sob-reactive-steam-use-utility>
          <sob-reactive-gas-contributor
            [formGroupWrapper]="formGroupWrapper"
            [unitOperation]="unitOperation"></sob-reactive-gas-contributor>
        </div>
      </div>
    </div>

    <div role="tabpanel" class="tab-pane fade in" id="economics">
      <div class="tab-content no-padding no-border">
        <div class="form-horizontal">
          <h4 class="subtitle">OPEX</h4>

          <div class="form-group" formGroupName="opexVar">
            <label class="control-label col-sm-4"> Variable </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.opexVar.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.opexVar">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="opexGasDiesel">
            <label class="control-label col-sm-4"> Gas Diesel </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.opexGasDiesel.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.opexGasDiesel">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="opexPower">
            <label class="control-label col-sm-4"> Power </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.opexPower.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.opexPower">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="opexCarbonDioxide">
            <label class="control-label col-sm-4"> CO2 </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select
                    formControlName="unit"
                    [quantity]="unitOperation.opexCarbonDioxide.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.opexCarbonDioxide">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="opexFixed">
            <label class="control-label col-sm-4"> Fixed </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.opexFixed.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.opexFixed">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" formGroupName="opexTotal">
            <label class="control-label col-sm-4"> Total </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.opexTotal.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.opexTotal">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <h4 class="subtitle">CAPEX</h4>

          <div class="form-group" formGroupName="capexAmortized">
            <label class="control-label col-sm-4"> Amortized CAPEX </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.capexAmortized.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.capexAmortized">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group" formGroupName="capexTotal">
            <label class="control-label col-sm-4"> Total </label>

            <div class="col-sm-8" sobReactiveSimVarInput>
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.capexTotal.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-6">
                  <sob-reactive-variable-value-input formControlName="value" [valueMetaData]="unitOperation.capexTotal">
                  </sob-reactive-variable-value-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div role="tabpanel" class="tab-pane fade in" id="constraints">
      <p class="constraints-active-label">
        <span>Active</span>
      </p>

      <div class="form-horizontal">
        <div class="form-group" formGroupName="gasFeedCapacity">
          <label class="pw-label control-label col-sm-4">Gas Feed Capacity</label>
          <div class="col-sm-8">
            <div class="row">
              <div class="col-xs-6">
                <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.gasFeedCapacity.quantity">
                </sob-reactive-unit-select>
              </div>
              <div class="col-xs-5 ps-1 pe-3">
                <sob-reactive-variable-value-input
                  formControlName="value"
                  [valueMetaData]="unitOperation.gasFeedCapacity">
                </sob-reactive-variable-value-input>
              </div>
              <div class="col-xs-1 text-center ps-2_5 pe-1">
                <label class="control-label">
                  <input type="checkbox" formControlName="isActive" />
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group" formGroupName="minimumFeedGasFlow">
          <label class="pw-label control-label col-sm-4">Minimum Feed Gas Flow</label>
          <div class="col-sm-8">
            <div class="row">
              <div class="col-xs-6">
                <sob-reactive-unit-select formControlName="unit" [quantity]="unitOperation.minimumFeedGasFlow.quantity">
                </sob-reactive-unit-select>
              </div>
              <div class="col-xs-5 ps-1 pe-3">
                <sob-reactive-variable-value-input
                  formControlName="value"
                  [valueMetaData]="unitOperation.minimumFeedGasFlow">
                </sob-reactive-variable-value-input>
              </div>
              <div class="col-xs-1 text-center ps-2_5 pe-1">
                <label class="control-label">
                  <input type="checkbox" formControlName="isActive" />
                </label>
              </div>
            </div>
          </div>
        </div>

        <ng-container *ngIf="isAllowNaphthaFeedEnabled">
          <div class="form-group" formGroupName="naphthaFeedCapacity">
            <label class="pw-label control-label col-sm-4">Naphtha Feed Capacity</label>
            <div class="col-sm-8">
              <div class="row">
                <div class="col-xs-6">
                  <sob-reactive-unit-select
                    formControlName="unit"
                    [quantity]="unitOperation.naphthaFeedCapacity.quantity">
                  </sob-reactive-unit-select>
                </div>
                <div class="col-xs-5 ps-1 pe-3">
                  <sob-reactive-variable-value-input
                    formControlName="value"
                    [valueMetaData]="unitOperation.naphthaFeedCapacity">
                  </sob-reactive-variable-value-input>
                </div>
                <div class="col-xs-1 text-center ps-2_5 pe-1">
                  <label class="control-label">
                    <input type="checkbox" formControlName="isActive" />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div role="tabpanel" class="tab-pane fade in" id="customModels">
      <div class="tab-content no-padding no-border">
        <div class="form-horizontal">
          <div class="form-group">
            <label for="customModelEnabled" class="control-label col-sm-4"> Enabled Custom Model </label>
            <div class="col-sm-4">
              <label class="control-label">
                <input formControlName="useCustomModel" type="checkbox" id="customModelEnabled" />
              </label>
            </div>
          </div>

          <div class="mb-4">
            <sob-open-reactive-code-editor-button
              [code]="unitOperation.customModelScript"
              [defaultCodeProvider]="defaultCodeProvider"
              [disabled]="!isCustomModelEnabled"
              [instanceId]="codeEditorInstanceId"
              (codeSaved)="saveCustomModel($event)">
            </sob-open-reactive-code-editor-button>
          </div>
        </div>
      </div>
    </div>

    <div role="tabpanel" class="tab-pane fade in" id="flexiformerComments">
      <div class="tab-content no-padding no-border">
        <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
      </div>
    </div>
  </div>
</ng-container>
