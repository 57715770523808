import { Component, inject, Input, OnInit } from '@angular/core';
import { Hydrocracker } from '../../../_models/_unit-operations/hydrocracker';
import { FlowsheetService } from '../../../_services/flowsheet.service';
import { HydrocrackerCodeProvider } from '../../../_services/custom-code-providers/hydrocracker-code-provider';
import { FlowsheetDiagramService } from '../../../_services/flowsheet-diagram/flowsheet-diagram.service';
import { CoreService } from '../../../_services/core.service';
import { SeparateGasStreamUserUnitBlockUpdater } from '../../../_services/flowsheet-diagram/separate-gas-stream-user-unit-block-updater';

@Component({
  selector: 'sob-hydrocracker',
  templateUrl: './hydrocracker.component.html',
  styleUrls: ['./hydrocracker.component.css'],
})
export class HydrocrackerComponent implements OnInit {
  @Input() unitOperation: Hydrocracker;
  defaultCodeProvider: HydrocrackerCodeProvider;

  private readonly flowsheetDiagramService = inject(FlowsheetDiagramService);
  private readonly coreService = inject(CoreService);

  constructor(private flowsheetService: FlowsheetService) {}

  ngOnInit() {
    this.defaultCodeProvider = new HydrocrackerCodeProvider();
  }

  saveCustomModel(res: any) {
    if (res.instanceId === 'hcCodeEditor') {
      this.unitOperation.modelScript = res.code;
      this.flowsheetService.saveCustomCodeChanges();
    }
  }

  separateGasStreamChanged() {
    SeparateGasStreamUserUnitBlockUpdater.create().updateGoJsBlock(
      this.unitOperation.id,
      this.unitOperation.hasSeparateGasStream,
      this.coreService,
      this.flowsheetDiagramService.gojsDiagram
    );
  }
}
