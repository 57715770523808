import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SuncorFluidAnalysis } from '../../../_models/_fluid/suncor-fluid-analysis';

@Component({
  selector: 'sob-reactive-cut-properties',
  templateUrl: './reactive-cut-properties.component.html',
  styleUrls: ['./reactive-cut-properties.component.css'],
})
export class ReactiveCutPropertiesComponent implements OnInit, OnChanges {
  @Input() fluidAnalysis: SuncorFluidAnalysis;
  @Input() readonly: boolean;
  @Input() idPrefix: string;
  formGroup: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.readonly = this.readonly ? this.readonly : false;
    this.idPrefix = this.idPrefix ? this.idPrefix : '';
    this.formGroup = this.fb.group({});
    this.addControls();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['fluidAnalysis'] && !changes['fluidAnalysis']?.isFirstChange()) {
      this.updateControls();
    }
  }

  addControls() {
    this.formGroup.addControl('lightEndsCut', this.fb.group({}));
    this.formGroup.addControl('lightNaphthaCut', this.fb.group({}));
    this.formGroup.addControl('heavyNaphthaCut', this.fb.group({}));
    this.formGroup.addControl('distillateCut', this.fb.group({}));
    this.formGroup.addControl('LGOCut', this.fb.group({}));
    this.formGroup.addControl('HGOCut', this.fb.group({}));
    this.formGroup.addControl('VGOCut', this.fb.group({}));
    this.formGroup.addControl('HVGOCut', this.fb.group({}));
    this.formGroup.addControl('residCut', this.fb.group({}));
  }

  updateControlsValues(groupName: string) {
    const cutControlsGroup = this.formGroup.get(groupName) as UntypedFormGroup;
    const keys = Object.keys(cutControlsGroup.controls);
    for (const controlsKey of keys) {
      cutControlsGroup.controls[controlsKey].get('unit').setValue(this.fluidAnalysis[groupName][controlsKey].unit);
      cutControlsGroup.controls[controlsKey].get('value').setValue(this.fluidAnalysis[groupName][controlsKey].value);
    }
  }

  updateControls() {
    this.updateControlsValues('lightEndsCut');
    this.updateControlsValues('lightNaphthaCut');
    this.updateControlsValues('heavyNaphthaCut');
    this.updateControlsValues('distillateCut');
    this.updateControlsValues('LGOCut');
    this.updateControlsValues('HGOCut');
    this.updateControlsValues('VGOCut');
    this.updateControlsValues('HVGOCut');
    this.updateControlsValues('residCut');
  }
}
