<div class="form-horizontal">
  <div class="form-group">
    <label [attr.for]="'stream-massDensity'" class="pw-label control-label col-sm-4"> Mass Density </label>
    <sim-var-input
      [inputId]="'stream-massDensity'"
      [simVar]="stream.massDensity"
      [readOnly]="true"
      [transientValue]="true"></sim-var-input>
  </div>

  <div class="form-group">
    <label [attr.for]="'stream-ccr'" class="pw-label control-label col-sm-4"> CCR </label>
    <sim-var-input
      [inputId]="'stream-ccr'"
      [simVar]="stream.ccr"
      [readOnly]="true"
      [transientValue]="true"></sim-var-input>
  </div>

  <div class="form-group">
    <label [attr.for]="'stream-sulfur'" class="pw-label control-label col-sm-4"> Sulfur </label>
    <sim-var-input
      [inputId]="'stream-sulfur'"
      [simVar]="stream.sulfur"
      [readOnly]="true"
      [transientValue]="true"></sim-var-input>
  </div>

  <div class="form-group">
    <label [attr.for]="'stream-nitrogen'" class="pw-label control-label col-sm-4"> Nitrogen </label>
    <sim-var-input
      [inputId]="'stream-nitrogen'"
      [simVar]="stream.nitrogen"
      [readOnly]="true"
      [transientValue]="true"></sim-var-input>
  </div>
</div>
