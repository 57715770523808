<div class="text-center" style="margin-top: 20px; margin-bottom: 20px" *ngIf="unitOperation.useSubFlowsheet">
  <a (click)="openSubFlowsheet()" class="btn btn-primary" style="margin-bottom: 15px">
    <i class="fa fa-window-maximize"></i> Use Subflowsheet
  </a>
  <hr />
</div>

<div class="form-horizontal">
  <div class="form-group">
    <label for="typeDD" class="pw-label control-label col-sm-4"> Assay Name </label>
    <div class="col-sm-6" id="typeDD">
      <sob-assay-selector
        [fluidAnalyses]="fluidAnalyses"
        [currentAnalysis]="currentAnalysis"
        (updateCurrentAssay)="updateCurrentAnalysis($event)"></sob-assay-selector>
    </div>
  </div>
</div>
<hr />

<ul class="nav nav-tabs" role="tablist" style="margin-right: 15px; margin-top: 20px">
  <li role="presentation" class="active">
    <a href="#blockSpecifications" aria-controls="blockSpecifications" role="tab" data-toggle="tab">General</a>
  </li>
  <li *ngIf="currentAnalysis" role="presentation">
    <a href="#fluidCharacterization" aria-controls="fluidCharacterization" role="tab" data-toggle="tab">Fluid</a>
  </li>
  <li role="presentation">
    <a href="#economics" aria-controls="economics" role="tab" data-toggle="tab">Economics</a>
  </li>

  <li role="presentation">
    <a href="#extractionComments" aria-controls="extractionComments" role="tab" data-toggle="tab">
      <i class="fa fa-comments fa-lg" aria-hidden="true"> </i>
      Comments
    </a>
  </li>
</ul>

<div class="tab-content pw-margin tab-content-padding">
  <div role="tabpanel" class="tab-pane fade in active" id="blockSpecifications">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <div class="form-group">
          <label for="level2Enabled" class="control-label col-sm-4"> Enable Level 2 Model </label>
          <div class="col-sm-4">
            <label class="control-label">
              <input
                type="checkbox"
                id="level2Enabled"
                (ngModelChange)="changeNodeTemplate($event)"
                (click)="clearOpex()"
                [(ngModel)]="unitOperation.useSubFlowsheet" />
            </label>
          </div>

          <div class="clearfix"></div>
        </div>

        <div class="form-group">
          <label [attr.for]="'yield'" class="pw-label control-label col-sm-4"> Yield </label>
          <sim-var-input [inputId]="'yield'" [simVar]="unitOperation.yield"> </sim-var-input>
        </div>

        <div class="form-group">
          <label [attr.for]="'bitumenLossToTailing'" class="pw-label control-label col-sm-4">
            Bitumen Loss to Tailing
          </label>
          <sim-var-input
            [inputId]="'bitumenLossToTailing'"
            [readOnly]="true"
            [simVar]="unitOperation.bitumenLossToTailing">
          </sim-var-input>
        </div>

        <hr />
        <h4 class="subtitle">GHG</h4>
        <div class="form-group">
          <label [attr.for]="'ghgIntensity'" class="pw-label control-label col-sm-4"> GHG Intensity </label>
          <sim-var-input [inputId]="'ghgIntensity'" [simVar]="unitOperation.ghgIntensity"></sim-var-input>
        </div>

        <div class="form-group">
          <label [attr.for]="'ghgEmissions'" class="pw-label control-label col-sm-4"> GHG Emissions </label>
          <sim-var-input
            [inputId]="'absoluteGhgEmissions'"
            [simVar]="unitOperation.ghgEmissions"
            [readOnly]="true"></sim-var-input>
        </div>
      </div>
    </div>
  </div>
  <div role="tabpanel" class="tab-pane fade in" id="economics">
    <div class="tab-content no-padding no-border">
      <div class="form-horizontal">
        <div class="form-group">
          <label [attr.for]="'price'" class="pw-label control-label col-sm-4"> Price </label>
          <sim-var-input [inputId]="'price'" [simVar]="unitOperation.price"></sim-var-input>
        </div>
        <hr />
        <h4 class="subtitle">OPEX</h4>
        <div class="form-group">
          <label [attr.for]="'opexFixed'" class="pw-label control-label col-sm-4"> Fixed </label>
          <sim-var-input [inputId]="'opexFixed'" [simVar]="unitOperation.opexFixed"></sim-var-input>
        </div>

        <div class="form-group">
          <label [attr.for]="'opexVar'" class="pw-label control-label col-sm-4"> Variable </label>
          <sim-var-input [inputId]="'opexVar'" [simVar]="unitOperation.opexVar"></sim-var-input>
        </div>

        <div class="form-group">
          <label [attr.for]="'opexCarbonDioxide'" class="pw-label control-label col-sm-4"> CO2 </label>
          <sim-var-input
            [inputId]="'opexCarbonDioxide'"
            [simVar]="unitOperation.opexCarbonDioxide"
            [readOnly]="true"></sim-var-input>
        </div>

        <div class="form-group">
          <label [attr.for]="'opexTotal'" class="pw-label control-label col-sm-4"> Total </label>
          <sim-var-input [inputId]="'opexTotal'" [simVar]="unitOperation.opexTotal" [readOnly]="true"></sim-var-input>
        </div>

        <hr />
        <h4 class="subtitle">CAPEX</h4>

        <div class="form-group">
          <label [attr.for]="'capexAmortized'" class="pw-label control-label col-sm-4"> Amortized CAPEX </label>
          <sim-var-input [inputId]="'capexAmortized'" [simVar]="unitOperation.capexAmortized"></sim-var-input>
        </div>
        <div class="form-group">
          <label [attr.for]="'capexTotal'" class="pw-label control-label col-sm-4"> Total </label>
          <sim-var-input [inputId]="'capexTotal'" [simVar]="unitOperation.capexTotal" [readOnly]="true"></sim-var-input>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="currentAnalysis" role="tabpanel" class="tab-pane fade in" id="fluidCharacterization">
    <sob-suncor-fluid-analysis-properties [currentAnalysis]="currentAnalysis"></sob-suncor-fluid-analysis-properties>
  </div>

  <div role="tabpanel" class="tab-pane fade in" id="extractionComments">
    <div class="tab-content no-padding no-border">
      <sob-unit-operation-comments [unitOpId]="this.unitOperation.id"></sob-unit-operation-comments>
    </div>
  </div>
</div>
