import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { Hydrotreater } from '../../../_models/_unit-operations/hydrotreater';
import { CoreService } from '../../../_services/core.service';
import { SuncorFluidAnalysis } from '../../../_models/_fluid/suncor-fluid-analysis';
import { SimVarFormBuilder } from '../../../_form-utils/sim-var-form-builder';
import { BaseObjectFormGroupWrapper } from '../../../_form-utils/base-object-form-group-wrapper';
import { unitOperationsConfig } from '../../../_config/unit-operations.config';
import { FlowsheetDiagramService } from '../../../_services/flowsheet-diagram/flowsheet-diagram.service';
import { BulkPropertiesComponent } from '../../shared/bulk-properties/bulk-properties.component';
import { CompositionComponent } from '../../shared/composition/composition.component';
import { ReactiveCutPropertiesComponent } from '../../shared/reactive-cut-properties/reactive-cut-properties.component';
import { Quantity } from '../../../_config/quantity.enum';
import { BitumenModelTypes } from '../../../_config/unit-operations/bitumen-conversion-enums';
import { AssayType } from '../../../_config/assay-type.enum';

declare let unitConverter: any;

@Component({
  selector: 'sob-hydrotreater',
  templateUrl: './hydrotreater.component.html',
  styleUrls: ['./hydrotreater.component.css'],
})
export class HydrotreaterComponent implements OnInit {
  @Input() unitOperation: Hydrotreater;
  @Input() formGroupWrapper: BaseObjectFormGroupWrapper;

  @ViewChild(BulkPropertiesComponent) bulkProperties: BulkPropertiesComponent;
  @ViewChild(CompositionComponent) composition: CompositionComponent;
  @ViewChild(ReactiveCutPropertiesComponent) cutProperties: ReactiveCutPropertiesComponent;
  currentAnalysis: SuncorFluidAnalysis;

  protected readonly hydrogenUseRateVariablesUnit = unitConverter.units.GasStdVolumePerVolume.SCF_BBL; //  'scf/bbl';
  protected hydrogenUseRateVariablesQuantity = Quantity.GAS_STD_VOLUME_PER_VOLUME;

  constructor(
    private coreService: CoreService,
    private fb: UntypedFormBuilder,
    private svfb: SimVarFormBuilder,
    private flowsheetDiagramService: FlowsheetDiagramService
  ) {}

  ngOnInit(): void {
    this.addControls();
    this.addRatioVariableControls();
    this.updateCurrentAnalysis();
    this.formGroupWrapper.storeOriginalValue();
  }

  updateCurrentAnalysis(id?) {
    if (id) {
      this.unitOperation.fluidAnalysisId = id;
    }
    if (this.unitOperation.fluidAnalysisId) {
      this.currentAnalysis = this.coreService.currentCase.assayManager.findFluidAnalysisById(
        this.unitOperation.fluidAnalysisId
      );
      this.propertyWindowFormGroup.get('fluidAnalysisId').setValue(this.unitOperation.fluidAnalysisId);
      this.formGroupWrapper.storeOriginalValue();
    }
  }

  addControls() {
    this.propertyWindowFormGroup.addControl('fluidAnalysisId', this.fb.control(this.unitOperation.fluidAnalysisId));

    // General Tab
    this.propertyWindowFormGroup.addControl('name', this.fb.control(this.unitOperation.name));
    this.propertyWindowFormGroup.addControl(
      'separateLightEndsProduct',
      this.fb.control(this.unitOperation.separateLightEndsProduct)
    );
    this.propertyWindowFormGroup.addControl('totalYield', this.svfb.control(this.unitOperation.totalYield));
    this.propertyWindowFormGroup.addControl(
      'totalInletVolume',
      this.svfb.control(this.unitOperation.totalInletVolume, true)
    );
    this.propertyWindowFormGroup.addControl('butaneRecovery', this.svfb.control(this.unitOperation.butaneRecovery));
    this.propertyWindowFormGroup.addControl('ghgIntensity', this.svfb.control(this.unitOperation.ghgIntensity));
    this.propertyWindowFormGroup.addControl('ghgEmissions', this.svfb.control(this.unitOperation.ghgEmissions, true));

    // economics Tab

    this.propertyWindowFormGroup.addControl('opexFixed', this.svfb.control(this.unitOperation.opexFixed));
    this.propertyWindowFormGroup.addControl('opexVar', this.svfb.control(this.unitOperation.opexVar));
    this.propertyWindowFormGroup.addControl(
      'opexCarbonDioxide',
      this.svfb.control(this.unitOperation.opexCarbonDioxide, true)
    );
    this.propertyWindowFormGroup.addControl('opexTotal', this.svfb.control(this.unitOperation.opexTotal, true));
    this.propertyWindowFormGroup.addControl('capexAmortized', this.svfb.control(this.unitOperation.capexAmortized));
    this.propertyWindowFormGroup.addControl('capexTotal', this.svfb.control(this.unitOperation.capexTotal, true));

    // constraints Tab
    this.propertyWindowFormGroup.addControl('constraintOption', this.fb.control(this.unitOperation.constraintOption));
    this.propertyWindowFormGroup.addControl('feedSlateDisabled', this.fb.control(this.unitOperation.feedSlateDisabled));
    this.propertyWindowFormGroup.addControl('blendRecipeOption', this.fb.control(this.unitOperation.blendRecipeOption));
    this.propertyWindowFormGroup.addControl(
      'capacity',
      this.svfb.control(this.unitOperation.capacity, false, false, true)
    );
    this.propertyWindowFormGroup.addControl(
      'minimumFlow',
      this.svfb.control(this.unitOperation.minimumFlow, false, false, true)
    );
    this.propertyWindowFormGroup.addControl(
      'maximumSulfurContent',
      this.svfb.control(this.unitOperation.maximumSulfurContent, false, false, true)
    );
    this.propertyWindowFormGroup.addControl(
      'maximumNitrogen',
      this.svfb.control(this.unitOperation.maximumNitrogen, false, false, true)
    );
    this.disableFeedSlateCheckBox();

    // utility Tab
    this.propertyWindowFormGroup.addControl('hydrogenUse', this.svfb.control(this.unitOperation.hydrogenUse, true));
    this.propertyWindowFormGroup.addControl(
      'h2sProductionRate',
      this.svfb.control(this.unitOperation.h2sProductionRate)
    );
    this.propertyWindowFormGroup.addControl('h2sProduction', this.svfb.control(this.unitOperation.h2sProduction, true));
    this.propertyWindowFormGroup.addControl('powerUseRate', this.svfb.control(this.unitOperation.powerUseRate));
    this.isSimpleUtility
      ? this.propertyWindowFormGroup.get('powerUseRate').disable()
      : this.propertyWindowFormGroup.get('powerUseRate').enable();
    this.propertyWindowFormGroup.addControl(
      'naturalGasUseRate',
      this.svfb.control(this.unitOperation.naturalGasUseRate)
    );
  }

  addRatioVariableControls() {
    this.propertyWindowFormGroup.addControl(
      'hydrogenUseRateVariables',
      this.fb.array(this.getHydrogenUseRateControls())
    );
  }

  separateGasStreamChanged() {
    if (this.lightEndsProduct) {
      this.flowsheetDiagramService.changeTemplate(
        this.unitOperation.id,
        unitOperationsConfig.separatedHydrotreater.key
      );
    } else {
      this.flowsheetDiagramService.changeTemplate(this.unitOperation.id, unitOperationsConfig.hydrotreater.key);
    }
    this.flowsheetDiagramService.deleteOutletStreams(this.unitOperation.id);
  }

  disableFeedSlateCheckBox() {
    if (this.constraintOption === 'properties' || this.feedSlateDisabled.value) {
      this.feedSlateDisabled.disable();
      this.blendRecipeOption.disable();
    } else if (this.constraintOption === 'blendRatio') {
      this.feedSlateDisabled.enable();
      this.blendRecipeOption.enable();
    }
  }

  feedSlateCheckBoxChange() {
    this.feedSlateDisabled.value || this.constraintOption === 'properties'
      ? this.blendRecipeOption.disable()
      : this.blendRecipeOption.enable();
  }

  updateRecipeOption() {
    this.unitOperation.blendRecipeOption = this.blendRecipeOption.value;
  }

  findDistributionRatioName(index: number): string {
    if (this.unitOperation) {
      return this.unitOperation.findDistributionRatioVariableName(index);
    }

    return '';
  }

  private getHydrogenUseRateControls() {
    return this.unitOperation.hydrogenUseRateVariables.map(dv => {
      return new FormGroup({
        value: new FormControl<number | undefined>(dv.value, [Validators.required]),
      });
    });
  }

  get fluidAnalyses() {
    return this.coreService.currentCase.assayManager.getAssaysByType(AssayType.PRODUCT);
  }

  get propertyWindowFormGroup() {
    return this.formGroupWrapper.getFormGroup();
  }

  get constraintOption() {
    return this.propertyWindowFormGroup.get('constraintOption').value;
  }

  get feedSlateDisabled() {
    return this.propertyWindowFormGroup.get('feedSlateDisabled');
  }

  get blendRecipeOption() {
    return this.propertyWindowFormGroup.get('blendRecipeOption');
  }

  get lightEndsProduct() {
    return this.propertyWindowFormGroup.get('separateLightEndsProduct').value;
  }

  get hydrogenUseRateVariablesFormArray() {
    return this.propertyWindowFormGroup.get('hydrogenUseRateVariables') as UntypedFormArray;
  }

  get isSimpleUtility() {
    return this.unitOperation.modelType === BitumenModelTypes.SIMPLE;
  }
}
