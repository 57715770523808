<div class="form-horizontal">
  <div class="form-group">
    <div class="btn-group" data-toggle="buttons">
      <button
        class="btn btn-primary {{ compositionTypeSelected === StreamCompositionType.MASS ? 'active' : '' }}"
        (click)="compositionTypeChanged($event.target)"
        name="{{ StreamCompositionType.MASS }}">
        {{ StreamCompositionType.MASS }}
      </button>
      <button
        class="btn btn-primary {{ compositionTypeSelected === StreamCompositionType.VOLUME ? 'active' : '' }}"
        (click)="compositionTypeChanged($event.target)"
        name="{{ StreamCompositionType.VOLUME }}">
        {{ StreamCompositionType.VOLUME }}
      </button>
      <button
        class="btn btn-primary {{ compositionTypeSelected === StreamCompositionType.DISTILLATION ? 'active' : '' }}"
        (click)="compositionTypeChanged($event.target)"
        name="{{ StreamCompositionType.DISTILLATION }}">
        {{ StreamCompositionType.DISTILLATION }}
      </button>
    </div>
  </div>

  <ng-container *ngIf="compositionTypeSelected === StreamCompositionType.MASS">
    <div class="form-horizontal" sfeMultipleSimVarInput totalOutputId="streamWeightCompositionTotal">
      <div class="form-group">
        <label [attr.for]="'stream-compositionUnit'" class="pw-label control-label col-sm-4"> Unit </label>

        <div class="col-sm-4">
          <sfe-unit-select
            [selectId]="'stream-compositionUnit'"
            [simVar]="stream.composition[0]"
            [elClass]="'form-control'"></sfe-unit-select>
        </div>
      </div>

      <div class="form-group" *ngIf="freeGasCompositionTotalVariable">
        <label class="pw-label control-label col-sm-4">Gas Fraction</label>
        <div class="col-sm-4">
          <sfe-value-input
            [simVar]="freeGasCompositionTotalVariable"
            [disabled]="true"
            [elClass]="'form-control'"
            [inputId]="'streamFreeGasCompositionCompound'"
            [multiple]="true"
            [convertedBy]="'stream-compositionUnit'">
          </sfe-value-input>
        </div>

        <div class="col-sm-4 px-4 px-md-0 mt-2 mt-md-0">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            title="Gas Fraction"
            (click)="showFreeGasCompositionClicked()">
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>

      <ng-container *ngFor="let idx of stream.composition | keys">
        <div class="form-group">
          <label [attr.for]="'stream-composition' + idx" class="pw-label control-label col-sm-4">
            {{ stream.composition[idx].name | titleCaseComposition }}
          </label>
          <div class="col-sm-4">
            <sfe-value-input
              [simVar]="stream.composition[idx]"
              [elClass]="'form-control'"
              [inputId]="'stream-composition' + idx"
              [multiple]="true"
              [convertedBy]="'stream-compositionUnit'"
              [readOnlyValue]="true"
              [disabled]="true">
            </sfe-value-input>
          </div>
        </div>
      </ng-container>

      <div class="form-group">
        <label class="pw-label control-label col-sm-4">Total</label>
        <div class="col-sm-4">
          <input type="text" class="form-control" id="streamWeightCompositionTotal" readonly />
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="compositionTypeSelected === StreamCompositionType.VOLUME">
    <div class="form-horizontal" sfeMultipleSimVarInput totalOutputId="streamVolumeCompositionTotal">
      <div class="form-group">
        <label [attr.for]="'stream-volumeCompositionUnit'" class="pw-label control-label col-sm-4"> Unit </label>

        <div class="col-sm-4">
          <sfe-unit-select
            [selectId]="'stream-volumeCompositionUnit'"
            [simVar]="stream.volumeComposition[0]"
            [elClass]="'form-control'"></sfe-unit-select>
        </div>
      </div>

      <ng-container *ngFor="let idx of stream.volumeComposition | keys">
        <div class="form-group">
          <label [attr.for]="'stream-volumeComposition' + idx" class="pw-label control-label col-sm-4">
            {{ stream.volumeComposition[idx].name | titleCaseComposition }}
          </label>
          <div class="col-sm-4">
            <sfe-value-input
              [simVar]="stream.volumeComposition[idx]"
              [elClass]="'form-control'"
              [inputId]="'stream-volumeComposition' + idx"
              [multiple]="true"
              [convertedBy]="'stream-volumeCompositionUnit'"
              [readOnlyValue]="true"
              [disabled]="true">
            </sfe-value-input>
          </div>
        </div>
      </ng-container>

      <div class="form-group">
        <label class="pw-label control-label col-sm-4">Total</label>
        <div class="col-sm-4">
          <input type="text" class="form-control" id="streamVolumeCompositionTotal" readonly />
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="compositionTypeSelected === StreamCompositionType.DISTILLATION">
    <div class="form-horizontal" sfeMultipleSimVarInput>
      <div class="form-group">
        <label [attr.for]="'stream-distillationCompositionUnit'" class="pw-label control-label col-sm-4"> Unit </label>

        <div class="col-sm-4">
          <sfe-unit-select
            [selectId]="'stream-distillationCompositionUnit'"
            [simVar]="stream.distillationComposition[0]"
            [elClass]="'form-control'"
            [unitSubset]="UnitSubset.VOLUME_FRACTION_NO_PPM"></sfe-unit-select>
        </div>
      </div>

      <ng-container *ngFor="let idx of stream.distillationComposition | keys">
        <div class="form-group">
          <label [attr.for]="'stream-distillationComposition' + idx" class="pw-label control-label col-sm-4">
            {{ stream.distillationComposition[idx].name }}
          </label>
          <div class="col-sm-4">
            <sfe-value-input
              [simVar]="stream.distillationComposition[idx]"
              [elClass]="'form-control'"
              [inputId]="'stream-distillationComposition' + idx"
              [multiple]="true"
              [convertedBy]="'stream-distillationCompositionUnit'"
              [readOnlyValue]="true"
              [disabled]="true">
            </sfe-value-input>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
